import React, { createContext, useContext } from 'react';

import { FormInstance } from 'antd';

type CMASummaryFilterContextType = {
  form?: FormInstance;
};

export const CMASummaryFilterContext = createContext<CMASummaryFilterContextType>({
  form: undefined,
});

export const useCMASummaryFilterContext = () => useContext(CMASummaryFilterContext);
