import React from 'react';

import { Form, Radio } from 'antd';
import styled from 'styled-components';

import { CMA_SUMMARY_ROOM_COMPARISON_TYPE_FILTER_OPTIONS } from '../constants';

const Container = styled.div`
  padding: 10px 20px;
`;

const GeneralLabel = styled.div`
  font-family: var(--font-bold);
  font-size: 12px;
  margin-bottom: 10px;
`;

const StyledRadioGroup = styled(Radio.Group)`
  display: inline-flex;
  flex-flow: column;
  gap: 10px;
`;

const StyledRadio = styled(Radio)`
  font-size: 14px;
`;

const CMASummaryRoomComparisonTypeSelector = () => (
  <Container>
    <GeneralLabel>GENERAL</GeneralLabel>
    <Form.Item name="roomComparisonType">
      <StyledRadioGroup>
        <StyledRadio value={CMA_SUMMARY_ROOM_COMPARISON_TYPE_FILTER_OPTIONS.SIMPLE_COMPARISON}>
          Simple Comparison
        </StyledRadio>
        <StyledRadio value={CMA_SUMMARY_ROOM_COMPARISON_TYPE_FILTER_OPTIONS.ADVANCED_COMPARISON}>
          Advanced Comparison
        </StyledRadio>
      </StyledRadioGroup>
    </Form.Item>
  </Container>
);

export default CMASummaryRoomComparisonTypeSelector;
