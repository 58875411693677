import React from 'react';

import { Breadcrumb } from 'antd';
import styled from 'styled-components';

const StyledBreadcrumb = styled(Breadcrumb)`
  span {
    color: var(--text-primary);

    &.competitor {
      color: var(--competitor-purple);
    }

    &.terminal {
      color: var(--text-secondary);
    }
  }
`;

const CMASummaryBreadcrumbs = () => (
  <StyledBreadcrumb
    items={[
      { title: 'Community' },
      {
        className: 'terminal',
        title: 'CMA Summary',
      },
    ]}
    separator="·"
  />
);

export default CMASummaryBreadcrumbs;
