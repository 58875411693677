import React from 'react';

import SidebarSelectorButtonAdmin from './SidebarSelectorButtonAdmin';
import SidebarSelectorButtonUser from './SidebarSelectorButtonUser';
import { useAppSelector } from '../../../../store/hook';

const SidebarSelectorButton = () => {
  const { currentUser } = useAppSelector((state) => state.auth);

  return currentUser?.isSuperuser ? <SidebarSelectorButtonAdmin /> : <SidebarSelectorButtonUser />;
};

export default SidebarSelectorButton;
