import React from 'react';

import styled from 'styled-components';

import CompaniesFiltersReset from './CompaniesFiltersReset';
import CompaniesFiltersStatus from './CompaniesFiltersStatus';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding-right: 10px;
`;

const CompaniesFiltersMenu = () => {
  return (
    <Container>
      <CompaniesFiltersStatus />
      <CompaniesFiltersReset />
    </Container>
  );
};

export default CompaniesFiltersMenu;
