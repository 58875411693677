import React from 'react';

import classNames from 'classnames';

import SelectAdvancedFilter from 'components/lib/SelectAdvancedFilter';

type Props = {
  competitors: number[];
  options: { label: string; value: number; color: string }[];
  setFilters: (value: any) => void;
};

const CompetitorsFilter = ({ competitors, options, setFilters }: Props) => {
  const handleSelect = (values: number[]) => {
    setFilters({ competitors: values.map((item: any) => item.value) });
  };

  return (
    <SelectAdvancedFilter
      className={classNames({ active: competitors?.length > 0 })}
      placeholder={'Competitors'}
      options={options}
      defaultValue={competitors}
      onSelect={handleSelect}
    />
  );
};

export default CompetitorsFilter;
