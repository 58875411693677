import React from 'react';

import styled from 'styled-components';

import AdditionalLinks from './AdditionalLinks';
import BasicInfo from './BasicInfo';
import BrochureAndDocs from './BrochureAndDocs/BrochureAndDocs';
import Photos from './Photos/Photos';

const Container = styled.div`
  display: flex;
  gap: 20px;
`;

const Column = styled.div`
  flex: 1;
`;

const General = () => {
  return (
    <Container>
      <Column>
        <BasicInfo />
        <AdditionalLinks />
      </Column>
      <Column>
        <Photos />
        <BrochureAndDocs />
      </Column>
    </Container>
  );
};

export default General;
