import React from 'react';

import { UploadOutlined } from '@ant-design/icons';
import { Button, DatePicker, Form, Input, InputNumber, message, Select, Tag, Tooltip, Upload } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import dayjs from 'dayjs';
import styled from 'styled-components';

import { DEPENDENCY_TYPE, EditableTableDataType, UPLOAD_TYPE_ACCEPT } from './constants';
import { Divider } from '../../components/lib/Divider';
import { GLOBAL_DATE_FORMAT, INPUT_TYPES } from '../../constants';

const { TextArea } = Input;

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  options: DefaultOptionType[];
  title: any;
  required?: boolean;
  inputType: string;
  record: EditableTableDataType;
  index: number;
  disableTooltip?: boolean;
  handleChange?: (_: any) => void;
  validator?: (_: any) => void;
  dependencies?: string[];
  dependencyType?: string;
  children: React.ReactNode;
  calculatedDataIndex?: string;
  calculated: (_: any, dataIndex: string) => void;
}

const TooltipContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const EditableCell = ({
  editing,
  dataIndex,
  required,
  title,
  inputType,
  record,
  index,
  options,
  children,
  handleChange = () => {
    return;
  },
  validator = async () => {
    return;
  },
  dependencies,
  dependencyType,
  disableTooltip = false,
  calculatedDataIndex,
  calculated,

  ...restProps
}: EditableCellProps) => {
  const formInstance = Form.useFormInstance();

  let label = children;

  // Utilizing 'dependencies' in different manner than expected to ensure data validity by disabling competing inputs
  // Dependencies still used to update individual related Field.Item instances
  const dependenciesFields = formInstance.getFieldsValue(dependencies ?? []);
  const dependenciesValues = Object.values(dependenciesFields);
  const excludeDependencyCallback =
    dependencyType === DEPENDENCY_TYPE.EXCLUDE && dependenciesValues.some((value) => !!value);
  const includeDependencyCallback =
    dependencyType === DEPENDENCY_TYPE.INCLUDE && dependenciesValues.every((value) => !value);
  const excludeCalculatedDependencyCallback = calculatedDataIndex == dataIndex;
  calculated && calculated(formInstance, dataIndex);
  const disabled =
    (dependencies && (excludeDependencyCallback || includeDependencyCallback)) || excludeCalculatedDependencyCallback;

  let inputNode = <InputNumber disabled={disabled} style={{ width: '100%' }} />;

  switch (inputType) {
    case INPUT_TYPES.NUMBER_POSITIVE:
      inputNode = <InputNumber disabled={disabled} min={0} style={{ width: '100%' }} />;
      break;
    case INPUT_TYPES.TEXTAREA:
      inputNode = <TextArea disabled={disabled} placeholder="Note..." autoSize={{ minRows: 1, maxRows: 4 }} />;
      break;
    case INPUT_TYPES.TEXT:
      inputNode = <Input disabled={disabled} />;
      break;
    case INPUT_TYPES.CURRENCY:
      inputNode = <InputNumber disabled={disabled} addonBefore="$" min={0} style={{ width: '100%' }} />;
      label = <>${children}</>;
      break;
    case INPUT_TYPES.DATE: {
      inputNode = (
        <DatePicker
          style={{ width: '100%' }}
          disabled={disabled}
          defaultValue={dayjs()}
          maxDate={dayjs()}
          format={GLOBAL_DATE_FORMAT}
          onChange={(date: any) => {
            // We wan't to attach the time component without showing time selection panel
            // This proved to be the most simple approach
            const dateWithTime = date ? dayjs(date).set('hour', dayjs().hour()).set('minute', dayjs().minute()) : null;

            formInstance.setFieldsValue({
              [dataIndex]: dateWithTime,
            });
          }}
        />
      );

      // @ts-ignore
      const stringDate = record && record[dataIndex] && record[dataIndex].format(GLOBAL_DATE_FORMAT);
      label = stringDate && <span>{stringDate}</span>;
      break;
    }
    case INPUT_TYPES.SELECT: {
      // @ts-ignore
      inputNode = (
        <Select
          disabled={disabled}
          showSearch
          options={options}
          filterOption={(input, option) => (option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase())}
          onChange={() => handleChange(formInstance)}
        />
      );
      // @ts-ignore
      const selectedOption = options.find((option) => option.value === record[dataIndex]);
      label = <span>{record && selectedOption && selectedOption.label}</span>;

      break;
    }
    case INPUT_TYPES.MULTIPLE_SELECT: {
      inputNode = (
        <Select
          disabled={disabled}
          showSearch={false}
          mode={'multiple'}
          options={options}
          onChange={() => handleChange(formInstance)}
        />
      );

      const selectedOptions = options.filter(
        // @ts-ignore
        (option) => record && record[dataIndex] && record[dataIndex].includes(option.value)
      );
      label = (
        <span>
          {selectedOptions.map((item: DefaultOptionType) => (
            <Tag key={item.value}>{item.label}</Tag>
          ))}
        </span>
      );
      break;
    }
    case INPUT_TYPES.ATTACHMENT: {
      const rootFieldName = dataIndex.split('_')[0];
      const uploadFieldName = `${rootFieldName}_upload`;
      //@ts-ignore
      const currentAttachments = record[rootFieldName] ?? [];

      inputNode = (
        <>
          {Boolean(currentAttachments.length) && (
            <>
              <Form.Item name={dataIndex}>
                <Select
                  disabled={disabled}
                  showSearch={false}
                  showArrow={false}
                  open={false}
                  mode={'multiple'}
                  options={currentAttachments.map((val: any) => ({
                    label: val.file_name,
                    value: val.id,
                  }))}
                  onChange={() => handleChange(formInstance)}
                />
              </Form.Item>
              <Divider style={{ margin: 5 }} />
            </>
          )}
          <Form.Item
            name={uploadFieldName}
            valuePropName="fileList"
            getValueFromEvent={(e) => (Array.isArray(e) ? e : e?.fileList)}
          >
            <Upload
              name={uploadFieldName}
              listType="text"
              multiple
              beforeUpload={() => false}
              accept={UPLOAD_TYPE_ACCEPT[rootFieldName].join(',')}
            >
              <Button icon={<UploadOutlined />}>Select Files</Button>
            </Upload>
          </Form.Item>
        </>
      );

      label = (
        <div style={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {currentAttachments.map((item: DefaultOptionType) => (
            <a
              key={item.id}
              style={{ wordBreak: 'break-word' }}
              href={item.path}
              download={item.file_name}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Tag closeIcon={editing}>{item.file_name}</Tag>
            </a>
          ))}
        </div>
      );
      break;
    }
  }

  const getTooltipContent = () => {
    return (
      <TooltipContent>
        <span>Created By: {record?.createdBy}</span>
        <span>Created Date: {dayjs(record?.createdAt).format(GLOBAL_DATE_FORMAT)}</span>
        <span>Updated By: {record?.updatedBy}</span>
        <span>Updated Date: {dayjs(record?.updatedAt).format(GLOBAL_DATE_FORMAT)}</span>
      </TooltipContent>
    );
  };

  const tooltipHasContent = record && record?.updatedBy && record?.updatedAt;
  const hideTooltip = disableTooltip || !tooltipHasContent || editing;
  const tooltipTitle = hideTooltip ? '' : getTooltipContent;

  const rules = [
    {
      required: required,
      message: `Please Input ${title}!`,
    },
    {
      validator: async () => {
        const currentValues = formInstance.getFieldsValue();
        const payload = Object.assign({}, currentValues, { id: record.id, key: record.key });
        return validator(payload);
      },
    },
  ];

  return (
    <Tooltip title={tooltipTitle} placement="topLeft" color="blue">
      <td {...restProps}>
        {!editing && label}
        {editing && inputType != INPUT_TYPES.ATTACHMENT && (
          <Form.Item name={dataIndex} rules={rules} dependencies={dependencies}>
            {inputNode}
          </Form.Item>
        )}
        {editing && inputType === INPUT_TYPES.ATTACHMENT && <>{inputNode}</>}
      </td>
    </Tooltip>
  );
};

export default EditableCell;
