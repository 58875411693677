import React from 'react';

import { TagOutlined } from '@ant-design/icons/lib';
import classNames from 'classnames';
import { format } from 'date-fns';
import styled from 'styled-components';

import DataTable from './DataTable';
import { COMMUNITY_TYPE } from '../../../constants';

interface Props {
  data: any;
}

const StyledDataTable = styled(DataTable)`
  &&&&&& {
    width: 100%;
    td {
      padding: 11px 4px;
    }

    th {
      &.ant-table-cell::before {
        background-color: transparent;
      }
    }
  }
`;

const DataTableIncentives = ({ data }: Props) => {
  const columns = [
    {
      title: 'COMMUNITY',
      dataIndex: 'community_name',
      width: '30%',
      align: 'left' as const,
      render: (value: any, record: any) => (
        <span className={classNames({ highlighted: record.community_type === COMMUNITY_TYPE.COMMUNITY })}>{value}</span>
      ),
    },
    {
      title: 'INCENTIVE',
      dataIndex: 'description',
      align: 'left' as const,
      render: (value: any, record: any) => (
        <span>
          <TagOutlined style={{ color: 'var(--positive-green)', transform: 'rotate(-90deg)' }} /> {value}
        </span>
      ),
    },
    {
      title: 'DATE',
      dataIndex: 'date',
      align: 'left' as const,
      width: '12%',
      render: (value: any, record: any) => <span>{format(new Date(value), 'MMM dd, yyyy')}</span>,
    },
  ];

  return (
    <StyledDataTable columns={columns} dataSource={data} pagination={false} rowKey={(record: any) => record?.id} />
  );
};

export default DataTableIncentives;
