import React from 'react';

import { SearchOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import CompaniesFiltersMenu from './CompaniesFiltersMenu';
import Input from '../../lib/Input';
import { useCompaniesContext } from '../CompaniesContext';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
`;

const CompaniesFilters = () => {
  const { filters, setFilters } = useCompaniesContext();

  const handleChange = (event: any) => {
    const search = event.target.value;
    setFilters({ ...filters, page: 1, search });
  };

  const searchPlaceholder = 'Search companies';

  return (
    <Container>
      <Input
        value={filters?.search}
        prefix={<SearchOutlined />}
        style={{ width: 500 }}
        onChange={handleChange}
        placeholder={searchPlaceholder}
      />
      <CompaniesFiltersMenu />
    </Container>
  );
};

export default CompaniesFilters;
