import React from 'react';

import { CloseOutlined } from '@ant-design/icons';
import { Form, FormListFieldData, FormListOperation } from 'antd';
import styled from 'styled-components';

import Select from 'components/lib/Select';

import { useCMASummaryFilterContext } from './CMASummaryFilterContext';
import {
  CMA_SUMMARY_ROOM_COMPARISON_TYPE_FILTER_ROW_FIELDS,
  CMA_SUMMARY_ROOM_DATA_FILTER_TYPES,
  ROOM_COMPARISON_ROWS,
} from '../constants';
import { filterRemainingCMASummaryRoomComparisonFields } from '../filterUtils';

const StyledSelect = styled(Select)<{ width?: number }>`
  &.ant-select {
    width: ${(props) => (props.width ? `${props.width}px` : '148px')};
    .ant-select-selector {
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
      border: solid 1px #d7d7d7;
      border-radius: 0;
      height: 36px !important;
      font-size: 14px;
    }
  }
`;

const Field = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
`;

type Props = {
  subField: FormListFieldData;
  rowIndex: number;
  subOpt: FormListOperation;
};

const CMASummaryRoomComparisonTypeFilterField = ({ subField, rowIndex, subOpt }: Props) => {
  const { form } = useCMASummaryFilterContext();
  const roomComparisonRows = Form.useWatch(ROOM_COMPARISON_ROWS, form);
  const fieldValue = form?.getFieldValue([ROOM_COMPARISON_ROWS, rowIndex, subField.name, 'name']);

  const isRateField = [CMA_SUMMARY_ROOM_COMPARISON_TYPE_FILTER_ROW_FIELDS.RATE].includes(fieldValue);
  const showFieldTypeSelect = [
    CMA_SUMMARY_ROOM_COMPARISON_TYPE_FILTER_ROW_FIELDS.CARE,
    CMA_SUMMARY_ROOM_COMPARISON_TYPE_FILTER_ROW_FIELDS.RATE,
  ].includes(fieldValue);

  const fieldNameSelect = (
    <Form.Item name={[subField.name, 'name']}>
      <StyledSelect
        placeholder={'Select'}
        dropdownStyle={{
          border: '1px solid var(--line-gray)',
          borderRadius: 0,
          padding: 0,
        }}
        options={filterRemainingCMASummaryRoomComparisonFields(
          roomComparisonRows ? roomComparisonRows[rowIndex] : []
        ).map((fieldName: string) => ({ value: fieldName, label: fieldName }))}
        onMouseDown={(e) => e.stopPropagation()}
        disabled={isRateField}
      />
    </Form.Item>
  );
  const fieldTypeSelect = (
    <Form.Item style={{ marginLeft: '5px' }} name={[subField.name, 'fieldType']}>
      <StyledSelect
        placeholder={'Select'}
        dropdownStyle={{
          border: '1px solid var(--line-gray)',
          borderRadius: 0,
          padding: 0,
        }}
        width={80}
        options={Object.values(CMA_SUMMARY_ROOM_DATA_FILTER_TYPES).map((item) => ({
          value: item.id,
          label: item.label,
        }))}
        onMouseDown={(e) => e.stopPropagation()}
      />
    </Form.Item>
  );
  const removeFieldButton = (
    <CloseOutlined
      style={{ marginLeft: '13px', color: 'var(--gray-dark)' }}
      onClick={() => subOpt.remove(subField.name)}
    />
  );

  return (
    <Field key={subField.key}>
      {fieldNameSelect}
      {showFieldTypeSelect && fieldTypeSelect}
      {!isRateField && removeFieldButton}
    </Field>
  );
};

export default CMASummaryRoomComparisonTypeFilterField;
