import React, { useContext } from 'react';

import styled from 'styled-components';

import { CommunityViewWidgetModalContext } from './CommunityViewWidgetModalContext';
import Widget from '../../../common/Widgets/Widget/Widget';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: var(--background-gray);
  padding: 20px;
`;

const CommunityViewWidgetModalContent = () => {
  const {
    type,
    name,
    note,
    competitors,
    careTypes,
    roomTypes,
    careLevels,
    careLevelsStatsType,
    reviewSources,
    reviewCategories,
    swotCategories,
  } = useContext(CommunityViewWidgetModalContext);

  return (
    <Container>
      <Widget
        type={type}
        name={name}
        note={note}
        filters={{
          competitors,
          careTypes,
          roomTypes,
          careLevels,
          reviewSources,
          reviewCategories,
          swotCategories,
          careLevelsStatsType,
        }}
      />
    </Container>
  );
};

export default CommunityViewWidgetModalContent;
