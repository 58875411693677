import React, { useState } from 'react';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

import { Image } from 'antd';
import classNames from 'classnames';
import styled from 'styled-components';

import ZoomControls from 'common/Widgets/Photos/ZoomControls';

import { usePhotosContext } from './PhotosContext';

const ControlsContainer = styled.div`
  position: absolute;
  z-index: 10;
  bottom: 95px;
  right: 95px;
  display: none;
`;

const Caption = styled.div`
  position: absolute;
  width: 945px;
  z-index: 10;
  bottom: 10px;
  text-align: center;
  color: var(--white);
  font-size: 14px;
`;

const Container = styled.div`
  &:hover ${ControlsContainer} {
    display: flex;
  }

  &.zoomable {
    cursor: grab;
  }
`;

const PhotosPreviewCarousalDisplay = () => {
  const [isZoomed, setIsZoomed] = useState<boolean>(false);

  const { photos, selectedPhotoIndex } = usePhotosContext();
  const imgUrl = photos[selectedPhotoIndex]?.image_url;
  const caption = photos[selectedPhotoIndex]?.image_category;

  return (
    <Container className={classNames({ zoomable: isZoomed })}>
      <TransformWrapper>
        <ControlsContainer>
          <ZoomControls setIsZoomed={setIsZoomed} />
        </ControlsContainer>
        <TransformComponent>
          <Image src={imgUrl} alt="img" width="945px" height="645px" preview={false} />;<Caption>{caption}</Caption>
        </TransformComponent>
      </TransformWrapper>
    </Container>
  );
};

export default PhotosPreviewCarousalDisplay;
