import React from 'react';

import { Form } from 'antd';
import styled from 'styled-components';

import Checkbox from 'components/lib/Checkbox';

const Container = styled.div`
  display: flex;
  flex-flow: column;

  position: relative;
`;

const Title = styled.div`
  letter-spacing: 0.5px;
  color: var(--text-primary);
  font-family: var(--font-bold);
  font-size: 12px;
  margin-bottom: 10px;
`;

const Label = styled.span`
  font-size: 14px;
  margin-left: 10px;
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  span {
    display: flex;
  }
`;

const CareTypesContainer = styled.div`
  padding-left: 20px;
`;

type CheckboxRowType = {
  formItemName: string | string[];
  label: string;
  style?: React.CSSProperties;
};
const CheckboxRow = ({ formItemName, label, style }: CheckboxRowType) => (
  <LabelContainer style={style}>
    <Form.Item noStyle name={formItemName} valuePropName="checked">
      <Checkbox className={'green'} />
    </Form.Item>
    <Label>{label}</Label>
  </LabelContainer>
);

const CMASummaryViewFilterCategories = () => {
  return (
    <Container>
      <Title>CATEGORIES</Title>
      <CheckboxRow formItemName={'contactInformation'} label="Contact Information" />
      <CheckboxRow formItemName={'reviews'} label="Reviews" />
      <CheckboxRow formItemName={'careTypes'} label="Care Types" />
      <CareTypesContainer>
        <CheckboxRow formItemName={'roomType'} label="Room Type" />
        <CheckboxRow formItemName={'communityFee'} label="Community Fee" />
        <CheckboxRow formItemName={'secondPersonFee'} label="Second Person Fee" />
        <CheckboxRow formItemName={'petFee'} label="Pet Fee" />
        <CheckboxRow formItemName={'careFee'} label="Care Fee" />
      </CareTypesContainer>
      <CheckboxRow formItemName={'incentives'} label="Incentives" style={{ marginBottom: 0 }} />
    </Container>
  );
};

export default CMASummaryViewFilterCategories;
