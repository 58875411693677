import React, { createContext, useContext } from 'react';

import { SHOP_REQUEST_DUE_DATE_RANGES, SHOP_REQUESTS_PAGE_SIZE } from './constants';
import { MysteryShopRequestInbound, ShopRequestsFiltersType } from './types';

type ShopRequestsDataContextType = {
  filters: Partial<ShopRequestsFiltersType>;
  ordering?: string;
  items: MysteryShopRequestInbound[];
  selectionMode: boolean;
  openEditBatchShopRequestsModel: boolean;
  selectedItemsIds: string[];
  totalItemsIds: string[];
  setSelectionMode: (mode: any) => void;
  setOpenEditBatchShopRequestsModel: (open: any) => void;
  setTotalItemsIds: (ids: string[]) => void;
  setSelectedItemsIds: (ids: string[]) => void;
  setOrdering: (value: string) => void;
  setSearch: (s: string) => void;
  setPage: (page: number) => void;
  setItems: (payload: any) => void;
  setFilters: (f: any) => void;
  handleSelection: (items: any[]) => void;
  resetSelection: () => void;
};

export const ShopRequestsDataContext = createContext<ShopRequestsDataContextType>({
  filters: {
    page: 1,
    pageSize: SHOP_REQUESTS_PAGE_SIZE,
    search: '',
    status: [],
    requestFrom: undefined,
    shoppers: [],
    companyIds: [],
    dueDate: undefined,
    dueDateRange: SHOP_REQUEST_DUE_DATE_RANGES.ALL_REQUESTS,
    groupBy: undefined,
    orderBy: undefined,
  },
  items: [],
  totalItemsIds: [],
  ordering: undefined,
  selectionMode: false,
  openEditBatchShopRequestsModel: false,
  selectedItemsIds: [],
  setSelectedItemsIds: () => {
    // Do nothing
  },
  setSelectionMode: () => {
    // Do nothing
  },
  setOpenEditBatchShopRequestsModel: () => {
    // Do nothing
  },
  setTotalItemsIds: () => {
    // Do nothing
  },
  setOrdering: () => {
    // empty
  },
  setFilters: () => {
    // empty
  },
  setSearch: () => {
    // empty
  },
  setPage: () => {
    // empty
  },
  setItems: () => {
    // empty
  },
  handleSelection: () => {
    // Do nothing
  },
  resetSelection: () => {
    // Do nothing
  },
});

export const useShopRequestsDataContext = () => useContext(ShopRequestsDataContext);
