import React from 'react';

import styled from 'styled-components';

import HeaderUserActions from './HeaderUserActions';
import { useAppSelector } from '../../../store/hook';
import HeaderUserApplicationSwitch from '../HeaderInitial/HeaderUserApplicationSwitch';

const Container = styled.div`
  display: flex;
  justify-content: end;
  gap: 30px;
  padding-right: 10px;
`;

const HeaderUser = () => {
  const { currentUser } = useAppSelector((state) => state.auth);

  return (
    <Container>
      {currentUser && !currentUser.isSuperuser && <HeaderUserApplicationSwitch />}
      <HeaderUserActions />
    </Container>
  );
};

export default HeaderUser;
