import React from 'react';

import styled from 'styled-components';

import { getDocusealSignatureDocumentUrl } from 'components/Incentives/constants';
import { useIncentivesContext } from 'components/Incentives/IncentivesContext';

import DownCaretIcon from '../../../../common/Icons/DownCaretIcon';
import Button from '../../../lib/Button';
import Dropdown from '../../../lib/Dropdown';
import { ProposalType } from '../../types';

interface Props {
  record: ProposalType;
}

const Wrapper = styled.div`
  visibility: hidden;
`;

const StyledDropdownMenu = styled.div`
  .ant-dropdown-menu {
    width: 208px;
    padding: 0;
    border-radius: 0;
    box-shadow: var(--box-shadow-gray-dark) 0px 2px 4px 0px;
    border: 1px solid var(--line-gray);

    .ant-dropdown-menu-item {
      padding: 6px 10px;
      border-bottom: 1px solid var(--line-gray);
    }
  }
`;

const IncentivesTableProposalsActionsDropdown = ({ record }: Props) => {
  const { setSelectedProposalUnit, setSelectedProposal } = useIncentivesContext();

  const openProposalModal = () => {
    setSelectedProposal(record);
    setSelectedProposalUnit({ ...record.unit, incentive_id: record?.incentive?.id });
  };

  const menuItems = [
    {
      label: 'View Recipient Info',
      key: 'viewRecipientInfo',
      onClick: openProposalModal,
    },
    {
      label: 'View Proposal Page',
      key: 'viewProposalPage',
      onClick: () => window.open(record.proposal_page_url, '_blank'),
    },
  ];
  if (record.docuseal_slugs?.second_person_docuseal_slug) {
    menuItems.push({
      label: 'View Second Signer Page',
      key: 'viewSecondSignerPage',
      onClick: () =>
        window.open(getDocusealSignatureDocumentUrl(record.docuseal_slugs!.second_person_docuseal_slug!), '_blank'),
    });
  }
  if (record.docuseal_slugs?.community_docuseal_slug) {
    menuItems.push({
      label: 'View Community Signer Page',
      key: 'viewCommunitySignerPage',
      onClick: () =>
        window.open(getDocusealSignatureDocumentUrl(record.docuseal_slugs!.community_docuseal_slug!), '_blank'),
    });
  }

  return (
    <Wrapper className={'hover-visible'}>
      <Dropdown
        menu={{ items: menuItems.filter(Boolean) }}
        dropdownRender={(menu: any) => <StyledDropdownMenu>{menu}</StyledDropdownMenu>}
      >
        <Button style={{ width: 100 }}>
          <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
            <span>Actions</span>
            <DownCaretIcon />
          </div>
        </Button>
      </Dropdown>
    </Wrapper>
  );
};

export default IncentivesTableProposalsActionsDropdown;
