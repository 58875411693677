import React, { useState } from 'react';

import styled from 'styled-components';

import { batchUpdateShopRequests } from 'apis/ShopRequestAPI';
import { useRootContext } from 'components/layout/RootContext';
import Modal from 'components/lib/Modal';

import { EditBatchShopRequestsModalContext } from './EditBatchShopRequestsModalContext';
import EditBatchShopRequestsModalFooter from './EditBatchShopRequestsModalFooter';
import EditBatchShopRequestsModelContent from './EditBatchShopRequestsModelContent';
import { useShopRequestsDataContext } from '../ShopRequestsDataContext';
import { MysteryShopRequestsBatch } from '../types';
import { transformBatchShopRequestOutbound } from '../utils';

const StyledModal = styled(Modal)`
  &&& .ant-modal {
    max-width: 540px;
  }

  .ant-modal-close {
    top: 22px;
  }

  &&& .ant-modal-body {
    padding: 0;
  }

  &&& .ant-modal-footer {
    margin-top: 0;
    button {
      font-size: 14px;
    }
  }

  .ant-modal-content {
    max-width: 540px;

    .ant-modal-header {
      margin-bottom: 0;
    }
    .ant-modal-footer {
      display: flex;
      flex-flow: row-reverse;

      button {
        margin-right: 10px;
        border-radius: 4px;
        width: 155px;
      }
    }
  }
`;

type Props = {
  onCancel: () => void;
  refetchData: () => void;
};

const EditBatchShopRequestsModal = ({ onCancel, refetchData }: Props) => {
  const { showMessage } = useRootContext();
  const { selectedItemsIds, setSelectedItemsIds } = useShopRequestsDataContext();
  const [batchShopRequests, setBatchShopRequests] = useState<MysteryShopRequestsBatch>({ ids: selectedItemsIds });
  const [isLoading, setLoading] = useState(false);

  let titleText = 'Edit 1 Shop Request';
  if (selectedItemsIds.length > 1) {
    titleText = `Edit ${selectedItemsIds.length} Shop Requests`;
  }

  const handleConfirm = async () => {
    setLoading(true);
    const payload = transformBatchShopRequestOutbound(batchShopRequests);
    try {
      await batchUpdateShopRequests(payload).then(() => {
        setLoading(false);
        setSelectedItemsIds([]);
        onCancel();
        refetchData();
        showMessage('success', 'Shop requests edited');
      });
    } catch (e) {
      setLoading(false);
      showMessage('error', 'Trouble editing shop requests.');
      console.error('Trouble editing shop requests.', e);
    }
  };

  return (
    <EditBatchShopRequestsModalContext.Provider
      value={{ isLoading, batchShopRequests, setLoading, setBatchShopRequests }}
    >
      <StyledModal
        open
        title={titleText}
        cancelText="Close"
        okText="Save"
        onCancel={onCancel}
        width="fit-content"
        footer={<EditBatchShopRequestsModalFooter onOk={handleConfirm} onCancel={onCancel} />}
      >
        <EditBatchShopRequestsModelContent />
      </StyledModal>
    </EditBatchShopRequestsModalContext.Provider>
  );
};

export default EditBatchShopRequestsModal;
