import React, { useEffect, useState } from 'react';

import { Modal } from 'antd';
import styled from 'styled-components';

import {
  BLANK_INCENTIVE_PROPOSAL_PRICING,
  DEFAULT_INCENTIVE_PROPOSAL,
  INCENTIVE_PROPOSAL_MODAL_WIDTH,
} from './constants';
import { IncentivesProposalModalContext } from './IncentivesProposalModalContext';
import IncentivesProposalModalInfo from './IncentivesProposalModalInfo';
import IncentivesProposalModalProposal from './IncentivesProposalModalProposal';
import { IncentiveProposalPricingType, IncentiveProposalType } from './types';
import { INCENTIVES_FEES_TYPE, INCENTIVES_FEES_TYPE_LABELS, INCENTIVES_FEES_TYPES_LIST } from '../create/constants';
import { getUnitFeePrice } from '../helpers';
import { useIncentivesContext } from '../IncentivesContext';
import { IncentiveSavingType, IncentiveType } from '../types';

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 0px;
    padding: 0;
  }

  .ant-modal-footer {
    margin: 0;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 900px;
  max-height: 700px;
`;

const IncentivesProposalModal = () => {
  const { selectedProposalUnit, selectedProposal, setSelectedProposal, setSelectedProposalUnit } =
    useIncentivesContext();
  const [proposal, setProposal] = useState<IncentiveProposalType>(DEFAULT_INCENTIVE_PROPOSAL);
  const [rates, setRates] = useState<IncentiveProposalPricingType[]>([]);
  const [incentive, setIncentive] = useState<IncentiveType | undefined>(undefined);
  const [readOnly, setReadOnly] = useState(false);

  useEffect(() => {
    const incentive = selectedProposalUnit?.incentives?.find(
      (item: IncentiveType) => item.id === selectedProposalUnit.incentive_id
    );

    if (selectedProposalUnit && incentive) {
      setIncentive(incentive ?? undefined);
    }

    if (selectedProposal) {
      setReadOnly(true);
    }
  }, [selectedProposalUnit, selectedProposal]);

  useEffect(() => {
    if (selectedProposalUnit) {
      const calculatedCommonRates = INCENTIVES_FEES_TYPES_LIST.map((type: string) => {
        const pricingItem = Object.assign({}, BLANK_INCENTIVE_PROPOSAL_PRICING, {
          amount: getUnitFeePrice(selectedProposalUnit, type),
          type,
          name: INCENTIVES_FEES_TYPE_LABELS[type],
        });

        const feeTypeSavings = incentive?.savings.find((savingsItem: IncentiveSavingType) => savingsItem.type === type);

        if (feeTypeSavings) {
          return Object.assign(pricingItem, feeTypeSavings, { name: INCENTIVES_FEES_TYPE_LABELS[type] });
        }

        return pricingItem;
      });

      const calculatedCustomRates = (incentive?.savings ?? [])
        .filter((item: IncentiveSavingType) => item.type === INCENTIVES_FEES_TYPE.CUSTOM)
        .map((item: IncentiveSavingType) => {
          return Object.assign({}, BLANK_INCENTIVE_PROPOSAL_PRICING, {
            amount: item.discount,
            type: item.type,
            name: item.name,
          });
        });

      setRates([...calculatedCommonRates, ...calculatedCustomRates]);
    }
  }, [selectedProposalUnit, incentive]);

  useEffect(() => {
    setProposal((prev: IncentiveProposalType) => ({ ...prev, pricing: rates }));
  }, [rates]);

  const handleClose = () => {
    setSelectedProposalUnit(undefined);
    setSelectedProposal(undefined);
    setProposal(DEFAULT_INCENTIVE_PROPOSAL);
    setRates([]);
    setReadOnly(false);
  };

  return (
    <IncentivesProposalModalContext.Provider
      value={{
        rates,
        incentive,
        proposal,
        readOnly,
        setRates,
        setIncentive,
        setProposal,
        handleClose,
      }}
    >
      <StyledModal
        open={!!selectedProposalUnit}
        footer={false}
        closable
        onCancel={handleClose}
        destroyOnClose
        width={INCENTIVE_PROPOSAL_MODAL_WIDTH}
      >
        <Container>
          <IncentivesProposalModalInfo />
          <IncentivesProposalModalProposal />
        </Container>
      </StyledModal>
    </IncentivesProposalModalContext.Provider>
  );
};

export default IncentivesProposalModal;
