import React from 'react';
import { useQuery } from 'react-query';

import { OccupancyType } from './constants';
import { useOccupanciesContext } from './OccupanciesContext';
import OccupanciesFormHistoryTable from './OccupanciesFormHistoryTable';
import { mapCommunityOccupancyResponseToData } from './utils';
import { deleteOccupancies, getOccupancies } from '../../../apis/CommunityAPI';
import { useRootContext } from '../../../components/layout/RootContext';
import { STATUS } from '../../../constants';

const OccupanciesFormHistory = () => {
  const { showMessage } = useRootContext();
  const { communityId } = useOccupanciesContext();

  const [dataLoading, setDataLoading] = React.useState(STATUS.IDLE);

  const {
    data: occupancies,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['occupancies', 'historical', communityId],
    queryFn: () => getOccupancies(communityId as number, { active: 0 }),
    select: (data: any[]) => {
      const formatted_data: OccupancyType[] = data?.map((item: any) => mapCommunityOccupancyResponseToData(item));
      return formatted_data ?? [];
    },
    onError: () => {
      showMessage('error', 'Failed to fetch historical community Occupancies');
      setDataLoading(STATUS.FAILURE);
    },
    enabled: Boolean(communityId),
    refetchOnWindowFocus: false,
  });

  const deleteCommunityOccupancies = (item: Partial<OccupancyType>) => {
    setDataLoading(STATUS.LOADING);
    deleteOccupancies(communityId as number, item.id as number)
      .then((response) => {
        refetch();
        showMessage('success', 'Historical Community Occupancy deleted successfully');
        setDataLoading(STATUS.LOADED);
      })
      .catch((reason) => {
        console.error(reason);
        showMessage('error', 'Failed to delete historical Community Occupancy');
        setDataLoading(STATUS.FAILURE);
      });
  };

  return (
    <OccupanciesFormHistoryTable
      loading={dataLoading === STATUS.LOADING || isLoading}
      data={occupancies ?? []}
      handleDelete={deleteCommunityOccupancies}
    />
  );
};

export default OccupanciesFormHistory;
