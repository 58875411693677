import React, { useEffect } from 'react';

import classNames from 'classnames';

import { WIDGET_FILTER_OPTIONS, WIDGET_FILTER_TYPES } from 'common/Widgets/constants';
import SelectAdvancedFilter from 'components/lib/SelectAdvancedFilter';

type Props = {
  careTypes: any;
  setFilters: (value: any) => void;
};

const CareTypeFilter = ({ careTypes, setFilters }: Props) => {
  const handleSelect = (values: number[]) => {
    setFilters({ careTypes: values.map((item: any) => item.value) });
  };

  const options = WIDGET_FILTER_OPTIONS[WIDGET_FILTER_TYPES.CARE_TYPE].map((item) => ({
    label: item.name,
    value: item.id,
  }));

  return (
    <SelectAdvancedFilter
      className={classNames({ active: careTypes && careTypes.length > 0 })}
      placeholder={'Care Type'}
      options={options}
      defaultValue={careTypes || []}
      onSelect={handleSelect}
    />
  );
};

export default CareTypeFilter;
