import React from 'react';

import styled from 'styled-components';

import { AGGREGATION_TYPE, AGGREGATION_TYPE_LABELS } from 'components/View/constants';

import { useCMASummaryContext } from './CMASummaryContext';
import CMASummaryTableDataValuesDifference from './CMASummaryTableDataValuesDifference';
import { parseCMASummaryFeeData } from './utils';

const Title = styled.span`
  font-family: var(--font-regular);
  font-size: 14px;
`;

const Subtitle = styled.div`
  color: var(--text-secondary);
  font-size: 12px;
`;

const AggregationType = styled.span`
  margin-left: 4px;
  text-transform: lowercase;
`;

type Props = {
  careFee: any[];
};

const CMASummaryTableDataCareFeesGroup = ({ careFee }: Props) => {
  const { filters } = useCMASummaryContext();
  const [value, type, subtitle] = careFee;
  const [careFeeLevelValue, feeLevelDifference] = parseCMASummaryFeeData(value, filters.viewOptions?.valueDisplay);

  return (
    <div>
      {careFeeLevelValue && (
        <Title>
          ${careFeeLevelValue.toLocaleString(undefined, { maximumFractionDigits: 0 })}
          <AggregationType>{AGGREGATION_TYPE_LABELS[filters.aggregationType || AGGREGATION_TYPE.AVG]}</AggregationType>
          <CMASummaryTableDataValuesDifference difference={feeLevelDifference} />
        </Title>
      )}
      {!careFeeLevelValue && <Title>{type}</Title>}
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
    </div>
  );
};

export default CMASummaryTableDataCareFeesGroup;
