import React from 'react';

import styled from 'styled-components';

import { useAppSelector } from 'store/hook';

import SidebarSelectorDropdownAction from './SidebarSelectorDropdownAction';
import { SELECTOR_ITEM_TYPES } from '../constants';
import { useSelectorContext } from '../SidebarContext';

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: var(--positive-green);
  border: 1px solid var(--positive-green);
`;

const SidebarSelectorDropdownActionCompanySelector = () => {
  const { selectedCompany } = useAppSelector((state) => state.global);
  const { handleItemSelected } = useSelectorContext();

  return (
    <SidebarSelectorDropdownAction
      icon={
        <IconContainer>
          <img src={'/icons/business.svg'} style={{ width: '16px', height: '16px' }} />
        </IconContainer>
      }
      title={selectedCompany?.name || ''}
      subtitle={'Company homepage'}
      handleClick={() => {
        handleItemSelected({
          id: selectedCompany?.id || -1,
          type: SELECTOR_ITEM_TYPES.COMPANY,
          name: selectedCompany?.name || '',
        });
      }}
    />
  );
};

export default SidebarSelectorDropdownActionCompanySelector;
