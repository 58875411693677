import React from 'react';

import classNames from 'classnames';
import styled from 'styled-components';

import { AGGREGATION_TYPE, AGGREGATION_TYPE_LABELS } from 'components/View/constants';

type Props = {
  aggregationType: number;
  containerStyle?: React.CSSProperties;
  buttonsWrapperStyle?: React.CSSProperties;
  filterButtonStyle?: React.CSSProperties;
  onSelect: (value: number) => void;
};

const Container = styled.div`
  border-top: 1px solid var(--line-gray);
  max-height: 200px;
  padding: 10px 20px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 105px;
  height: 28px;
  padding: 4px;
  border-radius: 4px;
  background-color: var(--lighter-gray);
`;

const FilterButton = styled.div`
  width: 32px;
  height: 20px;
  padding: 2px 5px;
  border-radius: 3px;
  text-align: center;
  cursor: pointer;

  &.active {
    background-color: #007aff;
    color: var(--white);
  }
`;

const AggregationTypePicker = ({
  aggregationType,
  containerStyle,
  buttonsWrapperStyle,
  filterButtonStyle,
  onSelect,
}: Props) => (
  <Container style={containerStyle}>
    <ButtonsWrapper style={buttonsWrapperStyle}>
      {Object.entries(AGGREGATION_TYPE).map(([key, value]) => (
        <FilterButton
          key={key}
          className={classNames({
            active: value === aggregationType,
          })}
          style={filterButtonStyle}
          onClick={() => onSelect(value)}
        >
          {AGGREGATION_TYPE_LABELS[value]}
        </FilterButton>
      ))}
    </ButtonsWrapper>
  </Container>
);

export default AggregationTypePicker;
