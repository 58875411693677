import React, { useEffect } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';

import { useAppSelector } from 'store/hook';

import AppLayout from '.';

const PrivateRoot = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const currentUser = useAppSelector((state) => state.auth.currentUser);

  useEffect(() => {
    if (!currentUser) {
      navigate('/sign-in');
    } else if (currentUser.pending_password_reset && !currentUser.manager) {
      // Temporary measure intended to enforce stronger password for all users
      navigate('/forgot-password');
    }
  }, [currentUser, navigate]);

  if (location.pathname === '/') {
    return <Navigate to="/home" replace />;
  }

  return (
    <AppLayout>
      <Outlet />
    </AppLayout>
  );
};

export default PrivateRoot;
