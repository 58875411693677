import React from 'react';

import styled from 'styled-components';

import { CompanyListItemType } from './types';

const Container = styled.span`
  display: flex;
  margin: 2px 0px 10px 0px;
`;

const Count = styled.span`
  font-family: var(--font-regular);
  font-size: 14px;
  color: var(--link-blue);
`;

interface Props {
  item: CompanyListItemType;
}

const CompaniesTableCompany = ({ item }: Props) => {
  return (
    <Container>
      <Count>{item?.apartments_count} Communities</Count>
    </Container>
  );
};

export default CompaniesTableCompany;
