import React, { useState } from 'react';
import { useQuery } from 'react-query';

import { CommunityFeeDateType } from './constants';
import { useFeesContext } from './FeesContext';
import FeesFormCommunityFeesTable from './FeesFormCommunityFeesTable';
import { mapCommunityFeeDataToRequest, mapCommunityFeeResponseToData } from './utils';
import {
  createFeesIncentives,
  deleteFeesIncentives,
  getFeesIncentives,
  getLivingInfo,
  updateFeesIncentives,
} from '../../../apis/CommunityAPI';
import { useRootContext } from '../../../components/layout/RootContext';
import { STATUS } from '../../../constants';

const FeesFormCommunityFees = () => {
  const { showMessage } = useRootContext();
  const { communityId } = useFeesContext();

  const [dataLoading, setDataLoading] = useState(STATUS.IDLE);

  const {
    data: communityFeesIncentives,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['communityFeesIncentives', communityId],
    queryFn: () => getFeesIncentives(communityId as number, { active: 1 }),
    select: (data: any[]) => {
      const formatted_data: CommunityFeeDateType[] = data?.map((item: any) => mapCommunityFeeResponseToData(item));
      return formatted_data ?? [];
    },
    onError: () => {
      showMessage('error', 'Failed to fetch Community Fees');
      setDataLoading(STATUS.FAILURE);
    },
    enabled: Boolean(communityId),
    refetchOnWindowFocus: false,
  });

  const handleCreate = (item: Partial<CommunityFeeDateType>) => {
    setDataLoading(STATUS.LOADING);
    const data = mapCommunityFeeDataToRequest(item);

    createFeesIncentives(communityId as number, [data])
      .then((response) => {
        refetch();
        showMessage('success', 'Community Fee added successfully');
        setDataLoading(STATUS.LOADED);
      })
      .catch((reason) => {
        console.error(reason);
        showMessage('error', 'Failed to add Community Fee');
        setDataLoading(STATUS.FAILURE);
      });
  };

  const handleUpdate = (item: Partial<CommunityFeeDateType>) => {
    setDataLoading(STATUS.LOADING);
    const data = mapCommunityFeeDataToRequest(item);

    updateFeesIncentives(communityId as number, item.id as number, data)
      .then((response) => {
        refetch();
        showMessage('success', 'Community Fee changed successfully');
        setDataLoading(STATUS.LOADED);
      })
      .catch((reason) => {
        console.error(reason);
        showMessage('error', 'Failed to change Community Fee');
        setDataLoading(STATUS.FAILURE);
      });
  };

  const handleDelete = (item: Partial<CommunityFeeDateType>) => {
    setDataLoading(STATUS.LOADING);
    deleteFeesIncentives(communityId as number, item.id as number)
      .then((response: any) => {
        refetch();
        showMessage('success', 'Community Fee deleted successfully');
        setDataLoading(STATUS.LOADED);
      })
      .catch((reason: any) => {
        console.error(reason);
        showMessage('error', 'Failed to delete Community Fee');
        setDataLoading(STATUS.FAILURE);
      });
  };

  return (
    <FeesFormCommunityFeesTable
      loading={dataLoading === STATUS.LOADING || isLoading}
      data={communityFeesIncentives ?? []}
      onCreate={handleCreate}
      onUpdate={handleUpdate}
      onDelete={handleDelete}
    />
  );
};

export default FeesFormCommunityFees;
