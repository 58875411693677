import React from 'react';

import styled from 'styled-components';

type Props = {
  backgroundColor?: string;
};

const Container = styled.div<Props>`
  position: absolute;
  height: 1px;
  width: 100%;
  top: -1px;
  left: 0px;
  background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : 'var(--background-gray-light)')};
`;

const CMASummaryBorder = ({ backgroundColor }: Props) => (
  <Container backgroundColor={backgroundColor} className="cma-summary-border" />
);

export default CMASummaryBorder;
