import React from 'react';

import { Typography } from 'antd';
import styled from 'styled-components';

import Select from 'components/lib/Select';

import { useEditBatchShopRequestsModalContext } from './EditBatchShopRequestsModalContext';
import { useShopRequestsContext } from '../ShopRequestsContext';

const StyledSelect = styled(Select)`
  width: 407px;
  margin-right: 30px;
  &&&.ant-select {
    height: 36px;
  }

  &&& .ant-select-selector {
    border-radius: 0;
  }

  .ant-select-selection-item {
    font-size: 14px;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
`;

const Label = styled(Typography.Text)`
  font-family: var(--font-bold);
  margin-right: 10px;
`;

const EditBatchShopRequestsModalShopper = () => {
  const { batchShopRequests, setBatchShopRequests } = useEditBatchShopRequestsModalContext();
  const { staffUsers } = useShopRequestsContext();

  const shopperOptions = [
    { value: -1, label: 'Unassigned' },
    ...staffUsers?.map((user) => ({ value: user.userId, label: user.name })),
  ];

  return (
    <Container>
      <Label>Shopper</Label>
      <StyledSelect
        options={shopperOptions}
        value={batchShopRequests.shopper_id}
        onChange={(shopper_id: number) => {
          setBatchShopRequests({ ...batchShopRequests, shopper_id });
        }}
      />
    </Container>
  );
};

export default EditBatchShopRequestsModalShopper;
