import dayjs from 'dayjs';
import shortUUID from 'short-uuid';

import { CareFeeDateType, CommunityFeeDateType } from './constants';
import { GLOBAL_DATE_FORMAT } from '../../../constants';

export const mapCommunityFeeResponseToData = (item: any) => {
  return {
    id: item?.id,
    key: item ? String(item?.id) : shortUUID.generate(),
    livingTypeId: item?.living_type_id,
    livingType: item?.living_type,
    communityFee: item?.community_fee,
    communityFeeEquivalent: item?.community_fee_equivalent,
    frequency: item?.frequency,
    secondPersonFee: item?.second_person_fee,
    petFee: item?.pet_fee,
    parkingFee: item?.parking_fee,
    respiteFee: item?.respite_fee,
    additionalFee: item?.additional_fee,
    moveInFee: item?.move_in_fee,
    entranceFee: item?.entrance_fee,
    emergencyPendantFee: item?.emergency_pendant_fee,
    deposit: item?.deposit,
    depositEquivalentFactor: item?.deposit_equivalent_factor,
    incentive: item?.incentive,
    incentiveValue: item?.incentive_value,
    createdBy: item?.created_by,
    createdAt: dayjs(new Date(item?.created_at).toDateString(), { format: GLOBAL_DATE_FORMAT }),
    updatedBy: item?.updated_by,
    updatedAt: dayjs(new Date(item?.updated_at).toDateString(), { format: GLOBAL_DATE_FORMAT }),
    date: dayjs(new Date(item?.date).toDateString(), { format: GLOBAL_DATE_FORMAT }),
  };
};

export const mapCommunityFeeDataToRequest = (item: Partial<CommunityFeeDateType>) => {
  return {
    living_type_id: item.livingTypeId,
    community_fee: item.communityFee,
    frequency: item.frequency ? item.frequency : null,
    second_person_fee: item.secondPersonFee,
    pet_fee: item.petFee,
    parking_fee: item.parkingFee,
    respite_fee: item.respiteFee,
    additional_fee: item.additionalFee,
    deposit: item.deposit,
    deposit_equivalent_factor: item.depositEquivalentFactor,
    move_in_fee: item.moveInFee,
    entrance_fee: item.entranceFee,
    emergency_pendant_fee: item.emergencyPendantFee,
    incentive: item.incentive,
    incentive_value: item.incentiveValue,
    date: item?.date,
  };
};

export const mapCareFeeResponseToData = (item: any) => {
  return {
    id: item?.id,
    key: item ? String(item?.id) : shortUUID.generate(),
    livingTypeId: item?.living_type_id,
    livingType: item?.living_type,
    feesTypeId: item?.fees_type_id,
    feesType: item?.fees_type,
    feeNote: item?.fee_note,
    medicationManagementFee: item?.medication_management_fee,
    averageCareCost: item?.average_care_cost,
    level1: item?.level_1,
    level2: item?.level_2,
    level3: item?.level_3,
    level4: item?.level_4,
    level5: item?.level_5,
    level6: item?.level_6,
    level7: item?.level_7,
    level8: item?.level_8,
    createdBy: item?.created_by,
    createdAt: dayjs(new Date(item?.created_at).toDateString(), { format: GLOBAL_DATE_FORMAT }),
    updatedBy: item?.updated_by,
    updatedAt: dayjs(new Date(item?.updated_at).toDateString(), { format: GLOBAL_DATE_FORMAT }),
    date: dayjs(new Date(item?.date).toDateString(), { format: GLOBAL_DATE_FORMAT }),
  };
};

export const mapCareFeeDataToRequest = (item: Partial<CareFeeDateType>) => {
  return {
    living_type_id: item.livingTypeId,
    fees_type_id: item.feesTypeId,
    fee_note: item.feeNote,
    medication_management_fee: item.medicationManagementFee,
    average_care_cost: item?.averageCareCost,
    level_1: item?.level1,
    level_2: item?.level2,
    level_3: item?.level3,
    level_4: item?.level4,
    level_5: item?.level5,
    level_6: item?.level6,
    level_7: item?.level7,
    level_8: item?.level8,
    date: item?.date,
  };
};
