import React from 'react';

import styled from 'styled-components';

import { useAppSelector } from 'store/hook';

import SidebarMenuAddView from './SidebarMenuAddView';
import SidebarMenuCategoryHeader from './SidebarMenuCategoryHeader';
import SidebarMenuCmaSummary from './SidebarMenuCmaSummary';
import SidebarMenuItem from './SidebarMenuItem';
import { ACTOR_TYPE } from '../../../constants';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const SidebarMenuCommunityViews = () => {
  const { views, selectedCommunity } = useAppSelector((state) => state.global);

  const communityType =
    selectedCommunity?.type === ACTOR_TYPE.COMPETITOR ? ACTOR_TYPE.COMPETITOR : ACTOR_TYPE.COMMUNITY;

  const defaultCompanyView = views.length && views[0];
  const customCompanyViews = views.filter((_, index) => index > 0);
  return (
    <Container>
      <SidebarMenuCategoryHeader title={'VIEWS'}>
        <SidebarMenuAddView />
      </SidebarMenuCategoryHeader>
      {Boolean(defaultCompanyView) && (
        <SidebarMenuItem
          key={defaultCompanyView.id}
          to={`/${communityType}/${selectedCommunity?.id}/view/${defaultCompanyView.id}`}
          label={defaultCompanyView.name}
        />
      )}
      {<SidebarMenuCmaSummary key={'cma-summary-menu-item'} />}
      {customCompanyViews?.map((item, index) => (
        <SidebarMenuItem
          key={item.id}
          to={`/${communityType}/${selectedCommunity?.id}/view/${item.id}`}
          label={item.name}
        />
      ))}
    </Container>
  );
};

export default SidebarMenuCommunityViews;
