import React from 'react';

import { Typography } from 'antd';

import CommunityIncentivesTable from './CommunityIncentivesTable';

interface Props {
  communityId: number;
}

const CommunityIncentives = ({ communityId }: Props) => {
  return (
    <div>
      <Typography.Title level={3}>Incentives</Typography.Title>
      <CommunityIncentivesTable communityId={communityId} />
    </div>
  );
};

export default CommunityIncentives;
