import React from 'react';

import styled from 'styled-components';

import Modal from 'components/lib/Modal';

import { usePhotosContext } from './PhotosContext';
import PhotosPreviewContent from './PhotosPreviewContent';

const StyledModal = styled(Modal)`
  .ant-modal-close {
    background: var(--white);
    width: 41px;
    height: 41px;
    border-radius: 50%;
    right: 90px;
  }

  &&& .ant-modal-content {
    background: rgba(0, 0, 0, 0.9) !important;
  }

  .ant-modal-close-x {
    font-size: 24px;
    color: rgba(0, 0, 0);
  }

  &&& .ant-modal-footer {
    display: none;
  }

  &&& .ant-modal-body {
    padding: 0;
  }
`;

const PhotosPreview = () => {
  const { openPreviewPhotosModal, setOpenPreviewPhotosModal } = usePhotosContext();
  return (
    <StyledModal
      wrapClassName="black-mask"
      open={openPreviewPhotosModal}
      onCancel={() => setOpenPreviewPhotosModal(false)}
      width="fit-content"
      destroyOnClose={true}
      maskClosable={false}
    >
      <PhotosPreviewContent />
    </StyledModal>
  );
};

export default PhotosPreview;
