import React from 'react';

import styled from 'styled-components';

import { useAppSelector } from 'store/hook';

import SidebarMenuCategoryHeader from './SidebarMenuCategoryHeader';
import SidebarMenuItem from './SidebarMenuItem';
import { ACTOR_TYPE } from '../../../constants';
import { isIncentivesCorporate, isIncentivesSalesperson } from '../../../helpers/permissions';

const Container = styled.div`
  margin-top: 10px;
`;

const Beta = styled.span`
  margin-left: 4px;
  padding: 4px 8px;
  color: var(--competitor-purple);
  background: var(--neutral-purple-light);
  font-family: var(--font-bold);
  border-radius: 6px;
`;

const SidebarMenuOther = () => {
  const { currentUser } = useAppSelector((state) => state.auth);
  const { selectedCommunity, selectedManagementCompany, selectedCompany } = useAppSelector((state) => state.global);

  const showIncentives =
    currentUser && selectedCompany && (isIncentivesCorporate(currentUser) || isIncentivesSalesperson(currentUser));
  const showSettings = currentUser?.feature_flags?.show_beta_settings;
  const showBetaCommunityData = currentUser?.feature_flags?.show_beta_community_data;

  const BetaTag = (
    <span>
      Community Data<Beta>Beta</Beta>
    </span>
  );

  return (
    <Container>
      <SidebarMenuCategoryHeader title={'OTHER'} />
      {Boolean(selectedCommunity) && (
        <SidebarMenuItem to={`/${selectedCommunity?.type}/${selectedCommunity?.id}/info`} label={'Community Data'} />
      )}

      {Boolean(selectedCommunity) && showBetaCommunityData && (
        <SidebarMenuItem to={`/${selectedCommunity?.type}/${selectedCommunity?.id}/info-beta`} label={BetaTag} />
      )}

      {showIncentives && (
        <SidebarMenuItem
          to={selectedManagementCompany ? `/incentives/${selectedManagementCompany?.id}` : '/incentives'}
          label={'Incentives'}
        />
      )}
      {selectedCommunity?.type === ACTOR_TYPE.COMMUNITY && (
        <SidebarMenuItem to={`/community/${selectedCommunity?.id}/competitors`} label={'Competitors'} />
      )}
      {showSettings && <SidebarMenuItem to={`/preferences/${selectedManagementCompany?.id}`} label={'Settings'} />}
    </Container>
  );
};

export default SidebarMenuOther;
