import React from 'react';

import { updateIncentiveUnitLink } from '../../../apis/IncentiveAPI';
import ConfirmModal from '../../../common/Modal/ConfirmModal';
import { useRootContext } from '../../layout/RootContext';

interface Props {
  open: boolean;
  incentives: string[];
  onClose: any;
}

const IncentiveUnitArchiveModal = ({ open, incentives, onClose }: Props) => {
  const { showMessage } = useRootContext();

  const handleConfirm = async () => {
    Promise.all(
      incentives.map((id: string) => {
        return updateIncentiveUnitLink(id, { archived_at: new Date() });
      })
    )
      .then((response: any) => {
        showMessage('success', 'Incentive record archived.');
        onClose();
      })
      .catch((reason: any) => {
        showMessage('error', 'Trouble archiving incentive record');
      });
  };

  return (
    <ConfirmModal
      open={open}
      title={'Archive Incentive Record?'}
      description={
        `This will remove the incentive(s) from the selected unit(s) and archive the\n` +
        'record. The incentive, if applied to other units, will still remain.'
      }
      confirmLabel={'Archive'}
      onConfirm={handleConfirm}
      onCancel={onClose}
    />
  );
};

export default IncentiveUnitArchiveModal;
