import React from 'react';

import { archiveIncentive } from '../../../apis/IncentiveAPI';
import ConfirmModal from '../../../common/Modal/ConfirmModal';
import { useRootContext } from '../../layout/RootContext';
import { INCENTIVE_STATUS } from '../constants';
import { IncentiveType } from '../types';

interface Props {
  open: boolean;
  incentive: IncentiveType;
  onClose: any;
}

const IncentiveArchiveModal = ({ open, incentive, onClose }: Props) => {
  const { showMessage } = useRootContext();

  const handleConfirm = async () => {
    await archiveIncentive(incentive.uuid)
      .then((response: any) => {
        showMessage('success', 'Incentive archived.');
        onClose();
      })
      .catch((reason: any) => {
        showMessage('error', 'Trouble archiving incentive');
      });
  };

  return (
    <ConfirmModal
      open={open}
      title={'Archive Incentive?'}
      description={
        `This will remove the incentive from the selected unit(s) and archive it.\n` +
        'Are you sure you want to do this?'
      }
      confirmLabel={'Archive'}
      onConfirm={handleConfirm}
      onCancel={onClose}
    />
  );
};

export default IncentiveArchiveModal;
