import React from 'react';

import styled from 'styled-components';

import { CompanyListItemType } from './types';

const Container = styled.span`
  display: flex;
  margin: 2px 0px 10px 0px;
`;

const Title = styled.span`
  font-family: var(--font-bold);
  font-size: 14px;
  color: var(--text-primary);
`;

interface Props {
  item: CompanyListItemType;
}

const CompaniesTableCompany = ({ item }: Props) => {
  return (
    <Container>
      <Title>{item?.name}</Title>
    </Container>
  );
};

export default CompaniesTableCompany;
