import React from 'react';

import styled from 'styled-components';

import { useCompaniesContext } from '../CompaniesContext';

const Link = styled.span`
  color: var(--active-blue);
  font-size: 10px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  pointer-events: cursor;
`;

const CompaniesFiltersReset = () => {
  const { filters, resetFilters } = useCompaniesContext();

  const currentFilters = [filters.status];
  const active = currentFilters.some((filter: any) => Boolean(filter.length));

  if (!active) return null;

  return <Link onClick={resetFilters}>Reset</Link>;
};

export default CompaniesFiltersReset;
