import React from 'react';

import HeaderUserActions from './HeaderUserActions';
import HeaderUserApplicationSwitch from './HeaderUserApplicationSwitch';
import classes from './styles.module.scss';
import { useAppSelector } from '../../../store/hook';

const HeaderUser = () => {
  const { currentUser } = useAppSelector((state) => state.auth);

  return (
    <div className={classes.header_right}>
      {currentUser && !currentUser.isSuperuser && <HeaderUserApplicationSwitch />}
      <HeaderUserActions />
    </div>
  );
};

export default HeaderUser;
