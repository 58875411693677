import React from 'react';

import styled from 'styled-components';

const Container = styled.div`
  background: var(--text-secondary);
  color: var(--white);
  font-size: 12px;
  font-family: var(--font-bold);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

type Props = {
  label?: string;
};

const PreviewUnavailable = ({ label = 'Preview unavailable' }: Props) => {
  return <Container>{label}</Container>;
};

export default PreviewUnavailable;
