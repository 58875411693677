import React, { useContext } from 'react';

import classNames from 'classnames';
import styled from 'styled-components';

import Avatar from '../../../../common/Avatar';
import HolidayVillage from '../../../../common/Icons/HolidayVillage';
import { ACTOR_TYPE } from '../../../../constants';
import { SELECTOR_ITEM_DESCRIPTION } from '../constants';
import { SidebarContext } from '../SidebarContext';
import { SelectorItemDatatype } from '../types';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 13px 10px;
  gap: 10px;
  border-top: solid 1px var(--line-gray);
  flex-grow: 1;
  cursor: pointer;

  &:hover {
    background-color: var(--background-gray);
  }

  &.selected {
    background-color: var(--background-gray);
  }

  &:first-child {
    border-top: none;
  }
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
`;

const Title = styled.div`
  font-family: var(--font-bold);
  font-size: 14px;
  color: var(--text-primary):
`;

const Description = styled.div`
  display: flex;
  font-size: 12px;
  color: var(--gray-dark);
  white-space: nowrap;
`;

const Address = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Type = styled.span`
  display: flex;
  color: var(--text-primary);
  white-space: nowrap;

  &.competitor {
    color: var(--neutral-purple);
  }
`;

const Dot = styled.span`
  padding: 0px 4px;
`;

interface Props {
  item: SelectorItemDatatype;
}

const SidebarSelectorDropdownListItem = ({ item }: Props) => {
  const { selectedItem, handleItemSelected } = useContext(SidebarContext);

  const selectorItemIcons = {
    [ACTOR_TYPE.MANAGEMENT_COMPANY]: <HolidayVillage size={16} />,
    [ACTOR_TYPE.COMMUNITY]: undefined,
    [ACTOR_TYPE.COMPETITOR]: undefined,
  };

  return (
    <Container
      className={classNames({ selected: item.id === selectedItem?.id && item.type === selectedItem?.type })}
      onClick={() => handleItemSelected(item)}
    >
      <Avatar name={item.name} type={item.type} icon={selectorItemIcons[item.type]} />
      <Details>
        <Title>{item.name}</Title>
        <Description>
          <Type className={classNames({ competitor: item.type === ACTOR_TYPE.COMPETITOR })}>
            <span>{item.label ?? SELECTOR_ITEM_DESCRIPTION[item.type]}</span>
          </Type>
          {[ACTOR_TYPE.COMPETITOR, ACTOR_TYPE.COMMUNITY, ACTOR_TYPE.USER].includes(item.type) && (
            <>
              <Dot>·</Dot>
              <Address>{item.description}</Address>
            </>
          )}
        </Description>
      </Details>
    </Container>
  );
};

export default SidebarSelectorDropdownListItem;
