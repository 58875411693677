import React, { ReactNode } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import styled from 'styled-components';

import { getSavings } from 'apis/IncentiveAPI';
import { useAppSelector } from 'store/hook';

import { INCENTIVES_FEES_TYPE } from './constants';
import AutoComplete from '../../lib/AutoComplete';
import { IncentiveSavingType } from '../types';

const StyledDropdownMenu = styled.div`
  .ant-select-item-option-content {
    display: flex;
    align-items: center;
  }
`;

interface Props {
  savingsItem: IncentiveSavingType;
  onChange: any;
}

const IncentivesCreateOffersSavingsEntryCustomName = ({ savingsItem, onChange }: Props) => {
  const { managementCompanyId } = useParams();
  const { selectedCompany } = useAppSelector((state) => state.global);

  const { data: savingsOptions } = useQuery({
    queryKey: ['savings', selectedCompany, managementCompanyId, savingsItem?.name],
    queryFn: () =>
      getSavings({
        search: savingsItem?.name,
        companies: selectedCompany ? [selectedCompany?.id] : [],
        management_companies: managementCompanyId ? [parseInt(managementCompanyId)] : [],
        types: [INCENTIVES_FEES_TYPE.CUSTOM],
      }),
    enabled: Boolean(selectedCompany) || Boolean(managementCompanyId),
    refetchOnWindowFocus: false,
  });

  const uniqueSavingsOptions = [
    ...new Set((savingsOptions ?? []).map((item: IncentiveSavingType) => item?.name ?? '')),
  ].map((name) => ({
    value: name as string,
  }));

  return (
    <AutoComplete
      style={{ height: 36 }}
      value={savingsItem.name}
      dropdownStyle={{
        border: '1px solid var(--line-gray)',
        borderRadius: 0,
        padding: 0,
      }}
      dropdownRender={(menu: ReactNode) => <StyledDropdownMenu>{menu}</StyledDropdownMenu>}
      placeholder={'Enter savings name'}
      options={uniqueSavingsOptions}
      onChange={(value: string) => onChange(value, 'name')}
    />
  );
};

export default IncentivesCreateOffersSavingsEntryCustomName;
