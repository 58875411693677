import React, { useContext } from 'react';

import { Breadcrumb } from 'antd';
import styled from 'styled-components';

import { CommunityInfoContext } from './CommunityInfoContext';

const StyledBreadcrumb = styled(Breadcrumb)`
  span {
    color: var(--text-primary);

    &.competitor {
      color: var(--competitor-purple);
    }

    &.terminal {
      color: var(--text-secondary);
    }
  }
`;

const CompetitorHeaderBreadcrumbs = () => {
  const { communityInfo } = useContext(CommunityInfoContext);

  return (
    <StyledBreadcrumb
      items={[
        {
          title: communityInfo?.comunity_name,
        },
      ]}
      separator="·"
    />
  );
};

export default CompetitorHeaderBreadcrumbs;
