import React, { ReactNode } from 'react';

import classNames from 'classnames';
import styled from 'styled-components';

import SelectAdvanced from '../../lib/SelectAdvanced';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 185px;
`;

const Title = styled.div`
  color: var(--text-primary);
  font-size: 12px;
  margin-bottom: 5px;

  &.heading {
    font-family: var(--font-bold);
    font-size: 14px;
    margin-bottom: 10px;
  }
`;

interface Props {
  title: string;
  searchPlaceholder?: string;
  heading?: boolean;
  disabled?: boolean;
  options: any;
  otherOptions?: ReactNode;
  otherOptionsLabel?: string;
  defaultValue: any[];
  onSelect?: any;
}

const CommunityViewWidgetModalFilter = ({
  title,
  heading = false,
  disabled = false,
  options,
  otherOptions,
  otherOptionsLabel,
  defaultValue,
  onSelect,
}: Props) => {
  const handleSelect = (items: any[]) => {
    onSelect(items.map((item) => item.value));
  };

  return (
    <Container>
      <Title className={classNames({ heading })}>{title}</Title>
      <SelectAdvanced
        disabled={disabled}
        placeholder={title}
        options={options}
        otherOptions={otherOptions}
        otherOptionsLabel={otherOptionsLabel}
        defaultValue={defaultValue}
        onSelect={handleSelect}
      />
    </Container>
  );
};

export default CommunityViewWidgetModalFilter;
