import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';

import { Spin } from 'antd';
import styled from 'styled-components';

import ImageWrapper from './ImageWrapper';
import { PhotosContext } from './PhotosContext';
import PhotosFilter from './PhotosFilter';
import PhotosPreview from './PhotosPreview';
import { getCommunityPhotos } from '../../../../apis/CommunityAPI';
import { FILE_SOURCE } from '../../../../constants';
import Header from '../Header';

const Container = styled.div`
  box-sizing: border-box;
  position: relative;
`;

const SpinWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  padding: 20px;
`;

const Content = styled.div`
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  max-height: 320px;
  overflow-y: auto;
`;

const Photos = () => {
  const [selectedPhotoIndex, setSelectedPhotoIndex] = useState<number>(0);
  const [openPreviewPhotosModal, setOpenPreviewPhotosModal] = useState<boolean>(false);
  const [photos, setPhotos] = useState<any[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string>('');

  const { competitorId } = useParams();
  const { communityId } = useParams();
  const id = Number(competitorId || communityId);

  const { data, isLoading } = useQuery({
    queryKey: ['community', 'photos', id, selectedCategory],
    queryFn: () => getCommunityPhotos(id, { image_category: selectedCategory || null, source: [FILE_SOURCE.MEDIA] }),
    onSuccess: (data) => {
      setPhotos(data);
    },
    enabled: Boolean(id),
  });

  const previewHandler = (index: number) => {
    setSelectedPhotoIndex(index);
    setOpenPreviewPhotosModal(true);
  };

  return (
    <PhotosContext.Provider
      value={{
        selectedPhotoIndex,
        openPreviewPhotosModal,
        photos,
        selectedCategory,
        setSelectedCategory,
        setSelectedPhotoIndex,
        setOpenPreviewPhotosModal,
        setPhotos,
      }}
    >
      <Container>
        <Header title="Photos" filter={<PhotosFilter />} />
        {isLoading && (
          <SpinWrapper>
            <Spin />
          </SpinWrapper>
        )}
        {data && (
          <Content className="mini-scroll">
            {data.map((item: any, index: number) => (
              <ImageWrapper key={item.id} item={item} onClick={() => previewHandler(index)} />
            ))}
          </Content>
        )}
        <PhotosPreview />
      </Container>
    </PhotosContext.Provider>
  );
};

export default Photos;
