import React, { useState } from 'react';
import { useQuery } from 'react-query';

import { CareFeeDateType } from './constants';
import { useFeesContext } from './FeesContext';
import FeesFormCareFeesTable from './FeesFormCareFeesTable';
import { mapCareFeeDataToRequest, mapCareFeeResponseToData } from './utils';
import { createCareFees, deleteCareFees, getCareFees, updateCareFees } from '../../../apis/CommunityAPI';
import { useRootContext } from '../../../components/layout/RootContext';
import { STATUS } from '../../../constants';

const FeesFormCareFees = () => {
  const { showMessage } = useRootContext();
  const { communityId } = useFeesContext();

  const [dataLoading, setDataLoading] = useState(STATUS.IDLE);

  const {
    data: careFees,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['careFees', communityId],
    queryFn: () => getCareFees(communityId as number, { active: 1 }),
    select: (data: any[]) => {
      const formatted_data: CareFeeDateType[] = data?.map((item: any) => mapCareFeeResponseToData(item));
      return formatted_data ?? [];
    },
    onError: () => {
      showMessage('error', 'Failed to fetch Care Fees');
      setDataLoading(STATUS.FAILURE);
    },
    enabled: Boolean(communityId),
    refetchOnWindowFocus: false,
  });

  const handleCreate = (item: Partial<CareFeeDateType>) => {
    const data = mapCareFeeDataToRequest(item);

    createCareFees(communityId as number, [data])
      .then((response) => {
        refetch();
        showMessage('success', 'Care Fee created successfully');
        setDataLoading(STATUS.LOADED);
      })
      .catch((reason) => {
        console.error(reason);
        showMessage('error', 'Failed to create Care Fee');
        setDataLoading(STATUS.FAILURE);
      });
  };

  const handleUpdate = (item: Partial<CareFeeDateType>) => {
    setDataLoading(STATUS.LOADING);
    const data = mapCareFeeDataToRequest(item);

    updateCareFees(communityId as number, item.id as number, data)
      .then((response) => {
        refetch();
        showMessage('success', 'Care Fee changed successfully');
        setDataLoading(STATUS.LOADED);
      })
      .catch((reason) => {
        console.error(reason);
        showMessage('error', 'Failed to change Care Fee');
        setDataLoading(STATUS.FAILURE);
      });
  };

  const handleDelete = (item: Partial<CareFeeDateType>) => {
    setDataLoading(STATUS.LOADING);
    deleteCareFees(communityId as number, item.id as number)
      .then((response) => {
        refetch();
        showMessage('success', 'Care Fee deleted successfully');
        setDataLoading(STATUS.LOADED);
      })
      .catch((reason) => {
        console.error(reason);
        showMessage('error', 'Failed to delete Care Fee');
        setDataLoading(STATUS.FAILURE);
      });
  };

  return (
    <FeesFormCareFeesTable
      loading={dataLoading === STATUS.LOADING || isLoading}
      data={careFees ?? []}
      onCreate={handleCreate}
      onUpdate={handleUpdate}
      onDelete={handleDelete}
    />
  );
};

export default FeesFormCareFees;
