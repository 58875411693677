import React, { useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { Avatar, Button, message, Select, Spin, Typography } from 'antd';
import GoogleMapReact from 'google-map-react';

import axios from 'config/axiosPrivate';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { handleClearCommunityData } from 'store/slices/communitySlice';

import { mapCareFeeDataToRequest, mapCommunityFeeDataToRequest } from '../price_healing/Fees/utils';

interface Props {
  current: number;
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
  onCreate: (competitorResponse: any, communityResponse: any) => void;
}

type CenterType = { lat: number; lng: number };

const AnyReactComponent = ({ ...rest }: any) => (
  <div>
    <Avatar src="/further_logo.png" size={60} />
  </div>
);

const CommunitySelectForm = ({ current, setCurrent, onCreate }: Props) => {
  const navigate = useNavigate();
  const { managementCompanyId } = useParams();
  const dispatch = useAppDispatch();
  const [params] = useSearchParams();
  const community_id = params.get('cid') as string;
  const [messageApi, contextHolder] = message.useMessage();
  const basic_info = useAppSelector((state) => state.community.basic_info);

  const accommodation_and_care = useAppSelector((state) => state.community.cares_info);
  const communityFees = useAppSelector((state) => state.community.communityFees);
  const careFees = useAppSelector((state) => state.community.careFees);
  const { communityList } = useAppSelector((state) => state.global);

  const [communityOptions, setCommunityOptions] = React.useState<any[]>([]);
  const [selectedCommunity, setSelectedCommunity] = React.useState<any>(null);
  const [center, setCenter] = React.useState<CenterType>({
    lat: 44.5,
    lng: -89.5,
  });
  const [loading, setLoading] = React.useState<boolean>(false);

  useEffect(() => {
    const options: any[] = communityList?.map((ele: any) => ({
      ...ele,
      label: ele?.apartmentname,
      value: ele?.Id,
    }));

    if (community_id) {
      const selected = options.find((el) => `${el?.value}` === `${community_id}`);
      if (selected) {
        setSelectedCommunity(selected);
      }
    }
    setCommunityOptions(options);
  }, [communityList]);

  const handleCreateCompetitor = async () => {
    // Create Competitor Community instance. Response used in future requests
    try {
      setLoading(true);
      const basicResponse = await axios
        .post('/communities/Save_AddComm_CompetitorInfo/', {
          data: [
            {
              ...basic_info,
              apartment_id: selectedCommunity?.Id,
              company_id: selectedCommunity?.company_id,
              management_company_id: selectedCommunity?.management_company_id,
            },
          ],
        })
        .then((res) => res.data);

      // Bulk create Accommodation and Care (Living Info) records
      const accommodationCareData: any[] = [];
      accommodation_and_care.forEach((accommodation: any) => {
        accommodation.livingTypes.forEach((livingType: any) => {
          if (livingType.value) {
            const accommodationEntry = {
              apartment_type_id: accommodation.apartmentTypeId,
              living_type_id: livingType.id,
              base_rent: livingType.value,
              sq_footage: accommodation.squareFootage,
              price_per_sq: livingType.value / accommodation.squareFootage,
            };
            accommodationCareData.push(accommodationEntry);
          }
        });
      });

      axios
        .post(`/communities/${basicResponse?.id}/living-info`, accommodationCareData)
        .then((response) => response.data)
        .catch((reason) => {
          console.error(reason);
        });

      // Bulk create community Fee Incentives records
      const communityFeeData = communityFees.map((el: any) => {
        const communityFeeData = {
          ...el,
          additional_fee: el?.additional_fee || 0,
          community_fee: el?.community_fee || 0,
          pet_fee: el?.pet_fee || 0,
          respite_fee: el?.respite_fee || 0,
          second_person_fee: el?.second_person_fee || 0,
        };
        return mapCommunityFeeDataToRequest(communityFeeData);
      });

      await axios.post(`communities/${basicResponse?.id}/fee-incentives`, communityFeeData).then((res) => res.data);

      // Bulk create community Care Fees records
      const communityCareFeeData = careFees.map((el: any) => {
        const communityCareFeeData = {
          ...el,
          level_1: el?.level_1 || 0,
          level_2: el?.level_2 || 0,
          level_3: el?.level_3 || 0,
          level_4: el?.level_4 || 0,
          level_5: el?.level_5 || 0,
          level_6: el?.level_6 || 0,
          level_7: el?.level_7 || 0,
          level_8: el?.level_8 || 0,
          medication_management_fee: el?.medication_management_fee || 0,
          average_care_cost: el?.average_care_cost || 0,
        };

        return mapCareFeeDataToRequest(communityCareFeeData);
      });

      await axios.post(`communities/${basicResponse?.id}/care-fees`, communityCareFeeData).then((res) => res.data);

      dispatch(handleClearCommunityData());
      setLoading(false);

      // Handle routing outside this component
      onCreate(basicResponse, selectedCommunity);
    } catch (err: any) {
      setLoading(false);
      messageApi.open({
        type: 'error',
        content: err?.response?.data[0],
        duration: 5,
      });
    }
  };

  return (
    <div>
      {contextHolder}
      {loading && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'rgb(240 240 240 / 71%)',
            zIndex: 99,
          }}
        >
          <Spin size="large" />
        </div>
      )}
      <div
        style={{
          marginBottom: 10,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Select
          placeholder="Select"
          options={communityOptions}
          value={selectedCommunity}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
          }
          style={{ width: '100%', maxWidth: 450 }}
          onSelect={(_, option) => {
            setSelectedCommunity(option);
            setCenter({
              lat: parseFloat(option?.latitude || 0),
              lng: parseFloat(option?.longitude || 0),
            });
          }}
        />
      </div>
      {selectedCommunity && (
        <Typography.Paragraph style={{ textAlign: 'center', marginBottom: 20, fontSize: 16 }}>
          <strong>Address: </strong>
          {selectedCommunity?.apartmentaddress}
        </Typography.Paragraph>
      )}

      <div style={{ width: '100%', height: '550px', position: 'relative' }}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: 'AIzaSyAkEDUPfo1e_5N59IClF74jjicyKbTMYw4',
          }}
          defaultZoom={8}
          center={center}
          key={selectedCommunity?.id}
        >
          {selectedCommunity && (
            <AnyReactComponent lat={selectedCommunity?.latitude} lng={selectedCommunity?.longitude} />
          )}
        </GoogleMapReact>
      </div>
      <div
        style={{
          width: '100%',
          borderTop: '2px solid #eee',
          padding: '10px 30px',
          boxSizing: 'border-box',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          minHeight: '80px',
          marginTop: 30,
        }}
      >
        <Button onClick={() => navigate(`/overview/${managementCompanyId}`)}>Cancel</Button>
        <div style={{ textAlign: 'center', padding: '20px 50px', maxWidth: '70%' }}>
          <Typography.Title level={4} style={{ margin: 0 }}>
            {basic_info?.comunity_name}
          </Typography.Title>
          <Typography.Paragraph style={{ color: '#ccc', fontSize: 16 }}>{basic_info?.address}</Typography.Paragraph>
        </div>
        <div>
          {current !== 0 && (
            <Button style={{ marginRight: 10 }} onClick={() => setCurrent((prevState) => prevState - 1)}>
              Previous Step
            </Button>
          )}
          <Button
            type="primary"
            htmlType="button"
            onClick={handleCreateCompetitor}
            loading={loading}
            disabled={loading || !selectedCommunity}
          >
            Finish
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CommunitySelectForm;
