import React from 'react';

import styled from 'styled-components';

const Title = styled.div`
  font-family: var(--font-regular);
  font-size: 14px;
`;

const Subtitle = styled.div`
  color: var(--text-secondary);
  font-size: 12px;
`;

type Props = {
  occupancy?: [number, number, number];
};

const CMASummaryTableDataLivingTypeOccupancy = ({ occupancy }: Props) => {
  if (!occupancy?.length) {
    return null;
  }
  const [occupancyPercentage, totalApartmentsUnits, freeApartmentsUnits] = occupancy;

  const percentage = occupancyPercentage ? `${occupancyPercentage}%` : '';

  const freeUnits =
    totalApartmentsUnits && freeApartmentsUnits
      ? `${totalApartmentsUnits - freeApartmentsUnits}/${totalApartmentsUnits} units`
      : '';

  return (
    <div>
      <Title>{percentage}</Title>
      <Subtitle>{freeUnits}</Subtitle>
    </div>
  );
};

export default CMASummaryTableDataLivingTypeOccupancy;
