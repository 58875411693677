import React from 'react';
import DatePickerCalendar from 'react-datepicker';

import { Typography } from 'antd';
import styled from 'styled-components';

import { useEditBatchShopRequestsModalContext } from './EditBatchShopRequestsModalContext';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
`;

const Label = styled(Typography.Text)`
  font-family: var(--font-bold);
  margin-right: 10px;
`;

const DatePicker = styled(DatePickerCalendar)`
  font-size: 14px;
  width: 407px;
  padding: 8px 0 7px 12px;
  border: solid 1px var(--line-gray);
  background-color: var(--white);
  border-radius: 0;
  cursor: pointer;
  box-sizing: border-box;
  margin-right: 30px;
`;

const EditBatchShopRequestsModalShopper = () => {
  const { batchShopRequests, setBatchShopRequests } = useEditBatchShopRequestsModalContext();

  const changeDate = (date: Date) => {
    setBatchShopRequests({ ...batchShopRequests, due_date: date });
  };

  return (
    <Container>
      <Label>Due Date</Label>
      <DatePicker
        selected={batchShopRequests?.due_date}
        minDate={new Date()}
        showPopperArrow={false}
        onChange={(date) => changeDate(date!)}
        className="date-picker"
      />
    </Container>
  );
};

export default EditBatchShopRequestsModalShopper;
