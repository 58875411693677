import React from 'react';

import { message } from 'antd';

import { AccommodationContext } from './AccommodationContext';
import AnnualRateIncrease from './AnnualRateIncrease';
import MarketRates from './MarketRates';

interface Props {
  community_id: any;
}

const AccommodationForm = ({ community_id }: Props) => {
  return (
    <AccommodationContext.Provider value={{ communityId: parseInt(community_id) }}>
      <MarketRates />
      <AnnualRateIncrease communityId={parseInt(community_id)} />
    </AccommodationContext.Provider>
  );
};

export default AccommodationForm;
