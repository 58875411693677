import React from 'react';

import styled from 'styled-components';

import SidebarSelectorDropdownListAdmin from './SidebarSelectorDropdownListAdmin';
import SidebarSelectorDropdownListUser from './SidebarSelectorDropdownListUser';
import { useAppSelector } from '../../../../store/hook';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 400px;
  overflow-x: auto;
  border-top: solid 1px var(--line-gray);

  &.empty {
    border-top: none;
  }
`;

const SidebarSelectorDropdownList = () => {
  const { currentUser } = useAppSelector((state) => state.auth);

  return currentUser?.isSuperuser ? <SidebarSelectorDropdownListAdmin /> : <SidebarSelectorDropdownListUser />;
};

export default SidebarSelectorDropdownList;
