import React from 'react';

import styled from 'styled-components';

import AuthenticationSidePanel from './AuthenticationSidePanel';

type Props = {
  children: React.ReactNode;
};

const Container = styled.div`
  min-height: 100vh;
  height: 100vh;
  display: flex;
  align-items: center;
`;

const AuthForm = styled.div`
  padding: 30px;
  box-sizing: border-box;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: var(--white);
`;

const AuthenticationLayout = ({ children }: Props) => {
  return (
    <Container>
      <AuthenticationSidePanel />
      <AuthForm>{children}</AuthForm>
    </Container>
  );
};

export default AuthenticationLayout;
