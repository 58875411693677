import React, { useContext } from 'react';

import styled from 'styled-components';

import { useAppSelector } from 'store/hook';

import SidebarSelectorDropdownActionAdminSwitch from './SidebarSelectorDropdownActionAdminSwitch';
import SidebarSelectorDropdownActionCompanySelector from './SidebarSelectorDropdownActionCompanySelector';
import SidebarSelectorDropdownControls from './SidebarSelectorDropdownControls';
import SidebarSelectorDropdownList from './SidebarSelectorDropdownList';
import { USER_LEVELS } from '../../../../constants';
import { SELECTOR_ITEM_TYPES } from '../constants';
import { SidebarContext } from '../SidebarContext';

const Container = styled.div`
  position: absolute;
  margin-left: 4px;
  width: 450px;
  box-shadow: 0 2px 10px 0 var(--box-shadow-gray-dark);
  border: solid 1px var(--line-gray);
  background-color: #fff;
  z-index: 100;
`;

const SidebarSelectorDropdown = () => {
  const { currentUser } = useAppSelector((state) => state.auth);
  const { selectedClient } = useAppSelector((state) => state.global);
  const { selectedItem } = useContext(SidebarContext);

  const showAdminSwitch = Boolean(currentUser?.manager);
  const showCompanySelector =
    selectedClient &&
    selectedClient?.level != USER_LEVELS.MANAGEMENT_COMPANY &&
    selectedItem?.type != SELECTOR_ITEM_TYPES.COMPANY;

  return (
    <Container>
      {showAdminSwitch && <SidebarSelectorDropdownActionAdminSwitch />}
      {showCompanySelector && <SidebarSelectorDropdownActionCompanySelector />}
      <SidebarSelectorDropdownControls />
      <SidebarSelectorDropdownList />
    </Container>
  );
};

export default SidebarSelectorDropdown;
