import React from 'react';

import classNames from 'classnames';

import { WIDGET_FILTER_OPTIONS, WIDGET_FILTER_TYPES } from 'common/Widgets/constants';
import SelectAdvancedFilter from 'components/lib/SelectAdvancedFilter';

type Props = {
  reviewSources: any;
  setFilters: (value: any) => void;
};

const ReviewSourceFilter = ({ reviewSources, setFilters }: Props) => {
  const handleSelect = (values: number[]) => {
    setFilters({ reviewSources: values.map((item: any) => item.value) });
  };

  const options = WIDGET_FILTER_OPTIONS[WIDGET_FILTER_TYPES.REVIEW_SOURCE].map((item) => ({
    label: item.name,
    value: item.id,
  }));

  return (
    <SelectAdvancedFilter
      className={classNames({ active: reviewSources?.length > 0 })}
      placeholder={'Review Source'}
      options={options}
      defaultValue={reviewSources}
      onSelect={handleSelect}
    />
  );
};

export default ReviewSourceFilter;
