import React from 'react';

import styled from 'styled-components';

import { CompanyListItemType } from './types';

const Container = styled.span`
  display: flex;
  margin: 2px 0px 10px 0px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.span`
  font-family: var(--font-regular);
  font-size: 14px;
  color: var(--text-primary);
`;

const SubTitle = styled.span`
  font-family: var(--font-regular);
  font-size: 12px;
  color: var(--text-secondary);
`;

interface Props {
  item: CompanyListItemType;
}

const CompaniesTablePrimaryUser = ({ item }: Props) => {
  return (
    <Container>
      <Content>
        <Title>{item?.p_c_name}</Title>
        <SubTitle>{item?.primary_email}</SubTitle>
      </Content>
    </Container>
  );
};

export default CompaniesTablePrimaryUser;
