import React from 'react';

import styled from 'styled-components';

import CMASummaryApartmentTypeRoomComparisonTitle from './CMASummaryApartmentTypeRoomComparisonTitle';
import CMASummaryBorder from './CMASummaryBorder';
import {
  CMA_SUMMARY_APARTMENT_TYPE_DATA_ROW_ROOM_COMPARISON,
  CMA_SUMMARY_APARTMENT_TYPE_GROUP_DATA_ROW_ROOM_COMPARISON,
  CMA_SUMMARY_ROOM_COMPARISON_TYPE_FILTER_ROW_FIELDS,
} from './constants';

const Title = styled.div`
  font-family: var(--font-bold);
  font-size: 14px;
`;

const Subtitle = styled.div`
  font-family: var(--font-medium);
  font-size: 12px;
  color: var(--text-secondary);
`;

type Props = {
  rowType?: string;
  title?: string | string[];
  subtitle?: string | string[];
};

const CMASummaryTableTitle = ({ rowType, title, subtitle }: Props) => {
  switch (rowType) {
    case CMA_SUMMARY_APARTMENT_TYPE_DATA_ROW_ROOM_COMPARISON:
      return (
        <>
          <CMASummaryBorder />
          <CMASummaryApartmentTypeRoomComparisonTitle
            title={title as string[]}
            subtitle={subtitle as string[]}
            hiddenFieldTypes={[
              CMA_SUMMARY_ROOM_COMPARISON_TYPE_FILTER_ROW_FIELDS.RATE,
              CMA_SUMMARY_ROOM_COMPARISON_TYPE_FILTER_ROW_FIELDS.MEDICATION,
            ]}
          />
        </>
      );
    case CMA_SUMMARY_APARTMENT_TYPE_GROUP_DATA_ROW_ROOM_COMPARISON:
      return (
        <>
          <CMASummaryBorder backgroundColor={'var(--background-gray-light)'} />
          <CMASummaryApartmentTypeRoomComparisonTitle
            title={title as string[]}
            subtitle={subtitle as string[]}
            hiddenFieldTypes={[CMA_SUMMARY_ROOM_COMPARISON_TYPE_FILTER_ROW_FIELDS.MEDICATION]}
          />
        </>
      );
    default:
      return (
        <>
          <Title>{title}</Title>
          {subtitle && <Subtitle>{subtitle}</Subtitle>}
        </>
      );
  }
};

export default CMASummaryTableTitle;
