import React, { ReactNode } from 'react';

import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 13px 10px;
  gap: 10px;
  background-image: linear-gradient(to top, #f5f6f7, #fff);
  flex-grow: 1;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const Details = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`;

const Title = styled.div`
  font-family: var(--font-bold);
  font-size: 14px;
  color: var(--text-primary):
`;

const Description = styled.div`
  font-size: 12px;
  color: var(--gray-dark);
`;

interface Props {
  icon: ReactNode;
  title: string;
  subtitle: string;
  handleClick: any;
}
const SidebarSelectorDropdownAction = ({ icon, title, subtitle, handleClick }: Props) => (
  <Container onClick={handleClick}>
    {icon}
    <Details>
      <Title>{title}</Title>
      <Description>{subtitle}</Description>
    </Details>
    <div>
      <img src="/icons/chevron-right.svg" style={{ width: '24px', height: '24px' }} />
    </div>
  </Container>
);

export default SidebarSelectorDropdownAction;
