import React from 'react';

import { AutoComplete as AntDAutoComplete, AutoCompleteProps } from 'antd';
import styled from 'styled-components';

const StyledAutoComplete = styled(AntDAutoComplete)`
  font-family: var(--font-regular);
  flex-grow: 1;

  .ant-select-selector {
    border: 1px solid var(--line-gray);
    border-radius: 0 !important;

    .ant-select-selection-placeholder {
      color: var(--text-secondary);
    }

    .ant-input-outlined {
      background-color: var(--background-gray);

      font-size: 14px;

      .ant-input-prefix {
        font-size: 20px;
        color: var(--gray-dark);
      }
    }
  }
`;

const AutoComplete = (props: AutoCompleteProps) => {
  return <StyledAutoComplete {...props} />;
};

export default AutoComplete;
