import React from 'react';

import styled from 'styled-components';

import { ClientDataType } from 'types/actors';

import UserOnboardingActionsSendInvitation from './UserOnboardingActionsSendInvitation';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

interface Props {
  user: ClientDataType;
}

const UserOnboardingActions = ({ user }: Props) => {
  return (
    <Container>
      <UserOnboardingActionsSendInvitation user={user} />
    </Container>
  );
};

export default UserOnboardingActions;
