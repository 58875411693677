import React from 'react';

import styled from 'styled-components';

import { getProposalIncentiveSavingsValues } from '../proposal/helpers';
import { IncentiveSavingType } from '../types';

const Content = styled.div`
  font-size: 12px;
  display: flex;
  flex-direction: column;
  color: var(--text-secondary);
`;

const Row = styled.div`
  display: flex;
  gap: 6px;
`;

interface Props {
  item: IncentiveSavingType;
}

const SavingsItem = ({ item }: Props) => {
  const { label, value, durationLabel, showCommonSavings, showSavingsAmount } = getProposalIncentiveSavingsValues(item);

  return (
    <Content>
      <Row>
        <span>·</span>
        {showCommonSavings && !showSavingsAmount && (
          <span>
            {value}% off {label} {durationLabel}
          </span>
        )}
        {showCommonSavings && showSavingsAmount && (
          <span>
            ${value} off {label} {durationLabel}
          </span>
        )}
        {!showCommonSavings && showSavingsAmount && (
          <span>
            ${value} {label}
          </span>
        )}
        {!showCommonSavings && !showSavingsAmount && <span>{label}</span>}
      </Row>
    </Content>
  );
};

export default SavingsItem;
