import React from 'react';

import styled from 'styled-components';

const ControllerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 46px;
  height: 46px;
  background: var(--white);
  border-radius: 50%;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background: var(--light-gray);
  }
`;

type Props = {
  icon: React.ReactNode;
  onClick: () => void;
};

const PhotosPreviewCarousalController = ({ icon, onClick }: Props) => {
  return <ControllerContainer onClick={onClick}>{icon}</ControllerContainer>;
};

export default PhotosPreviewCarousalController;
