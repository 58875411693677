import React from 'react';

import { FeesContext } from './FeesContext';
import FeesFormEdit from './FeesFormEdit';
import FeesFormHistory from './FeesFormHistory';
import FormModeSwitch from '../../../common/FormNavigation/FormModeSwitch';

interface Props {
  community_id: any;
}

const FeesForm = ({ community_id }: Props) => (
  <FeesContext.Provider value={{ communityId: parseInt(community_id) }}>
    <FormModeSwitch editChild={<FeesFormEdit />} historyChild={<FeesFormHistory />} />
  </FeesContext.Provider>
);
export default FeesForm;
