import React from 'react';

import { Typography } from 'antd';
import dayjs from 'dayjs';

import { useAppSelector } from 'store/hook';

import { CommunityFeeDateType, FREQUENCY_OPTIONS } from './constants';
import { EditableTable } from '../../../common/Table';
import { DEPENDENCY_TYPE } from '../../../common/Table/constants';

interface Props {
  loading: boolean;
  data: CommunityFeeDateType[];
  onCreate: (record: Partial<CommunityFeeDateType>) => void;
  onUpdate: (record: Partial<CommunityFeeDateType>) => void;
  onDelete: (record: Partial<CommunityFeeDateType>) => void;
}

const FeesFormCommunityFeesTable = ({ data, loading, onCreate, onUpdate, onDelete }: Props) => {
  const living_types = useAppSelector((state) => state.global.livingtypes);

  const living_options = living_types.map((val) => ({
    label: val.type,
    value: +val.id,
  }));

  const uniqueLivingTypeValidator = async (record: CommunityFeeDateType) => {
    const filteredData = data.filter((entry) => entry.key != record?.key);
    const hasLivingTypeEntry = filteredData.some((entry) => entry.livingTypeId === record.livingTypeId);

    if (hasLivingTypeEntry) {
      return Promise.reject('This entry matches an existing record with the same Living Type.');
    }

    return Promise.resolve();
  };

  const futureDatesValidator = async (record: CommunityFeeDateType) => {
    const isFutureDate = record.date > dayjs().endOf('day');
    if (isFutureDate) {
      return Promise.reject('Future dates are not acceptable. Choose a valid date.');
    }
    return Promise.resolve();
  };

  const columns = [
    {
      title: 'Living Type',
      dataIndex: 'livingTypeId',
      inputType: 'select',
      options: living_options,
      width: '200px',
      required: true,
      editable: true,
      validator: uniqueLivingTypeValidator,
    },
    {
      title: 'Community Fee',
      dataIndex: 'communityFee',
      inputType: 'currency',
      dependencies: ['frequency'],
      dependencyType: DEPENDENCY_TYPE.EXCLUDE,
      editable: true,
      width: '130px',
    },
    {
      title: 'Community Fee Equivalent',
      dataIndex: 'frequency',
      inputType: 'select',
      options: FREQUENCY_OPTIONS,
      dependencies: ['communityFee'],
      dependencyType: DEPENDENCY_TYPE.EXCLUDE,
      editable: true,
      ellipsis: true,
      width: '170px',
    },
    {
      title: 'Second Person Fee',
      dataIndex: 'secondPersonFee',
      inputType: 'currency',
      editable: true,
      width: '150px',
    },
    {
      title: 'Pet Fee',
      dataIndex: 'petFee',
      inputType: 'currency',
      editable: true,
      width: '110px',
    },
    {
      title: 'Parking Fee',
      dataIndex: 'parkingFee',
      inputType: 'currency',
      editable: true,
      width: '110px',
    },
    {
      title: 'Respite Fee',
      dataIndex: 'respiteFee',
      inputType: 'currency',
      editable: true,
      width: '110px',
    },
    {
      title: 'Additional Fee',
      dataIndex: 'additionalFee',
      inputType: 'currency',
      editable: true,
      width: '120px',
    },
    {
      title: 'Move In Fee',
      dataIndex: 'moveInFee',
      inputType: 'currency',
      editable: true,
      width: '120px',
    },
    {
      title: 'Entrance Fee',
      dataIndex: 'entranceFee',
      inputType: 'currency',
      editable: true,
      width: '120px',
    },
    {
      title: 'Deposit Fee',
      dataIndex: 'deposit',
      inputType: 'currency',
      editable: true,
      width: '120px',
    },
    {
      title: 'Deposit Equivalent',
      dataIndex: 'depositEquivalentFactor',
      inputType: 'select',
      options: FREQUENCY_OPTIONS,
      dependencies: ['deposit'],
      dependencyType: DEPENDENCY_TYPE.EXCLUDE,
      editable: true,
      ellipsis: true,
      width: '170px',
    },
    {
      title: 'Emergency Pendant Fee',
      dataIndex: 'emergencyPendantFee',
      inputType: 'currency',
      editable: true,
      width: '120px',
    },
    {
      title: 'Data Collected Date',
      dataIndex: 'date',
      inputType: 'date',
      editable: true,
      width: '200px',
      validator: futureDatesValidator,
    },
  ];

  return (
    <div>
      <Typography.Title level={3}>Community Fees</Typography.Title>
      <EditableTable
        tableLayout="fixed"
        loading={loading}
        columns={columns}
        data={data}
        onAdd={onCreate}
        onUpdate={onUpdate}
        onDelete={onDelete}
      />
    </div>
  );
};

export default FeesFormCommunityFeesTable;
