import React, { useState } from 'react';

import classNames from 'classnames';

import CMASummarySelect from './CMASummarySelect';
import CMASummaryViewFilterDropdown from './CMASummaryViewFilterDropdown';
import CMASummaryViewFilterDropdownLabel from './CMASummaryViewFilterDropdownLabel';
import { useCMASummaryContext } from '../CMASummaryContext';
import { CMA_SUMMARY_VIEW_FILTER_DISPLAY_VALUE } from '../constants';

const CMASummaryViewFilter = () => {
  const [open, setOpen] = useState(false);
  const { filters } = useCMASummaryContext();

  return (
    <CMASummarySelect
      open={open}
      onDropdownVisibleChange={(visible) => setOpen(visible)}
      value={filters.roomComparisonType}
      className={classNames({
        active: filters.viewOptions?.valueDisplay !== CMA_SUMMARY_VIEW_FILTER_DISPLAY_VALUE.DONT_DISPLAY,
      })}
      dropdownRender={() => <CMASummaryViewFilterDropdown onClose={() => setOpen(false)} />}
      labelRender={() => <CMASummaryViewFilterDropdownLabel />}
    />
  );
};

export default CMASummaryViewFilter;
