import React from 'react';

import styled from 'styled-components';

import IncentivesProposalModalProposalAgreements from './IncentivesProposalModalProposalAgreements';
import IncentivesProposalModalProposalDisplay from './IncentivesProposalModalProposalDisplay';
import IncentivesProposalModalProposalHeader from './IncentivesProposalModalProposalHeader';
import IncentivesProposalModalProposalLead from './IncentivesProposalModalProposalLead';
import IncentivesProposalModalProposalResident from './IncentivesProposalModalProposalResident';
import IncentivesProposalModalProposalSecondPerson from './IncentivesProposalModalProposalSecondPerson';
import IncentivesProposalModalProposalTime from './IncentivesProposalModalProposalTime';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 30px;
  padding-top: 0;
  overflow-y: auto;
  box-sizing: border-box;
`;

const IncentivesProposalModalProposalData = () => (
  <>
    <IncentivesProposalModalProposalHeader />
    <Container>
      <IncentivesProposalModalProposalLead />
      <IncentivesProposalModalProposalResident />
      <IncentivesProposalModalProposalSecondPerson />
      <IncentivesProposalModalProposalTime />
      <IncentivesProposalModalProposalDisplay />
      <IncentivesProposalModalProposalAgreements />
    </Container>
  </>
);

export default IncentivesProposalModalProposalData;
