import React from 'react';

import styled from 'styled-components';

import CompaniesControls from './CompaniesControls';
import CompaniesTable from './table/CompaniesTable';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 30px;
  height: 100%;

  &.simple {
    width: 100%;
    padding: 0;
  }
`;

const CompaniesData = () => {
  return (
    <Container>
      <CompaniesControls />
      <CompaniesTable />
    </Container>
  );
};

export default CompaniesData;
