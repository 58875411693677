import React from 'react';

import styled from 'styled-components';

import { useIncentivesProposalModalContext } from './IncentivesProposalModalContext';
import Button from '../../lib/Button';
import { useIncentivesContext } from '../IncentivesContext';

interface Props {
  justifyContent?: string;
}

const Container = styled.div<Props>`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => props.justifyContent};
  padding: 20px 30px;
  gap: 10px;
  border-top: solid 1px var(--light-gray);
`;

const RightControls = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const StyledButton = styled(Button)`
  display: inline-flex;
  align-items: center;
  gap: 10px;
`;

const IncentivesProposalModalProposalControls = () => {
  const { readOnly, handleClose } = useIncentivesProposalModalContext();
  const { selectedProposal } = useIncentivesContext();

  return (
    <Container justifyContent={readOnly ? 'space-between' : 'end'}>
      {readOnly && (
        <StyledButton
          style={{ height: 40 }}
          onClick={() => window.open(selectedProposal?.proposal_page_url, '_blank')}
          disabled={!readOnly}
        >
          <img src="/icons/auto-awesome-black.svg" height={24} width={24} />
          View Proposal Page
        </StyledButton>
      )}
      <RightControls>
        {!readOnly && (
          <Button type={'primary'} style={{ width: 120, height: 40 }} htmlType="submit">
            Send
          </Button>
        )}
        <Button style={{ width: 120, height: 40 }} onClick={handleClose} disabled={readOnly ? false : undefined}>
          {readOnly ? 'Close' : 'Cancel'}
        </Button>
      </RightControls>
    </Container>
  );
};

export default IncentivesProposalModalProposalControls;
