import React, { createContext, useContext } from 'react';

import { MysteryShopRequestsBatch } from '../types';

type EditBatchShopRequestsModalContent = {
  isLoading: boolean;
  batchShopRequests: MysteryShopRequestsBatch;
  setLoading: (value: boolean) => void;
  setBatchShopRequests: (item: MysteryShopRequestsBatch) => void;
};

export const EditBatchShopRequestsModalContext = createContext<EditBatchShopRequestsModalContent>({
  isLoading: false,
  batchShopRequests: {},
  setLoading: () => {
    // empty
  },
  setBatchShopRequests: () => {
    // empty
  },
});

export const useEditBatchShopRequestsModalContext = () => useContext(EditBatchShopRequestsModalContext);
