import React from 'react';

import styled from 'styled-components';

const GradientDivider = styled.div`
  width: 100%;
  height: 1px;
  background: linear-gradient(to right, #fafafa 0%, #d7d7d7 50%, #fafafa);
  margin: 15px 0;
`;

export default GradientDivider;
