import React from 'react';

import { CaretRightFilled } from '@ant-design/icons/lib';
import { Tabs } from 'antd';

import General from './General/General';

const CommunityInfoContent = () => {
  const tabs = [
    {
      label: 'General',
      key: 'general',
      children: <General />,
    },
  ];

  return (
    <Tabs
      moreIcon={<CaretRightFilled />}
      tabPosition="top"
      tabBarGutter={30}
      size="small"
      destroyInactiveTabPane
      items={tabs}
    />
  );
};

export default CommunityInfoContent;
