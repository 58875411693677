import React from 'react';

import { StoreValue } from 'antd/es/form/interface';
import styled from 'styled-components';

import Button from 'components/lib/Button';

import { CMA_SUMMARY_ROOM_DATA_FILTER_TYPES } from '../constants';

const AddRowButton = styled(Button)`
  width: 138px;
  height: 30px;
  font-size: 12px;
  margin: 0 0 20px 44px;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  border-radius: 15px;

  span {
    height: 16px;
  }
`;

type Props = {
  addRow: (defaultValue?: StoreValue, insertIndex?: number) => void;
};

const CMASummaryRoomComparisonFilterAddRowButton = ({ addRow }: Props) => (
  <AddRowButton
    onClick={() => {
      const newRowInitialFields = [{ name: 'Rate', fieldType: CMA_SUMMARY_ROOM_DATA_FILTER_TYPES.AVG.id }];
      addRow(newRowInitialFields);
    }}
  >
    <img src="/icons/add-blue.svg" height={16} width={16} alt="add" />
    <span>Add Row</span>
  </AddRowButton>
);

export default CMASummaryRoomComparisonFilterAddRowButton;
