export const MEDIA_BREAK_POINTS = {
  SMALL_MOBILE: 400,
  MOBILE: 992,
  WEB: 1280,
  TABLET: 1540,
};

export const STATUS = {
  IDLE: 'idle',
  LOADING: 'loading',
  LOADED: 'loaded',
  FAILURE: 'failure',
};

export const US_DATE_FORMAT = 'MM/DD/YYYY';
export const GLOBAL_DATE_FORMAT = 'MMM DD, YYYY';

export const INPUT_TYPES = {
  TEXT: 'text',
  TEXTAREA: 'textArea',
  NUMBER: 'number',
  NUMBER_POSITIVE: 'number_positive',
  CURRENCY: 'currency',
  DATE: 'date',
  SELECT: 'select',
  MULTIPLE_SELECT: 'multiple_select',
  ATTACHMENT: 'attachment',
};

export const APARTMENT_TYPE_CATEGORIES = {
  BASIC: 1,
  PLUS: 2,
  DELUXE: 3,
  SPECIFIC: 4,
};

export const USER_LEVELS = {
  COMPANY: 'company',
  MANAGEMENT_COMPANY: 'management_company',
};

export const USER_DATA_VALIDATION_FIELD = 'hash';

export const COMMUNITY_TYPE = {
  COMMUNITY: 'apartment',
  COMPETITOR: 'competitor',
};

export const ACTOR_TYPE = {
  PRIMARY_USER: 'primary_user',
  USER: 'user',
  COMPANY: 'company',
  MANAGEMENT_COMPANY: 'management_company',
  COMMUNITY: 'community',
  COMPETITOR: 'competitor',
};

export const PAGINATION_PAGE_SIZE = {
  SMALL: 10,
  MEDIUM: 300,
  LARGE: 1000,
};

export const USER_GROUPS = {
  INCENTIVES_CORPORATE: 'Incentives Corporate',
  INCENTIVES_SALESPERSON: 'Incentives Salesperson',
  MYSTERY_SHOP_ADMIN: 'Mystery Shop Admin',
  MYSTERY_SHOP_SHOPPER: 'Mystery Shop Shopper',
};

export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\\.,;:\s@"]+\.)+[^<>()[\]\\.,;:\s@"]{2,})$/i;

export const SORT_ORDER = {
  ASCEND: 'ascend',
  DESCEND: 'descend',
};

export const FILE_SOURCE = {
  MEDIA: 1,
  INCENTIVE: 2,
};
