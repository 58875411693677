import React from 'react';

import { Typography } from 'antd';
import styled from 'styled-components';

import { getFormattedDate } from 'helpers/dates';

import { MysteryShopRequestInbound } from '../types';

const Container = styled.div`
  display: flex;
  flex-flow: column;
`;

const StyledSubTitle = styled(Typography.Text)`
  font-size: 12px;
  height: 18px;
  color: var(--text-secondary);
`;

type Props = {
  shopRequest: MysteryShopRequestInbound;
};

const ShopRequestsUpdatedAt = ({ shopRequest }: Props) => {
  if (!shopRequest.updatedAt) {
    return <div />;
  }

  return (
    <Container className="updated-at-column">
      <Typography.Text>{getFormattedDate(shopRequest.updatedAt, 'MMMM D YYYY')}</Typography.Text>
      <StyledSubTitle>
        {shopRequest.updated_by === shopRequest.shopper?.userId ? 'Shopper' : shopRequest.updatedBy}
      </StyledSubTitle>
    </Container>
  );
};

export default ShopRequestsUpdatedAt;
