import React from 'react';

import styled from 'styled-components';

const Title = styled.div`
  font-family: var(--font-regular);
  font-size: 14px;
`;

const Subtitle = styled.div`
  color: var(--text-secondary);
  font-size: 12px;
`;

type Props = { address: string };

const CMASummaryTableDataAddress = ({ address }: Props) => {
  const parts = address?.split(',');
  let street, cityStateZip;

  if (parts?.length) {
    street = parts[0].trim();
    cityStateZip = parts.slice(1).join(',').replace(', USA', '').trim();
  }

  return (
    <div>
      <Title>{street}</Title>
      <Subtitle>{cityStateZip}</Subtitle>
    </div>
  );
};

export default CMASummaryTableDataAddress;
