import React, { useEffect, useState } from 'react';

import styled from 'styled-components';

import ConfirmModal from '../../../common/Modal/ConfirmModal';
import {
  CUSTOM_WIDGET_TYPES,
  GRAPH_WIDGET_TYPES,
  SINGLE_METRIC_WIDGET_TYPES,
  WIDGET_TYPES,
  WIDGET_TYPES_LABELS,
} from '../../../common/Widgets/constants';
import Checkbox from '../../lib/Checkbox';
import Input from '../../lib/Input';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  gap: 10px;
  flex-grow: 1;
  flex-grow: 1;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  justify-content: end;
  padding: 20px 0 0 0;
  flex-grow: 1;
`;

const SelectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 30px;
  justify-content: space-between;
`;

const SelectionCategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const CategoryTitle = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 12px;
  font-family: var(--font-bold);
  color: var(--text-primary);
  border-bottom: 1px solid var(--line-gray);
  padding: 5px 0;
`;

const CategoryTitleLabel = styled.span`
  margin-left: 8px;
`;

const CategoryList = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 5px;
`;

interface Props {
  open: boolean;
  onConfirmCreateNew: any;
  onCancel: any;
}

const GRAPH_TABLE_OPTIONS = [...GRAPH_WIDGET_TYPES, WIDGET_TYPES.SWOT_PERFORMANCE, ...CUSTOM_WIDGET_TYPES];
const SINGLE_METRIC_OPTIONS = SINGLE_METRIC_WIDGET_TYPES;

const ViewSaveFilterModal = ({ open, onConfirmCreateNew, onCancel }: Props) => {
  const [graphTableOptions, setGraphTableOptions] = useState<number[]>([]);
  const [singleMetricOptions, setSingleMetricOptions] = useState<number[]>([]);
  const [name, setName] = useState<string>('');

  useEffect(() => {
    setName('');
    setGraphTableOptions(GRAPH_TABLE_OPTIONS);
    setSingleMetricOptions(SINGLE_METRIC_OPTIONS);
  }, [open]);

  const handleConfirm = () => {
    onConfirmCreateNew({ name, widget_types: [...singleMetricOptions, ...graphTableOptions] });
  };

  const handleNameChange = (e: any) => {
    setName(e.target.value);
  };

  const handleSelectAllGraphsTables = (e: any) => {
    if (e.target.checked) {
      setGraphTableOptions(GRAPH_TABLE_OPTIONS);
    } else {
      setGraphTableOptions([]);
    }
  };

  const handleSelectAllSingleMetrics = (e: any) => {
    if (e.target.checked) {
      setSingleMetricOptions(SINGLE_METRIC_OPTIONS);
    } else {
      setSingleMetricOptions([]);
    }
  };

  const onGraphTableChange = (values: any) => {
    setGraphTableOptions([...values]);
  };

  const onSingleMetricChange = (values: any) => {
    setSingleMetricOptions([...values]);
  };

  return (
    <ConfirmModal
      open={open}
      title={'Save Filter'}
      description={'You will create a new view from the current filter.'}
      confirmLabel={'Create New'}
      onConfirm={handleConfirm}
      onCancel={onCancel}
      disabled={name.length == 0 || (graphTableOptions.length == 0 && singleMetricOptions.length == 0)}
    >
      <Container>
        <InputContainer>
          <span>View Name</span>
          <Input placeholder={'Enter a name (visible to all communities)'} onChange={handleNameChange} />
        </InputContainer>
        <SelectionContainer>
          <SelectionCategoryContainer>
            <CategoryTitle>
              <Checkbox
                onChange={handleSelectAllGraphsTables}
                checked={graphTableOptions.length === GRAPH_TABLE_OPTIONS.length}
              />
              <CategoryTitleLabel>Graphs and Tables</CategoryTitleLabel>
            </CategoryTitle>
            <Checkbox.Group value={graphTableOptions} onChange={onGraphTableChange}>
              <CategoryList>
                {GRAPH_TABLE_OPTIONS.map((type) => (
                  <Checkbox key={type} value={type}>
                    {WIDGET_TYPES_LABELS[type]}
                  </Checkbox>
                ))}
              </CategoryList>
            </Checkbox.Group>
          </SelectionCategoryContainer>
          <SelectionCategoryContainer>
            <CategoryTitle>
              <Checkbox
                onChange={handleSelectAllSingleMetrics}
                checked={singleMetricOptions.length === SINGLE_METRIC_OPTIONS.length}
              />
              <CategoryTitleLabel>Single Metrics</CategoryTitleLabel>
            </CategoryTitle>
            <Checkbox.Group value={singleMetricOptions} onChange={onSingleMetricChange}>
              <CategoryList>
                {SINGLE_METRIC_OPTIONS.map((type) => (
                  <Checkbox key={type} value={type}>
                    {WIDGET_TYPES_LABELS[type]}
                  </Checkbox>
                ))}
              </CategoryList>
            </Checkbox.Group>
          </SelectionCategoryContainer>
        </SelectionContainer>
      </Container>
    </ConfirmModal>
  );
};

export default ViewSaveFilterModal;
