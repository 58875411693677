import React, { useState } from 'react';

import { Checkbox } from 'antd';
import styled from 'styled-components';

import EditShopRequestModal from '../EditShopRequestModal/EditShopRequestModal';
import { useShopRequestsDataContext } from '../ShopRequestsDataContext';
import ShopRequestCommunity from '../TableColumns/ShopRequestCommunity';
import ShopRequestsDueDate from '../TableColumns/ShopRequestsDueDate';
import ShopRequestsShopper from '../TableColumns/ShopRequestsShopper';
import ShopRequestsStatus from '../TableColumns/ShopRequestsStatus';
import ShopRequestsUpdatedAt from '../TableColumns/ShopRequestsUpdatedAt';
import { MysteryShopRequestInbound } from '../types';

const Container = styled.div`
  display: flex;
  flex-flow: row;
  padding-left: 24px;
  background: white;
  border: solid 1px var(--line-gray);
  border-top: 0;
`;

const SubContainer = styled.div`
  display: flex;
  flex-flow: row;
  width: 100%;
  .due-date-column,
  .updated-at-column,
  .status-column,
  .community-column,
  .shopper-column {
    padding: 10px 0 10px 20px;
  }
`;

type Props = {
  shopRequest: MysteryShopRequestInbound;
  refetch: () => void;
};

const ShopRequestGroupTableRow = ({ shopRequest, refetch }: Props) => {
  const [modifyingShopRequest, setModifyingShopRequest] = useState<MysteryShopRequestInbound | null>(null);
  const { selectionMode, selectedItemsIds, setSelectedItemsIds } = useShopRequestsDataContext();

  const onClick = () => {
    setModifyingShopRequest(shopRequest);
  };

  const onSelect = (record: any, selected: any) => {
    const itemKey = record.id;
    let newSelection: any[] = [];

    if (selected) {
      newSelection = [...selectedItemsIds, itemKey];
    } else {
      const index = selectedItemsIds.indexOf(itemKey);
      if (index > -1) {
        newSelection = [...selectedItemsIds];
        newSelection.splice(index, 1);
      }
    }

    setSelectedItemsIds(newSelection);
  };

  const isRowSelected = selectedItemsIds.some((id) => id.toString() === shopRequest.id.toString());

  return (
    <>
      <Container className="shop-request-group-table-row">
        {selectionMode && (
          <Checkbox checked={isRowSelected} onChange={(e: any) => onSelect(shopRequest, e.target.checked)} />
        )}
        <SubContainer onClick={onClick}>
          <ShopRequestCommunity shopRequest={shopRequest} />
          <ShopRequestsShopper shopRequest={shopRequest} />
          <ShopRequestsStatus shopRequest={shopRequest} />
          <ShopRequestsUpdatedAt shopRequest={shopRequest} />
          <ShopRequestsDueDate shopRequest={shopRequest} />
        </SubContainer>
      </Container>
      {modifyingShopRequest && (
        <EditShopRequestModal
          shopRequest={modifyingShopRequest}
          refetchData={refetch}
          onCancel={() => setModifyingShopRequest(null)}
        />
      )}
    </>
  );
};

export default ShopRequestGroupTableRow;
