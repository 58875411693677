import { CommunityIncentiveType } from '../../components/CommunityInfo/types';
import { AccommodationDataType } from '../../pageComponents/AddCommunityCompetitor/accommodation/constants';
import { LivingInfoDataType } from '../../pages/price_healing/Accommodation/constants';
import { CareFeeDateType, CommunityFeeDateType } from '../../pages/price_healing/Fees/constants';
import { OccupancyType } from '../../pages/price_healing/Occupancies/constants';

export type EditableTableDataType =
  | CommunityFeeDateType
  | CareFeeDateType
  | LivingInfoDataType
  | AccommodationDataType
  | OccupancyType
  | CommunityIncentiveType;

export const DEPENDENCY_TYPE = {
  INCLUDE: 'include',
  EXCLUDE: 'exclude',
};

export const UPLOAD_TYPE = {
  FILES: 'files',
  IMAGES: 'images',
};

export const FILE_TYPES = [
  'application/pdf', // PDF
  'application/msword', // Word (doc)
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // Word (docx)
  'application/vnd.ms-excel', // Excel (xls)
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // Excel (xlsx)
  'text/plain',
  'text/csv',
];
export const IMAGE_TYPES = ['image/jpeg', 'image/png', 'image/gif'];

export const UPLOAD_TYPE_ACCEPT = {
  [UPLOAD_TYPE.FILES]: FILE_TYPES,
  [UPLOAD_TYPE.IMAGES]: IMAGE_TYPES,
};
