import React, { useState } from 'react';

import styled from 'styled-components';

import { CompaniesContext } from './CompaniesContext';
import CompaniesData from './CompaniesData';
import CompaniesHeader from './CompaniesHeader';
import { COMPANIES_PAGE_SIZE } from './constants';
import useDataList from '../../utils/hooks/useDataList';

const Container = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`;

const Companies = () => {
  const { items, totalItemsCount, filters, setItems, setPage, setFilters } = useDataList({
    initialState: {
      filters: {
        page: 1,
        pageSize: COMPANIES_PAGE_SIZE,
        search: '',
        status: [],
      },
    },
  });

  const resetFilters = () => {
    setFilters({
      page: 1,
      search: '',
      status: [],
    });
  };

  return (
    <CompaniesContext.Provider
      value={{
        items: items ?? [],
        totalItemsCount,
        filters,
        setItems,
        setPage,
        setFilters,
        resetFilters,
      }}
    >
      <Container>
        <CompaniesHeader />
        <CompaniesData />
      </Container>
    </CompaniesContext.Provider>
  );
};

export default Companies;
