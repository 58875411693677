export const COMPANIES_PAGE_SIZE = 20;

export const COMPANY_STATUS = {
  INACTIVE: 1,
  DEMO: 2,
  IN_TRIAL: 3,
  PAYING_CUSTOMER: 4,
  OUTSIDE_COMPANY: 5,
};

export const COMPANY_STATUS_LABEL = {
  [COMPANY_STATUS.INACTIVE]: 'Inactive',
  [COMPANY_STATUS.DEMO]: 'Demo',
  [COMPANY_STATUS.IN_TRIAL]: 'In Trial',
  [COMPANY_STATUS.PAYING_CUSTOMER]: 'Paying Customer',
  [COMPANY_STATUS.OUTSIDE_COMPANY]: 'Outside Company',
};

export const TABLE_ACTIONS = {
  SORT: 'sort',
  PAGINATE: 'paginate',
  FILTER: 'filter',
};
