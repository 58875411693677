import React from 'react';

import { Table } from 'antd';
import styled from 'styled-components';

const ShopRequestsStyledTable = styled(Table)`
  .ant-table-thead {
    .ant-table-cell {
      text-transform: uppercase;
      font-size: 12px;
      line-height: 12px;
      font-weight: 900;
      letter-spacing: 0.5px;
      padding: 14px 20px 10px;

      ::before {
        display: none;
      }
    }
  }

  thead {
    th {
      z-index: 0;
    }
  }

  th.ant-table-column-sort,
  th.ant-table-column-has-sorters {
    background-color: var(--background-gray-light) !important;
    background: var(--background-gray-light) !important;
  }

  &&&.ant-table-wrapper td.ant-table-column-sort {
    background: white;
  }

  .ant-table-tbody {
    tr {
      cursor: pointer;
    }
  }

  &&&.ant-table-wrapper .ant-table-tbody .ant-table-row.ant-table-row-selected > .ant-table-cell {
    background: #f0f6ff !important;
  }

  .ant-table-row {
    height: 60px;

    .ant-table-cell {
      border-bottom: 0;
      border-bottom: solid 1px var(--line-gray);
      padding: 10px 0 10px 20px;
      &:first-child {
        border-left: solid 1px var(--line-gray);
      }
      &:last-child {
        border-right: solid 1px var(--line-gray);
      }
    }
    &:first-child {
      .ant-table-cell {
        border-top: solid 1px var(--line-gray);
      }
    }
    &:hover {
      .ant-table-cell {
        background: white;
      }

      .note-button-container {
        opacity: 1;
      }
    }
  }

  .ant-table-pagination {
    margin: 22px 0px;
    align-items: baseline;
    font-size: 12px;

    .ant-pagination-total-text {
      position: absolute;
      right: 20px;
    }

    .ant-pagination-item {
      line-height: 24px;
      color: var(--text-primary);
    }

    .ant-pagination-item-active {
      border-radius: 11px;

      box-shadow: 0 2px 6px 0 var(--box-shadow-gray);
      background-color: #fff;
      border: none;
      a {
        color: var(--text-primary) !important;
      }
    }
  }

  &&&&&& {
    tr {
      background-color: #fff;
    }
  }
`;

export default ShopRequestsStyledTable;
