import axios from '../config/axiosPrivate';

export const getUserInfo = async () => {
  const url = `/users/info`;
  const response = await axios.get(url);
  return response.data;
};

export const impersonateUser = async (oayload: { user_id: number | undefined }) => {
  const response = await axios.post(`users/impersonate`, oayload);
  return response.data;
};

export const getClients = async (params: any) => {
  const url = `/users/clients`;
  const response = await axios.get(url, { params });
  return response.data;
};

export const getCompanies = async (params: any) => {
  const url = `/company`;
  const response = await axios.get(url, { params });
  return response.data;
};

export const getCompany = async (clientId: number, companyId: number) => {
  const url = `/company/${clientId}/${companyId}`;
  const response = await axios.get(url);
  return response.data;
};

export const getManagementCompanies = async (companyId: number, params: any) => {
  const url = `/company/${companyId}/management-companies`;
  const response = await axios.get(url, { params });
  return response.data;
};

export const getManagementCompany = async (companyId: number, managementCompanyId: number) => {
  const url = `/company/${companyId}/management-companies/${managementCompanyId}`;
  const response = await axios.get(url);
  return response.data;
};

export const sendInvitation = async (userId: number) => {
  const url = `/users/invitation`;
  const response = await axios.post(url, { user_id: userId });
  return response.data;
};

export const getMysteryShopShoppers = async () => {
  const url = `/users/mystery_shop_request_shoppers`;
  const response = await axios.get(url);
  return response.data;
};

export const checkUser = async (params: any) => {
  const url = `/users/check`;
  const response = await axios.get(url, { params });
  return response.data;
};
