import React from 'react';

import styled from 'styled-components';

import Button from 'components/lib/Button';

import { useEditBatchShopRequestsModalContext } from './EditBatchShopRequestsModalContext';

const Container = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  width: 100%;
`;

type Props = {
  onOk: () => void;
  onCancel: () => void;
};

const EditShopRequestModalFooter = ({ onOk, onCancel }: Props) => {
  const { isLoading, batchShopRequests } = useEditBatchShopRequestsModalContext();
  const { status, shopper_id, due_date } = batchShopRequests;

  const hasRequiredFields = shopper_id || status || due_date;

  const disabled = isLoading || !hasRequiredFields;

  return (
    <Container>
      <div>
        <Button type="primary" onClick={onOk} disabled={disabled} style={{ width: '91px' }}>
          Save
        </Button>
        <Button onClick={onCancel} style={{ width: '91px' }}>
          Close
        </Button>
      </div>
    </Container>
  );
};

export default EditShopRequestModalFooter;
