import React from 'react';

import classNames from 'classnames';

import SelectAdvancedFilter from 'components/lib/SelectAdvancedFilter';

type Props = {
  roomTypes: any;
  options: { label: string; value: number }[];
  setFilters: (value: any) => void;
};

const RoomTypeFilter = ({ roomTypes, options, setFilters }: Props) => {
  const handleSelect = (values: number[]) => {
    setFilters({ roomTypes: values.map((item: any) => item.value) });
  };

  return (
    <SelectAdvancedFilter
      className={classNames({ active: roomTypes && roomTypes.length > 0 })}
      placeholder={'Room Type'}
      options={options}
      defaultValue={roomTypes || []}
      onSelect={handleSelect}
    />
  );
};

export default RoomTypeFilter;
