import React, { useState } from 'react';

import { Form } from 'antd';
import styled from 'styled-components';

import { DEFAULT_INCENTIVE_PROPOSAL_LEAD, DEFAULT_INCENTIVE_PROPOSAL_RATES, PROPOSAL_DURATION } from './constants';
import { useIncentivesProposalModalContext } from './IncentivesProposalModalContext';
import IncentivesProposalModalProposalControls from './IncentivesProposalModalProposalControls';
import IncentivesProposalModalProposalData from './IncentivesProposalModalProposalData';
import { SIGNATURE_DOCUMENT_TYPES } from '../constants';
import { INCENTIVES_FEES_TYPE } from '../create/constants';
import { useIncentivesContext } from '../IncentivesContext';
import useIncentiveInitialFormValues from '../useIncentiveInitialFormValues';
import useSubmitIncentiveProposal from '../useSubmitIncentiveProposal';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const IncentivesProposalModalProposal = () => {
  const { incentive, readOnly } = useIncentivesProposalModalContext();
  const { selectedProposalUnit, selectedProposal } = useIncentivesContext();

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const handleSubmit = useSubmitIncentiveProposal({ setLoading });

  useIncentiveInitialFormValues({ form, incentive, selectedProposalUnit, selectedProposal, readOnly });

  /**
   * Regular from behavior must be overruled in a way that when
   * Pet Fee checkbox is selected, then the Addendum P (Pets) must be checked also.
   * If Pet Fee checkbox is unchecked, then the Addendum P (Pets) must be unchecked also
   */
  const onValuesChange = (changedValues: any, allValues: any) => {
    if (
      !changedValues?.rates ||
      !allValues?.signature_documents_ids ||
      !selectedProposalUnit?.community?.signature_documents?.find(
        (document) => document.type === SIGNATURE_DOCUMENT_TYPES.TYPE_P
      )
    ) {
      return;
    }

    const petAddendumDocument = selectedProposalUnit?.community?.signature_documents?.find(
      (document) => document.type === SIGNATURE_DOCUMENT_TYPES.TYPE_P
    );
    if (!petAddendumDocument) {
      return;
    }
    if (
      allValues.rates.includes(INCENTIVES_FEES_TYPE.PET_FEE) &&
      !allValues.signature_documents_ids.includes(petAddendumDocument.id)
    ) {
      form.setFieldsValue({ signature_documents_ids: [...allValues.signature_documents_ids, petAddendumDocument.id] });
    } else if (
      !allValues.rates.includes(INCENTIVES_FEES_TYPE.PET_FEE) &&
      allValues.signature_documents_ids.includes(petAddendumDocument.id)
    ) {
      form.setFieldsValue({
        signature_documents_ids: allValues.signature_documents_ids.filter(
          (id: number) => id !== petAddendumDocument.id
        ),
      });
    }
  };

  return (
    <Form
      form={form}
      disabled={loading || readOnly}
      initialValues={{
        lead: DEFAULT_INCENTIVE_PROPOSAL_LEAD,
        time: {
          limit: true,
          duration: PROPOSAL_DURATION.SEVEN,
        },
        rates: DEFAULT_INCENTIVE_PROPOSAL_RATES,
        resident: { enabled: false },
        second_person: { enabled: false },
        signature_documents_ids: undefined,
      }}
      colon={false}
      requiredMark={false}
      autoComplete="off"
      onFinish={handleSubmit}
      onValuesChange={onValuesChange}
    >
      <Container>
        {selectedProposalUnit?.incentives && (
          <>
            <IncentivesProposalModalProposalData />
            <IncentivesProposalModalProposalControls />
          </>
        )}
      </Container>
    </Form>
  );
};
export default IncentivesProposalModalProposal;
