import React, { createContext, useContext } from 'react';

import { INCENTIVES_PAGE_SIZE } from './constants';
import { UnitIncentivesFiltersType, UnitType } from './types';

type IncentivesDataContent = {
  createMode: boolean;
  selectionMode: boolean;
  archiveMode: boolean;
  items: UnitType[];
  totalItemsCount: number;
  totalItemsUuids: string[];
  selectedItemsIds: string[];
  archivedItemsIds: string[];
  filters: Partial<UnitIncentivesFiltersType>;
  ordering?: string;
  setSelectionMode: (mode: any) => void;
  setTotalItemsUuids: (ids: string[]) => void;
  setSelectedItemsIds: (ids: string[]) => void;
  setArchivedItemsIds: (ids: string[]) => void;
  setItems: (payload: any) => void;
  setPage: (page: number) => void;
  setFilters: (filters: any) => void;
  setOrdering: (value: string) => void;
  resetFilters: () => void;
  handleSelection: (items: any[]) => void;
};

export const IncentivesDataContext = createContext<IncentivesDataContent>({
  createMode: false,
  selectionMode: false,
  archiveMode: false,
  items: [],
  totalItemsCount: 0,
  totalItemsUuids: [],
  selectedItemsIds: [],
  archivedItemsIds: [],
  filters: {
    page: 1,
    pageSize: INCENTIVES_PAGE_SIZE,
    search: '',
    ordering: undefined,
    communities: [],
    roomTypes: [],
    careTypes: [],
    occupancyRange: [],
    unitAvailability: [],
    unitVacancyDayCount: undefined,
    incentiveStatus: [],
    proposalStatus: '',
  },
  ordering: undefined,
  setSelectionMode: () => {
    // Do nothing
  },
  setTotalItemsUuids: () => {
    // Do nothing
  },
  setSelectedItemsIds: () => {
    // Do nothing
  },
  setArchivedItemsIds: () => {
    // Do nothing
  },
  setItems: () => {
    // Do nothing
  },
  setPage: () => {
    // Do nothing
  },
  setFilters: () => {
    // Do nothing
  },
  setOrdering: () => {
    // Do nothing
  },
  resetFilters: () => {
    // Do nothing
  },
  handleSelection: () => {
    // Do nothing
  },
});

export const useIncentivesDataContext = () => useContext(IncentivesDataContext);
