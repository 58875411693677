import React from 'react';

import { Input as AntDInput } from 'antd';
import styled from 'styled-components';

const StyledInput = styled(AntDInput)`
  background-color: var(--background-gray);
  border: 1px solid var(--line-gray);
  border-radius: 0;
  font-size: 14px;

  .ant-input-prefix {
    font-size: 20px;
    color: var(--gray-dark);
  }
`;

const StyledPasswordInput = styled(AntDInput.Password)`
  background-color: var(--background-gray);
  border: 1px solid var(--line-gray);
  border-radius: 0;
  font-size: 14px;

  .ant-input-prefix {
    font-size: 20px;
    color: var(--gray-dark);
  }
`;

const Input = (props: any) => {
  return <StyledInput {...props}>{props.children}</StyledInput>;
};

Input.Password = (props: any) => {
  return <StyledPasswordInput {...props}>{props.children}</StyledPasswordInput>;
};

export default Input;
