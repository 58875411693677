import React from 'react';

import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  padding-top: 28px;
  padding-bottom: 15px;
  border-bottom: solid 1px var(--light-gray);
`;

const Title = styled.span`
  font-family: var(--font-bold);
  font-size: 20px;
  color: var(--text-primary);
  line-height: 29px;
`;

const Subtitle = styled.span`
  font-family: var(--text-regular);
  font-size: 12px;
  line-height: 18px;
  color: var(--text-secondary);
`;

const IncentivesProposalModalProposalHeader = () => (
  <Container>
    <Title>Send Proposal</Title>
    <Subtitle>The proposal will be sent by email and SMS, as applicable, to the person below.</Subtitle>
  </Container>
);

export default IncentivesProposalModalProposalHeader;
