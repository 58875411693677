import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { Form, FormInstance } from 'antd';

import { getCareFees, getFeesIncentives, getLivingInfo, getOccupancies } from 'apis/CommunityAPI';
import { fetchData } from 'apis/DataAPI';
import { WIDGET_TYPES } from 'common/Widgets/constants';
import { DATA_RANGE_TYPE } from 'common/Widgets/Widget/constants';
import { mapCommunityLivingInfoToData } from 'pages/price_healing/Accommodation/utils';
import { mapCommunityOccupancyResponseToData } from 'pages/price_healing/Occupancies/utils';

import {
  ANTD_TABLE_CONTAINER_CLASS,
  ANTD_TABLE_HEADER_CLASS,
  CMA_SUMMARY_ROOM_COMPARISON_TYPE_FILTER_OPTIONS,
  CMA_SUMMARY_TABLE_COLUMN_WIDTH,
  INITIAL_DISPLAYED_NUMBER_OF_COMMUNITIES,
} from './constants';
import { CMASummaryFilter } from './types';
import { mapCareFeeResponseToData, mapCommunityFeeResponseToData } from '../../pages/price_healing/Fees/utils';

export const useSetNumberOfDisplayedCommunities = (
  tableRef: React.RefObject<HTMLInputElement>,
  setDisplayedNumberOfCommunities: (i: number) => void
) => {
  const [currentNumber, setCurrentNumber] = useState(INITIAL_DISPLAYED_NUMBER_OF_COMMUNITIES);

  const resetNumberOfDisplayedCommunities = () => {
    if (!tableRef?.current) {
      return;
    }

    const tableHeader: HTMLElement | null = tableRef.current.querySelector('.ant-table-header');
    if (!tableHeader) {
      return;
    }

    const numberOfCommunitiesToFit = Math.floor(tableHeader.offsetWidth / CMA_SUMMARY_TABLE_COLUMN_WIDTH);
    if (numberOfCommunitiesToFit !== currentNumber) {
      setDisplayedNumberOfCommunities(numberOfCommunitiesToFit);
      setCurrentNumber(numberOfCommunitiesToFit);
    }
  };

  useEffect(() => {
    resetNumberOfDisplayedCommunities();
  }, [tableRef, currentNumber, resetNumberOfDisplayedCommunities]);

  useEffect(() => {
    window.addEventListener('resize', resetNumberOfDisplayedCommunities);
    return () => {
      window.removeEventListener('resize', resetNumberOfDisplayedCommunities);
    };
  }, []);
};

export const useFetchCMASummaryData = (community: any, competitors: any[], filters: Partial<CMASummaryFilter>) => {
  const { data: ratings, isLoading: ratingsLoading } = useQuery({
    queryKey: ['CMASummary', 'ratings', community?.id, filters.reviewSources],
    queryFn: () => {
      const params: any = {
        review_sources: filters.reviewSources,
      };
      return fetchData(community?.id, WIDGET_TYPES.RATING_GRAPH, DATA_RANGE_TYPE.NOW, params);
    },
    select: (data?: any[]) => {
      // fetchData endpoint doesnt always give ratings in the right order of competitors.
      // therefore we need to sort it according to our community&competitor list.
      return [community, ...competitors].map((community: any) =>
        data?.find((communityRating: any) => communityRating.community_id === community.id)
      );
    },
    enabled: Boolean(community),
    refetchOnWindowFocus: false,
  });

  const { data: feesIncentives, isLoading: feesLoading } = useQuery({
    queryKey: ['CMASummary', 'feesIncentive', community?.id, filters.competitors],
    queryFn: () => {
      const fetchAllFeesIncentives = async (community: any, competitors: any[]) => {
        const requests = [getFeesIncentives(community.id, { active: 1 })];
        competitors.forEach((competitor) => {
          requests.push(getFeesIncentives(competitor.id, { active: 1 }));
        });

        const responses = await Promise.all(requests);
        return responses.map((response) => response?.map((item: any) => mapCommunityFeeResponseToData(item)));
      };

      return fetchAllFeesIncentives(community, competitors);
    },
    enabled: Boolean(community && competitors),
    refetchOnWindowFocus: false,
  });

  const { data: careFees, isLoading: careFeesLoading } = useQuery({
    queryKey: ['CMASummary', 'careFees', community?.id, filters.competitors],
    queryFn: () => {
      const fetchAllCareFees = async (community: any, competitors: any[]) => {
        const requests = [getCareFees(community.id, { active: 1 })];
        competitors.forEach((competitor) => {
          requests.push(getCareFees(competitor.id, { active: 1 }));
        });

        const responses = await Promise.all(requests);
        return responses.map((response) => response?.map((item: any) => mapCareFeeResponseToData(item)));
      };

      return fetchAllCareFees(community, competitors);
    },
    enabled: Boolean(community && competitors),
    refetchOnWindowFocus: false,
  });

  const { data: occupancies, isLoading: occupanciesLoading } = useQuery({
    queryKey: ['CMASummary', 'occupancies', community?.id, filters.competitors],
    queryFn: () => {
      const fetchAllOccupancies = async (community: any, competitors: any[]) => {
        const requests = [getOccupancies(community.id, { active: 1 })];
        competitors.forEach((competitor) => {
          requests.push(getOccupancies(competitor.id, { active: 1 }));
        });
        const responses = await Promise.all(requests);
        return responses.map((response) =>
          response.map((occupancy: any) => mapCommunityOccupancyResponseToData(occupancy))
        );
      };

      return fetchAllOccupancies(community, competitors);
    },
    enabled: Boolean(community && competitors),
    refetchOnWindowFocus: false,
  });

  const { data: livingInfo, isLoading: livingInformationLoading } = useQuery({
    queryKey: ['CMASummary', 'livingInfo', community?.id, filters.competitors],
    queryFn: () => {
      const fetchAllLivingInformation = async (community: any, competitors: any[]) => {
        const requests = [getLivingInfo(community.id, { active: 1 })];
        competitors.forEach((competitor) => {
          requests.push(getLivingInfo(competitor.id, { active: 1 }));
        });
        const responses = await Promise.all(requests);
        return responses.map((response) => response.map((livingInfo: any) => mapCommunityLivingInfoToData(livingInfo)));
      };

      return fetchAllLivingInformation(community, competitors);
    },
    enabled: Boolean(community && competitors),
    refetchOnWindowFocus: false,
  });

  const loading = ratingsLoading || feesLoading || careFeesLoading || livingInformationLoading || occupanciesLoading;

  return { loading, ratings, feesIncentives, careFees, occupancies, livingInfo };
};

export const useFocusNewestRow = (
  roomComparisonType: number,
  roomComparisonRows: any[],
  containerRef: React.RefObject<HTMLDivElement>,
  numberOfRows: number,
  setNumberOfRows: (number: number) => void
) => {
  useEffect(() => {
    if (roomComparisonType === CMA_SUMMARY_ROOM_COMPARISON_TYPE_FILTER_OPTIONS.ADVANCED_COMPARISON) {
      if (!roomComparisonRows?.length) {
        return;
      }

      if (roomComparisonRows.length > numberOfRows) {
        // focus the newly added filter row
        if (containerRef.current) {
          const allInputFields = containerRef?.current?.querySelectorAll('input');
          if (allInputFields.length) {
            allInputFields[allInputFields.length - 1].focus();
          }
        }
      }
      setNumberOfRows(roomComparisonRows.length);
    } else {
      setNumberOfRows(0);
    }
  }, [roomComparisonRows, roomComparisonType]);
};

export const useCMASummaryTableStickyHeader = (competitorsMaxIndex: number) => {
  const [containerWidth, setContainerWidth] = useState('0px');
  const [containerHeight, setContainerHeight] = useState('0px');

  // Initial width calculation
  useEffect(() => {
    const tableHeaderContainer: HTMLElement | null = document.querySelector(`.${ANTD_TABLE_HEADER_CLASS}`);
    if (!tableHeaderContainer) {
      return;
    }
    const placeholder = document.createElement('div');
    placeholder.id = 'fixed-header-placeholder';
    placeholder.style.display = 'none';
    tableHeaderContainer.parentNode?.insertBefore(placeholder, tableHeaderContainer);

    const tableHeaderWidth = `${tableHeaderContainer.offsetWidth}px`;
    const tableHeaderHeight = `${tableHeaderContainer.offsetHeight}px`;
    setContainerWidth(tableHeaderWidth);
    setContainerHeight(tableHeaderHeight);

    return () => {
      if (placeholder && placeholder.parentNode) {
        placeholder.parentNode.removeChild(placeholder);
      }
    };
  }, [competitorsMaxIndex, setContainerWidth, setContainerHeight]);

  // Recalculate header width on resize
  useEffect(() => {
    const resizeTableHeader = () => {
      const tableContainer: HTMLElement | null = document.querySelector(`.${ANTD_TABLE_CONTAINER_CLASS}`);
      const tableHeaderContainer: HTMLElement | null = document.querySelector(`.${ANTD_TABLE_HEADER_CLASS}`);

      if (tableContainer && tableHeaderContainer) {
        const tableBodyWidth = `${tableContainer.offsetWidth}px`;
        const tableHeaderHeight = `${tableHeaderContainer.offsetHeight}px`;
        setContainerWidth(tableBodyWidth);
        setContainerHeight(tableHeaderHeight);

        const scrollTop = window.scrollY || document.documentElement.scrollTop;
        const initialOffsetTop = tableContainer.getBoundingClientRect().top + window.scrollY;
        if (scrollTop > initialOffsetTop) {
          const placeholder: HTMLElement | null = document.querySelector('#fixed-header-placeholder');
          if (!tableHeaderContainer || !placeholder) {
            return;
          }
          placeholder.style.width = tableBodyWidth;
          placeholder.style.display = 'block';

          tableHeaderContainer.style.width = tableBodyWidth;
        }
      }
    };

    window.addEventListener('resize', resizeTableHeader);
    return () => {
      window.removeEventListener('resize', resizeTableHeader);
    };
  }, []);

  // handle moving of fixed header on scroll
  useEffect(() => {
    const handleScroll = () => {
      const tableHeaderContainer: HTMLElement | null = document.querySelector(`.${ANTD_TABLE_HEADER_CLASS}`);
      const placeholder: HTMLElement | null = document.querySelector('#fixed-header-placeholder');
      const tableContainer: HTMLElement | null = document.querySelector(`.${ANTD_TABLE_CONTAINER_CLASS}`);
      if (!tableContainer || !placeholder || !tableHeaderContainer) {
        return;
      }

      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      const initialOffsetTop = tableContainer.getBoundingClientRect().top + window.scrollY;
      if (scrollTop > initialOffsetTop) {
        placeholder.style.width = containerWidth;
        placeholder.style.height = containerHeight;
        placeholder.style.display = 'block';

        tableHeaderContainer.style.position = 'fixed';
        tableHeaderContainer.style.top = '0';
        tableHeaderContainer.style.zIndex = '1';
        tableHeaderContainer.style.width = containerWidth;
      } else {
        tableHeaderContainer.style.position = '';
        tableHeaderContainer.style.top = '';
        tableHeaderContainer.style.width = '';

        placeholder.style.display = 'none';
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [containerWidth, containerWidth]);
};

/**
 * This hook will make sure that:
 * 1. Once the parent `careTypes` checkbox is unchecked, that all of the children checboxes are unchecked as well
 * 2. If `careTypes` checbox is unchecked, and then any of the children are checked -> make the `careTypes` checked
 */
export const useCareTypesCheckboxSelector = ({ form }: { form: FormInstance<any> }) => {
  const careTypes = Form.useWatch('careTypes', form);
  const roomType = Form.useWatch('roomType', form);
  const communityFee = Form.useWatch('communityFee', form);
  const petFee = Form.useWatch('petFee', form);
  const secondPersonFee = Form.useWatch('secondPersonFee', form);
  const careFee = Form.useWatch('careFee', form);

  useEffect(() => {
    if (!careTypes) {
      form.setFieldsValue({
        roomType: false,
        communityFee: false,
        petFee: false,
        secondPersonFee: false,
        careFee: false,
      });
    }
  }, [careTypes]);

  useEffect(() => {
    if (!careTypes && roomType) {
      form.setFieldsValue({ careTypes: true });
    }
  }, [roomType]);
  useEffect(() => {
    if (!careTypes && communityFee) {
      form.setFieldsValue({ careTypes: true });
    }
  }, [communityFee]);
  useEffect(() => {
    if (!careTypes && petFee) {
      form.setFieldsValue({ careTypes: true });
    }
  }, [petFee]);
  useEffect(() => {
    if (!careTypes && secondPersonFee) {
      form.setFieldsValue({ careTypes: true });
    }
  }, [secondPersonFee]);
  useEffect(() => {
    if (!careTypes && careFee) {
      form.setFieldsValue({ careTypes: true });
    }
  }, [careFee]);
};
