import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';

import { Button, message } from 'antd';
import { NoticeType } from 'antd/es/message/interface';
import styled from 'styled-components';

import { checkUser } from 'apis/UserAPI';
import axios from 'config/axiosPublic';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { handleLogin } from 'store/slices/authSlice';

import AuthenticationLayout from './AuthenticationLayout';
import { STEPS, STEPS_ACTIONS } from './constants';
import Input from '../../components/lib/Input';
import { HTTP_STATUS } from '../../config/constants';
import { STATUS } from '../../constants';
import { DEFAULT_LOGIN_ERROR_MESSAGE, INVALID_EMAIL, UNAUTHORIZED_LOGIN_ERROR_MESSAGE } from '../constants';

const { Password } = Input;

const LoginButton = styled(Button)`
  color: #fff;
  font-family: var(--font-bold);
  font-size: 14px;
  margin-top: 30px;
  width: 530px;
  height: 48px;
  border-radius: 4px;
  background-color: var(--positive-green);

  &.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    border-color: var(--positive-green);
    color: var(--positive-green);
  }
`;

const LoginTitle = styled.p`
  font-family: var(--font-bold);
  font-size: 60px;
  color: #222222;
  font-size: 60px;
`;

const LoginSubTitle = styled.p`
  font-family: var(--font-regular);
  font-size: 18px;
  color: #222222;
`;

const Container = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 3px;
  width: 530px;
`;

const Label = styled.div`
  font-family: var(--font-bold);
  font-size: 14px
  color: var(--text-primary);
  display: flex;
  justify-content: space-between;
`;

const Error = styled.p`
  font-family: var(--font-regular);
  font-size: 14px;
  color: #fa7550;
  margin-top: 2px;
`;

const SignIn = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [step, setStep] = useState(STEPS.ENTER_EMAIL);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(STATUS.IDLE);

  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const isFinalStep = step == STEPS.ENTER_PASSWORD;
  const disabled =
    (step == STEPS.ENTER_EMAIL && email == '') ||
    (step == STEPS.ENTER_PASSWORD && password == '') ||
    loading === STATUS.LOADING;

  const mutation = useMutation((formData: any) => {
    return axios.post('/api/jwt/auth/login/', formData);
  });

  const showMessage = (mgs: string, type: NoticeType | undefined) => {
    messageApi.open({
      type: type,
      content: mgs,
    });
  };

  const onFinish = () => {
    if (!disabled) {
      if (step == STEPS.ENTER_EMAIL) {
        checkUserExistance();
        return;
      }

      const formData = new FormData();
      formData.append('username', email.trim());
      formData.append('password', password);
      mutation.mutate(formData);
    }
  };

  const checkUserExistance = async () => {
    try {
      setLoading(STATUS.LOADING);
      await checkUser({ email: email.trim() });
      setLoading(STATUS.LOADED);
      setStep(STEPS.ENTER_PASSWORD);
    } catch (e) {
      setLoading(STATUS.FAILURE);
      setErrorMessage(INVALID_EMAIL);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onFinish();
    }
  };

  useEffect(() => {
    if (mutation?.error) {
      // @ts-ignore
      const response = mutation.error.response;
      const status = response.status;
      const loginFailedMessage =
        status === HTTP_STATUS.UNAUTHORIZED ? UNAUTHORIZED_LOGIN_ERROR_MESSAGE : DEFAULT_LOGIN_ERROR_MESSAGE;
      setErrorMessage(loginFailedMessage);
    }
  }, [mutation.error]);

  useEffect(() => {
    setErrorMessage('');
  }, [email, password]);

  React.useEffect(() => {
    if (mutation.data) {
      const { data } = mutation.data;
      showMessage('Login Successful', 'success');

      const payload = {
        currentUser: data?.userInfo,
        featureFlags: {
          showBetaApplication: data?.userInfo?.feature_flags?.use_beta_application,
        },
        accessToken: data?.accessToken,
        refreshToken: data?.refreshToken,
      };

      setTimeout(() => {
        dispatch(handleLogin(payload));
        navigate('/home');
      }, 1000);
    }
  });

  React.useEffect(() => {
    if (currentUser) {
      navigate('/home');
    }
  }, [currentUser, navigate]);

  return (
    <AuthenticationLayout>
      {contextHolder}
      <div>
        <LoginTitle className="mb-15">{STEPS_ACTIONS[step].TITLE}</LoginTitle>
        <LoginSubTitle>{STEPS_ACTIONS[step].SUBTITLE}</LoginSubTitle>
        <Container>
          <Label>
            <span>{STEPS_ACTIONS[step].LABEL}</span>
            {isFinalStep && (
              <Link
                to="/forgot-password"
                style={{
                  fontFamily: 'AvenirLTPro-Medium',
                  color: '#1f58b0',
                  textDecoration: 'none',
                  float: 'right',
                }}
              >
                Forgot password?
              </Link>
            )}
          </Label>
          {!isFinalStep && (
            <Input
              style={{ width: '530px', height: '48px' }}
              defaultValue={email}
              value={email}
              onChange={(e: any) => setEmail(e.target.value)}
              onKeyDown={handleKeyDown}
              autoFocus
            />
          )}
          {isFinalStep && (
            <Password
              style={{ width: '530px', height: '48px' }}
              defaultValue={password}
              value={password}
              autoFocus
              onChange={(e: any) => setPassword(e.target.value)}
              onKeyDown={handleKeyDown}
            />
          )}
          {errorMessage && <Error>{errorMessage}</Error>}
        </Container>
        <LoginButton loading={loading === STATUS.LOADING} disabled={disabled} onClick={onFinish}>
          {STEPS_ACTIONS[step].BUTTON_TEXT}
        </LoginButton>
      </div>
    </AuthenticationLayout>
  );
};

export default SignIn;
