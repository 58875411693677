import React, { createContext, useContext } from 'react';

type PhotosContent = {
  openPreviewPhotosModal: boolean;
  selectedPhotoIndex: number;
  photos: any;
  selectedCategory: string;
  setSelectedCategory: (category: string) => void;
  setSelectedPhotoIndex: (index: number) => void;
  setOpenPreviewPhotosModal: (show: boolean) => void;
  setPhotos: (data: any[]) => void;
};

export const PhotosContext = createContext<PhotosContent>({
  openPreviewPhotosModal: false,
  selectedPhotoIndex: 0,
  selectedCategory: '',
  photos: [],
  setSelectedCategory: () => {
    //  Do Nothing
  },
  setSelectedPhotoIndex: () => {
    //  Do Nothing
  },
  setOpenPreviewPhotosModal: () => {
    //  Do Nothing
  },
  setPhotos: () => {
    //  Do Nothing
  },
});

export const usePhotosContext = () => useContext(PhotosContext);
