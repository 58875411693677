import React from 'react';

import { Button } from 'antd';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  height: 100%;
  padding: 0px 85px 0px 85px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Graph = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
`;

const TalkButton = styled(Button)`
  width: 132px;
  height: 40px;
  padding: 10px 35px 11px 36px;
  border-radius: 20px;
  border: solid 1px rgba(255, 255, 255, 0.76);
  background: transparent;
  color: var(--white);
  font-family: var(--font-regular);
  font-size: 14px;
  margin-top: 35px;
  font-weight: 500;

  &.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    border: solid 1px rgba(255, 255, 255, 0.76);
    background: transparent;
    color: var(--white);
  }
`;

const Title = styled.p`
  font-family: var(--font-regular);
  font-size: 20px;
  color: #fff;
  text-align: center;
`;

const SubTitle = styled.p`
  font-family: var(--font-regular);
  font-size: 14px;
  line-height: 1.71;
  color: rgba(255, 255, 255, 0.69);
  margin-top: 23px;
  text-align: center;
`;

const AuthenticationSidePanelContent = () => {
  return (
    <Container>
      <Content>
        <Graph>
          <img src="logos/login_group_logo.svg" alt="img" />
        </Graph>

        <Title>Optimize Senior Housing Revenue With Our Pricing Assistant</Title>
        <SubTitle>
          How does your community compare against your competition on what matters to families? <br /> Further can help!
        </SubTitle>

        <TalkButton
          type="default"
          shape="round"
          href="https://www.talkfurther.com/pricing-assistant"
          rel="noopener noreferrer"
          target="_blank"
        >
          Let's Talk
        </TalkButton>
      </Content>
    </Container>
  );
};

export default AuthenticationSidePanelContent;
