import React, { createContext, useContext } from 'react';

type BrochureAndDocsContent = {
  brochureAndDocs: any;
  selectedCategory: string;
  setSelectedCategory: (category: string) => void;
  setBrochureAndDocs: (data: any[]) => void;
};

export const BrochureAndDocsContext = createContext<BrochureAndDocsContent>({
  selectedCategory: '',
  brochureAndDocs: [],
  setSelectedCategory: () => {
    //  Do Nothing
  },
  setBrochureAndDocs: () => {
    //  Do Nothing
  },
});

export const useBrochureAndDocsContext = () => useContext(BrochureAndDocsContext);
