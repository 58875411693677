import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { Checkbox, Table } from 'antd';
import styled from 'styled-components';

import { getShopRequestGroups } from 'apis/ShopRequestAPI';

import ShopRequestGroupsStyledTable from './ShopRequestGroupsStyledTable';
import ShopRequestGroupTableExpandIcon from './ShopRequestGroupTableExpandIcon';
import ShopRequestGroupTableRow from './ShopRequestGroupTableRow';
import ShopRequestSortIcon from './ShopRequestSortIcon';
import { SHOP_REQUEST_COLUMNS, SHOP_REQUEST_VIEW_FILTER_OPTIONS, SHOP_REQUESTS_PAGE_SIZE } from '../constants';
import EditBatchShopRequestsModal from '../EditBatchShopRequestsModal/EditBatchShopRequestsModal';
import ShopRequestGroup from '../GroupTableColumns/ShopRequestGroup';
import ShopRequestGroupStatus from '../GroupTableColumns/ShopRequestGroupStatus';
import { useShopRequestsDataContext } from '../ShopRequestsDataContext';
import { AntdTableExpandIcon, MysteryShopRequestGroup } from '../types';
import { getShopRequestGroupKey, getShopRequestGroupsWithKeys, getShopRequestListingPayload } from '../utils';

const Container = styled.div`
  flex-grow: 1;
  background: var(--background-gray);
  padding: 0 10px;
  border-top: solid 1px var(--line-gray);
`;

const ShopRequestsGroupTable = () => {
  const {
    ordering,
    filters,
    selectionMode,
    selectedItemsIds,
    openEditBatchShopRequestsModel,
    setOpenEditBatchShopRequestsModel,
    setSelectedItemsIds,
    setPage,
    setOrdering,
    setItems,
    setTotalItemsIds,
  } = useShopRequestsDataContext();
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

  const enableShopRequestsGroups = SHOP_REQUEST_VIEW_FILTER_OPTIONS.some((option) => option.value === filters.groupBy);

  const { isLoading, isFetching, data, refetch } = useQuery({
    queryKey: ['shopRequestsGroups', filters, ordering],
    queryFn: () => getShopRequestGroups(getShopRequestListingPayload(filters, ordering)),
    onSuccess: (data) => {
      setItems(data);
      setTotalItemsIds(data.ids);

      // Handle new selection once filters are updated
      // New selection is filtered out previous selection
      const selectionIntersection = selectedItemsIds.filter((id) => data.ids.includes(id));
      setSelectedItemsIds(selectionIntersection);
    },
    refetchOnWindowFocus: false,
    enabled: enableShopRequestsGroups,
  });

  const onSelectAll = (selected: any, selectedRows: any, changeRows: any) => {
    let newSelection: any[] = [];

    if (selected) {
      newSelection = selectedRows.flatMap((row: any) => row.shop_requests.map((shopRequest: any) => shopRequest.id));
      setSelectedItemsIds(newSelection);
    } else {
      setSelectedItemsIds(newSelection);
    }

    setSelectedItemsIds(newSelection);
  };

  useEffect(() => {
    const allRowKeyIds = data?.results?.map((group: MysteryShopRequestGroup) => getShopRequestGroupKey(group)) || [];
    setExpandedRowKeys(allRowKeyIds);
  }, [data]);

  const columns: any[] = [
    Table.SELECTION_COLUMN,
    Table.EXPAND_COLUMN,
    {
      id: SHOP_REQUEST_COLUMNS.requestGroup.id,
      key: SHOP_REQUEST_COLUMNS.requestGroup.id,
      title: SHOP_REQUEST_COLUMNS.requestGroup.label,
      width: '27%',
      sorter: true,
      sortIcon: ({ sortOrder }: any) => <ShopRequestSortIcon sortOrder={sortOrder} />,
      render: (value: any, record: any) => <ShopRequestGroup shopRequestGroup={record} />,
    },
    {
      id: SHOP_REQUEST_COLUMNS.shopper.id,
      key: SHOP_REQUEST_COLUMNS.shopper.id,
      title: SHOP_REQUEST_COLUMNS.shopper.label,
      width: '23%',
      sorter: true,
      sortIcon: ({ sortOrder }: any) => <ShopRequestSortIcon sortOrder={sortOrder} />,
    },
    {
      id: SHOP_REQUEST_COLUMNS.status.id,
      key: SHOP_REQUEST_COLUMNS.status.id,
      title: SHOP_REQUEST_COLUMNS.status.label,
      width: '20%',
      sorter: true,
      sortIcon: ({ sortOrder }: any) => <ShopRequestSortIcon sortOrder={sortOrder} />,
      render: (value: any, record: any) => <ShopRequestGroupStatus shopRequestGroup={record} />,
    },
    {
      id: SHOP_REQUEST_COLUMNS.updatedAt.id,
      key: SHOP_REQUEST_COLUMNS.updatedAt.id,
      title: SHOP_REQUEST_COLUMNS.updatedAt.label,
      width: '15%',
      sorter: true,
      sortIcon: ({ sortOrder }: any) => <ShopRequestSortIcon sortOrder={sortOrder} />,
    },
    {
      id: SHOP_REQUEST_COLUMNS.dueDate.id,
      key: SHOP_REQUEST_COLUMNS.dueDate.id,
      title: SHOP_REQUEST_COLUMNS.dueDate.label,
      width: '15%',
      sorter: true,
      defaultSortOrder: 'ascend' as const,
      sortIcon: ({ sortOrder }: any) => <ShopRequestSortIcon sortOrder={sortOrder} />,
    },
  ];

  return (
    <Container>
      <ShopRequestGroupsStyledTable
        columns={columns}
        dataSource={getShopRequestGroupsWithKeys(data?.results)}
        loading={isLoading || isFetching}
        pagination={{
          size: 'small',
          position: ['bottomLeft'],
          showSizeChanger: false,
          current: filters.page,
          pageSize: SHOP_REQUESTS_PAGE_SIZE,
          total: data?.count,
          onChange: (pageNumber: number) => {
            setPage(pageNumber);
          },
          showTotal: (total, range) => {
            const displayedItems = range[1] - range[0] + 1;
            return (
              <span>
                Showing {displayedItems} of {total} records
              </span>
            );
          },
        }}
        onChange={(pagination: any, filters: any, sorter: any) => {
          if (sorter) {
            if (!sorter.column) {
              setOrdering('');
            } else {
              const orderBy = sorter.order === 'descend' ? `-${sorter.column.id}` : sorter.column.id;
              if (orderBy !== ordering) {
                setOrdering(orderBy);
              }
            }
          }
          if (pagination) {
            setPage(pagination.current);
          }
        }}
        rowKey="key"
        rowSelection={
          selectionMode
            ? {
                columnWidth: 0, // Hide the selection column in rows
                renderCell: () => null, // Hide checkboxes in each row
                preserveSelectedRowKeys: true,
                onSelectAll,
              }
            : undefined
        }
        expandable={{
          expandedRowRender: (shopRequestGroup: any) => (
            <div key={shopRequestGroup.id}>
              {shopRequestGroup.shopRequests.map((request: any) => (
                <ShopRequestGroupTableRow key={request.id} shopRequest={request} refetch={refetch} />
              ))}
            </div>
          ),
          expandRowByClick: true,
          expandIcon: ({ expanded, onExpand, record }: AntdTableExpandIcon) => (
            <ShopRequestGroupTableExpandIcon expanded={expanded} onExpand={onExpand} record={record} />
          ),
          expandedRowKeys: expandedRowKeys,
          onExpand: (expanded, record) => {
            setExpandedRowKeys((prevKeys) =>
              expanded ? [...prevKeys, record.key] : prevKeys.filter((key) => key !== record.key)
            );
          },
        }}
      />
      {openEditBatchShopRequestsModel && (
        <EditBatchShopRequestsModal refetchData={refetch} onCancel={() => setOpenEditBatchShopRequestsModel(false)} />
      )}
    </Container>
  );
};

export default ShopRequestsGroupTable;
