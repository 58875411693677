import React from 'react';

import styled from 'styled-components';

import { IncentiveListItemType } from './types';
import LabelsList from '../../../../common/List/LabelsList';
import SavingsList from '../../common/SavingsList';
import { INCENTIVES_TABS } from '../../constants';
import { useIncentivesContext } from '../../IncentivesContext';

const Container = styled.span`
  display: flex;
  margin: 2px 0px 10px 0px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const Icon = styled.span`
  padding-top: 4px;
  padding-right: 20px;
`;

const Title = styled.span`
  font-family: var(--font-bold);
  font-size: 14px;
  color: var(--text-primary);
`;

const Savings = styled.div`
  font-family: var(--font-bold);
  font-size: 12px;
  color: var(--text-primary);
`;

const Info = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6px;
  font-size: 12px;
`;

const Description = styled.span`
  font-family: var(--font-regular);
  font-size: 12px;
  text-align: justify;
  line-height: normal;
  color: var(--text-secondary);
`;

const UnitCount = styled.span`
  color: var(--link-blue);
  cursor: pointer;
`;

interface Props {
  item: IncentiveListItemType;
}

const IncentivesTableIncentivesIncentive = ({ item }: Props) => {
  const { setCurrentTab, setSearch } = useIncentivesContext();

  const unitsCount = item.units_count;
  const unitsCountLabel = `${unitsCount} ${unitsCount > 1 ? 'units' : 'unit'}`;

  const handleViewUnits = () => {
    setSearch(item.name);
    setCurrentTab(INCENTIVES_TABS.UNIT);
  };

  return (
    <Container>
      <Icon>
        <img
          src={'/icons/price-tag-green.svg'}
          alt="Incentive"
          style={{
            minWidth: 24,
            minHeight: 24,
          }}
        />
      </Icon>

      <Content>
        <Title>{item?.name}</Title>
        <Savings>
          <SavingsList savings={item?.savings ?? []} />
        </Savings>
        <Info>
          <LabelsList options={item?.care_types ?? []} labelPlural={'care types'} />
          <span>·</span>
          <LabelsList options={item?.room_types ?? []} labelPlural={'room types'} />
          <span>·</span>
          <UnitCount onClick={handleViewUnits}>{unitsCountLabel}</UnitCount>
        </Info>
        <Description>{item?.description}</Description>
      </Content>
    </Container>
  );
};

export default IncentivesTableIncentivesIncentive;
