import React, { useState } from 'react';
import { useQueryClient } from 'react-query';

import styled from 'styled-components';

import { updateShopRequest } from 'apis/ShopRequestAPI';
import { isMysteryShopShopper } from 'helpers/permissions';
import { useAppSelector } from 'store/hook';

import { SHOP_REQUEST_STATUS, SHOP_REQUEST_STATUS_LABELS } from '../constants';
import EditNoteButton from '../NoteButton/EditNoteButton';
import NotePopover from '../NoteButton/NotePopover';
import NoteModal from '../NoteModal/NoteModal';
import { useShopRequestsDataContext } from '../ShopRequestsDataContext';
import ShopRequestStatusSelector from '../ShopRequestStatusSelector';
import { MysteryShopRequestInbound, MysteryShopRequestOutbound } from '../types';

const Container = styled.div<{ editNoteButtonOpacity: number }>`
  display: flex;
  flex-flow: row;
  align-items: center;
  .note-button-container {
    margin-left: 10px;
    opacity: ${(props) => props.editNoteButtonOpacity};
  }
`;

type Props = {
  shopRequest: MysteryShopRequestInbound;
};

const ShopRequestsStatus = ({ shopRequest }: Props) => {
  const queryClient = useQueryClient();
  const { filters, ordering } = useShopRequestsDataContext();
  const [isLoading, setLoading] = useState(false);
  const [statusModalOpen, setStatusModalOpen] = useState(false);
  const [editNoteModalOpen, setEditNoteModalOpen] = useState(false);
  const [newStatus, setNewStatus] = useState<number | null>(null);
  const { currentUser } = useAppSelector((state) => state.auth);
  const isShopRequestSalesperson = currentUser && isMysteryShopShopper(currentUser);

  const openUpdateStatusModal = async (status: number) => {
    setNewStatus(status);
    setStatusModalOpen(true);
  };

  const updateNote = async (note: string) => {
    setLoading(true);
    const payload: Partial<MysteryShopRequestOutbound> = { shopper_note: note };
    if (newStatus) {
      payload.status = newStatus;
    }
    await updateShopRequest(shopRequest.id, payload).then(() => {
      queryClient.invalidateQueries(['shopRequests', filters, ordering]);
      queryClient.invalidateQueries(['shopRequestsGroups', filters, ordering]);
      setLoading(false);
      setEditNoteModalOpen(false);
    });
  };

  const onCloseModal = () => {
    setEditNoteModalOpen(false);
    setStatusModalOpen(false);
    setNewStatus(null);
  };

  if (!shopRequest.status) {
    return <div />;
  }

  return (
    <Container
      editNoteButtonOpacity={shopRequest.shopperNote ? 1 : 0}
      onClick={(event: React.MouseEvent) => event.stopPropagation()}
      className="status-column"
    >
      <ShopRequestStatusSelector
        shopRequest={shopRequest}
        disabled={!!isShopRequestSalesperson && currentUser?.PAUser_Id !== shopRequest?.shopper?.userId}
        onChange={openUpdateStatusModal}
      />
      {shopRequest.shopperNote || shopRequest.adminNote ? (
        <NotePopover shopRequest={shopRequest} />
      ) : (
        <EditNoteButton
          isModalOpen={editNoteModalOpen}
          note={shopRequest.shopperNote}
          okButtonDisabled={isLoading}
          setModalOpen={setEditNoteModalOpen}
          onCloseModal={onCloseModal}
          modifyNote={updateNote}
        />
      )}
      {statusModalOpen && (
        <NoteModal
          title={`Change to ${SHOP_REQUEST_STATUS.find((item) => item.id === newStatus)?.label}?`}
          note={shopRequest.shopperNote}
          isModalOpen={statusModalOpen}
          closeModal={onCloseModal}
          modifyNote={updateNote}
          disableEmptyNote={newStatus === SHOP_REQUEST_STATUS_LABELS.BLOCKED}
        />
      )}
    </Container>
  );
};

export default ShopRequestsStatus;
