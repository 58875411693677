import React from 'react';
import { Link } from 'react-router-dom';

import styled from 'styled-components';

import { IncentiveListItemType } from './types';

const Container = styled.div`
  display: flex;
  min-height: 40px;
  flex-direction: column;
`;

const StyledLink = styled(Link)`
  font-family: var(--font-regular);
  font-size: 12px;
  color: var(--text-secondary);

  &:hover {
    color: var(--link-blue);
  }
`;

interface Props {
  item: IncentiveListItemType;
}

const IncentivesTableIncentivesCommunities = ({ item }: Props) => {
  const communities = (item?.communities_list ?? []).reduce((acc: any, obj: any) => {
    // Extract the key and value from each object
    const entries = Object.entries(obj);
    const key = entries[0][0];
    const value = entries[0][1];

    // Push the tuple (key, value) into the accumulator
    acc.push([key, value]);
    return acc;
  }, []);

  return (
    <Container>
      {communities
        .sort((a: any, b: any) => {
          return a[1].localeCompare(b[1]);
        })
        .map(([id, name]: [number, string]) => (
          <StyledLink to={`/community/${id}`} target="_blank" key={name}>
            {name}
          </StyledLink>
        ))}
    </Container>
  );
};

export default IncentivesTableIncentivesCommunities;
