import React, { useContext, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';

import { CaretRightFilled } from '@ant-design/icons/lib';
import { Tabs } from 'antd';

import CommunityIncentives from './CommunityIncentives';
import { CommunityInfoContext } from './CommunityInfoContext';
import Loader from './CommunityInfoLoader';
import axios from '../../config/axiosPrivate';
import AccommodationForm from '../../pages/price_healing/Accommodation/AccommodationForm';
import AdditionalLinks from '../../pages/price_healing/AdditionalLinks';
import FeesForm from '../../pages/price_healing/Fees/FeesForm';
import Occupancies from '../../pages/price_healing/Occupancies/Occupancies';
import ShopNotes from '../../pages/price_healing/ShopNotes';
import classes from '../../pages/price_healing/styles.module.scss';
import BasicInfoForm from '../../pages/price_healing/sub_competitor/BasicInfoForm';
import ImageBrochure from '../../pages/price_healing/sub_competitor/ImageBrochure';
import CommunityDetails from '../../pages/PriceHealing/CommunityDetails';
import { useRootContext } from '../layout/RootContext';

const CommunityInfoCompetitorContent = () => {
  const { communityId, competitorId } = useParams();
  const { refreshCommunities } = useRootContext();
  const { activeTabKey, setActiveTabKey, communityInfo, parentCommunityId } = useContext(CommunityInfoContext);

  const [selectedItem, setSelectedItem] = React.useState<any>(null);

  const [competitorInfo, setCompetitorInfo] = React.useState<any>(null);
  const [competitorLoading, setShowCompetitorLoading] = useState(true);

  useQuery({
    queryKey: ['competitorContent', parentCommunityId, competitorId],
    queryFn: () => axios.get(`/communities/Get_CompetitorInfo/${parentCommunityId}/`),
    onSuccess: (response) => {
      const data = response.data?.payload;

      const formatted_data = data.Competitor_Info?.map((cm: any) => ({
        name: cm?.ApartmentName,
        streetNumber: '',
        route: '',
        city: cm?.city,
        state: cm?.state,
        zip_code: cm?.zip_code,
        country: cm?.county,
        formatted_address: cm?.ApartmentAddress,
        place_id: cm?.google_map_location,
        lat: cm?.Latitude,
        lng: cm?.Longitude,
        op: '',
        id: cm.Id,
      }));

      if (formatted_data.length > 0) {
        const competitor_id = competitorId ? competitorId : communityId;
        const selectedCompetitorCommunity = formatted_data.find(
          (competitor: any) => competitor.id === parseInt(competitor_id || '')
        );
        setSelectedItem(selectedCompetitorCommunity);
        const community_data = data?.Competitor_Info?.filter(
          (item: any) => item.Id == selectedCompetitorCommunity.id
        )[0];
        setCompetitorInfo(community_data);
      }
      setShowCompetitorLoading(false);
    },
    refetchOnWindowFocus: false,
    enabled: !!parentCommunityId,
  });

  if (competitorLoading) {
    return <Loader />;
  }

  const setData = (data: any) => {
    refreshCommunities(); // used to reload redux state which hold the header competitor name
    setCompetitorInfo(data);
  };

  return (
    <div>
      {competitorInfo ? (
        <Tabs
          activeKey={activeTabKey}
          moreIcon={<CaretRightFilled />}
          tabPosition="top"
          className={classes.sub_competitor_tab}
          destroyInactiveTabPane
          onChange={(key: any) => setActiveTabKey(key)}
          items={[
            {
              label: 'Basic Info',
              key: '1',
              children: (
                <BasicInfoForm
                  setData={setData}
                  initialData={competitorInfo}
                  community_id={selectedItem?.id || 0}
                  apartment_id={parentCommunityId}
                />
              ),
              style: {
                padding: 0,
              },
            },
            {
              label: 'Community Details',
              key: '2',
              children: <CommunityDetails community_id={selectedItem?.id || 0} />,
              disabled: communityInfo === null,
            },
            {
              label: 'Accommodation',
              key: '3',
              children: <AccommodationForm community_id={competitorInfo?.Id} />,
              style: {
                padding: 0,
              },
            },
            {
              label: 'Fees & Care',
              key: '4',
              children: <FeesForm community_id={competitorInfo?.Id} />,
              style: {
                padding: 0,
              },
            },
            {
              label: 'Incentives',
              key: '5',
              children: <CommunityIncentives communityId={competitorInfo?.Id} />,
              style: {
                padding: 0,
              },
            },
            {
              label: 'Images/Brochures',
              key: '6',
              children: <ImageBrochure community_id={competitorInfo?.Id} />,
              style: {
                padding: 0,
              },
            },
            {
              label: 'Additional Links',
              key: '7',
              children: <AdditionalLinks community_id={competitorInfo?.Id} />,
              style: {
                padding: 0,
              },
            },
            {
              label: 'Occupancies',
              key: '8',
              children: <Occupancies communityId={competitorInfo?.Id} />,
              style: {
                padding: 0,
              },

              disabled: communityInfo === null,
            },
            {
              label: 'Shop Notes',
              key: '9',
              children: <ShopNotes community_id={parseInt(competitorInfo?.Id)} />,
              style: {
                padding: 0,
              },
              disabled: communityInfo === null,
            },
          ]}
        />
      ) : (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100px',
            fontSize: 22,
          }}
        >
          <p>No data found</p>
        </div>
      )}
    </div>
  );
};

export default CommunityInfoCompetitorContent;
