import React, { useState } from 'react';

import { uuid } from 'short-uuid';
import styled from 'styled-components';

import { isIncentivesCorporate } from 'helpers/permissions';
import { useAppSelector } from 'store/hook';

import DownCaretIcon from '../../../../common/Icons/DownCaretIcon';
import Button from '../../../lib/Button';
import Dropdown from '../../../lib/Dropdown';
import IncentiveArchiveModal from '../../archive/IncentiveArchiveModal';
import { INCENTIVES_TABS } from '../../constants';
import { useIncentivesContext } from '../../IncentivesContext';
import { IncentiveType } from '../../types';

interface Props {
  item: IncentiveType;
}

const Wrapper = styled.div`
  visibility: hidden;
`;

const IncentivesTableIncentivesActions = ({ item }: Props) => {
  const { currentUser } = useAppSelector((state) => state.auth);
  const { setKey, setCurrentTab, setSearch } = useIncentivesContext();

  const [openIncentiveArchiveModal, setOpenIncentiveArchiveModal] = useState(false);

  const handleArchive = () => {
    setOpenIncentiveArchiveModal(false);
    setKey(uuid());
  };

  const handleViewUnits = () => {
    setSearch(item.name);
    setCurrentTab(INCENTIVES_TABS.UNIT);
  };

  return (
    <Wrapper className={'hover-visible'}>
      <IncentiveArchiveModal open={openIncentiveArchiveModal} incentive={item} onClose={handleArchive} />
      <Dropdown
        menu={{
          items: [
            {
              label: 'View Units',
              key: 'viewUnits',
              onClick: () => handleViewUnits(),
            },
            isIncentivesCorporate(currentUser)
              ? {
                  label: 'Archive',
                  key: 'archive',
                  onClick: () => setOpenIncentiveArchiveModal(true),
                }
              : null,
          ],
        }}
      >
        <Button style={{ width: 100 }}>
          <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
            <span>Actions</span>
            <DownCaretIcon />
          </div>
        </Button>
      </Dropdown>
    </Wrapper>
  );
};

export default IncentivesTableIncentivesActions;
