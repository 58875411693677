import React from 'react';

import styled from 'styled-components';

import { PROPOSAL_SIGNATURE_SUBMISSION_STATUS } from 'components/Incentives/constants';
import { ProposalType } from 'components/Incentives/types';
import { getDaysLeft } from 'helpers/dates';

interface Props {
  record: ProposalType;
}

interface DaysLeftProps {
  daysLeft: number;
}

const Container = styled.div`
  display: flex;
  flex-flow: column;
`;
const DaysLeft = styled.div<DaysLeftProps>`
  color: ${(props) => (props.daysLeft <= 3 ? 'var(--negative-orange)' : 'var(--text-secondary)')};
  font-size: 12px;
`;
const Subtitle = styled.div`
  color: var(--text-secondary);
  font-size: 12px;
`;

const IncentivesTableProposalsStatus = ({ record }: Props) => {
  let title, subtitle, daysLeft;
  switch (record.status) {
    case PROPOSAL_SIGNATURE_SUBMISSION_STATUS.SENT:
      title = 'Sent';
      daysLeft = getDaysLeft(record?.expires_at);
      break;
    case PROPOSAL_SIGNATURE_SUBMISSION_STATUS.OPENED_BY_RESIDENT:
      title = 'Opened';
      subtitle = 'by recipient';
      daysLeft = getDaysLeft(record?.expires_at);
      break;
    case PROPOSAL_SIGNATURE_SUBMISSION_STATUS.SIGNED_BY_RESIDENT:
      title = 'Signed';
      subtitle = 'by recipient';
      break;
    case PROPOSAL_SIGNATURE_SUBMISSION_STATUS.OPENED_BY_COMMUNITY:
      title = 'Opened';
      subtitle = 'by community';
      break;
    case PROPOSAL_SIGNATURE_SUBMISSION_STATUS.SIGNED_BY_COMMUNITY:
      title = 'Signed';
      subtitle = 'by community';
      break;
    default:
      title = 'Sent';
  }

  return (
    <Container>
      <span>{title}</span>
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
      {daysLeft && <DaysLeft daysLeft={parseInt(daysLeft)}>{daysLeft}</DaysLeft>}
    </Container>
  );
};

export default IncentivesTableProposalsStatus;
