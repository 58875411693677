import React, { createContext, useContext } from 'react';

type OccupanciesContent = {
  communityId: number | null;
};

export const OccupanciesContext = createContext<OccupanciesContent>({
  communityId: null,
});
export const useOccupanciesContext = () => useContext(OccupanciesContext);
