import React, { useEffect } from 'react';

import { Form } from 'antd';
import styled from 'styled-components';

import GradientDivider from 'components/lib/GradientDivider';

import { CMASummaryFilterContext } from './CMASummaryFilterContext';
import CMASummaryFilterDropdownHeader from './CMASummaryFilterDropdownHeader';
import CMASummaryViewFilterCategories from './CMASummaryViewFilterCategories';
import CMASummaryViewFilterValuesSelector from './CMASummaryViewFilterValuesSelector';
import { useCMASummaryContext } from '../CMASummaryContext';
import { CMA_SUMMARY_VIEW_FILTER_DISPLAY_VALUE } from '../constants';
import { useCareTypesCheckboxSelector } from '../customHooks';

const Container = styled.div`
  display: flex;
  flex-direction: column;

  .ant-form-item .ant-form-item-control-input {
    min-height: 0;
  }
`;

const CMASummaryFilterBody = styled.div`
  padding: 20px;
`;

const INITIAL_VIEW_FILTER_VALUE = {
  contactInformation: true,
  reviews: true,
  careTypes: true,
  roomType: true,
  communityFee: true,
  petFee: true,
  secondPersonFee: true,
  careFee: true,
  incentives: true,
  valueDisplay: CMA_SUMMARY_VIEW_FILTER_DISPLAY_VALUE.DONT_DISPLAY,
};

type Props = {
  onClose: () => void;
};

const CMASummaryViewFilterDropdown = ({ onClose }: Props) => {
  const [form] = Form.useForm();
  const { loading, filters, setFilters } = useCMASummaryContext();
  useCareTypesCheckboxSelector({ form });

  useEffect(() => {
    if (filters.viewOptions) {
      form.setFieldsValue({
        contactInformation: filters.viewOptions.contactInformation,
        reviews: filters.viewOptions.reviews,
        careTypes: filters.viewOptions.careTypes,
        roomType: filters.viewOptions.roomType,
        communityFee: filters.viewOptions.communityFee,
        petFee: filters.viewOptions.petFee,
        secondPersonFee: filters.viewOptions.secondPersonFee,
        careFee: filters.viewOptions.careFee,
        incentives: filters.viewOptions.incentives,
        valueDisplay: filters.viewOptions.valueDisplay,
      });
    }
  }, [filters]);

  const handleSubmit = (values: any) => {
    setFilters({
      viewOptions: {
        contactInformation: values.contactInformation,
        reviews: values.reviews,
        careTypes: values.careTypes,
        roomType: values.roomType,
        communityFee: values.communityFee,
        petFee: values.petFee,
        secondPersonFee: values.secondPersonFee,
        careFee: values.careFee,
        incentives: values.incentives,
        valueDisplay: values.valueDisplay,
      },
    });
    onClose();
  };

  return (
    <Form form={form} disabled={loading} initialValues={INITIAL_VIEW_FILTER_VALUE} onFinish={handleSubmit}>
      <CMASummaryFilterContext.Provider value={{ form }}>
        <Container>
          <CMASummaryFilterDropdownHeader onReset={() => form.setFieldsValue(INITIAL_VIEW_FILTER_VALUE)} />
          <CMASummaryFilterBody>
            <CMASummaryViewFilterCategories />
            <GradientDivider />
            <CMASummaryViewFilterValuesSelector />
          </CMASummaryFilterBody>
        </Container>
      </CMASummaryFilterContext.Provider>
    </Form>
  );
};

export default CMASummaryViewFilterDropdown;
