import React from 'react';

import { DownOutlined } from '@ant-design/icons/lib';
import styled from 'styled-components';

import DownCaretIcon from '../../../../common/Icons/DownCaretIcon';

const Selector = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  padding: 11px;
  border-radius: 4px;
  box-shadow: 0 3px 4px 0 var(--sidebar-selector-shadow);
  background: #fff;
  gap: 10px;
  user-select: none;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SelectorInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  color: var(--text-primary);
  font-family: var(--font-regular);
`;

const SelectorInfoAdministration = styled.div`
  font-size: 10px;
`;

const SelectorInfoLabel = styled.div`
  font-size: 14px;
`;

const SidebarSelectorButtonAdmin = () => {
  return (
    <Selector>
      <IconContainer>
        <img src="/icons/lock-open.svg" style={{ width: '24px', height: '24px' }} />
      </IconContainer>
      <SelectorInfo>
        <SelectorInfoAdministration>Administration</SelectorInfoAdministration>
        <SelectorInfoLabel>Select a client</SelectorInfoLabel>
      </SelectorInfo>
      <DownOutlined style={{ padding: 4, color: 'black', fontSize: 10 }} />
    </Selector>
  );
};

export default SidebarSelectorButtonAdmin;
