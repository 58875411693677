import React from 'react';

import styled from 'styled-components';

const StyledLink = styled.a`
  overflow: hidden;
  max-width: 205px;
  word-break: break-word;
  display: inline-block;
  font-size: 12px;
  vertical-align: middle;
  color: var(--link-blue);
  text-wrap: auto;
  word-break: break-all;
`;

type Props = { website: string };

const CMASummaryTableDataWebsite = ({ website }: Props) => {
  return (
    <StyledLink href={website} target="_blank" rel="noreferrer" title={website}>
      {website}
    </StyledLink>
  );
};

export default CMASummaryTableDataWebsite;
