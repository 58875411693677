import React from 'react';

import classNames from 'classnames';

import SelectAdvancedFilter from '../../lib/SelectAdvancedFilter';
import { useCompaniesContext } from '../CompaniesContext';
import { COMPANY_STATUS_LABEL } from '../constants';

const CompaniesFiltersStatus = () => {
  const { filters, setFilters } = useCompaniesContext();

  const handleSelect = (selection: any[]) => {
    const values = selection.map((item: any) => item.value);
    setFilters({ ...filters, page: 1, status: values });
  };

  const options = Object.keys(COMPANY_STATUS_LABEL).map((key: any) => ({
    label: COMPANY_STATUS_LABEL[key],
    value: key,
  }));

  return (
    <SelectAdvancedFilter
      className={classNames({ active: Boolean(filters.status?.length) })}
      placeholder={'Status'}
      options={options}
      defaultValue={filters?.status ?? []}
      onSelect={handleSelect}
    />
  );
};

export default CompaniesFiltersStatus;
