import React from 'react';

import styled from 'styled-components';

import Button from '../../lib/Button';
import Notification from '../../Notification/Notification';
import { useShopRequestsDataContext } from '../ShopRequestsDataContext';

type Props = {
  onEditMultiple: () => void;
};

const SelectorControl = styled.div`
  margin-right: 10px;
  margin-bottom: 10px;
`;

const ShopRequestTableSelectorControl = ({ onEditMultiple }: Props) => {
  const { items, selectionMode, setSelectionMode, totalItemsIds, selectedItemsIds, setSelectedItemsIds } =
    useShopRequestsDataContext();

  const handleCancelSelection = () => {
    setSelectedItemsIds([]);
  };

  const handleSelectionModeChange = () => {
    setSelectionMode((prev: boolean) => !prev);
    setSelectedItemsIds([]);
  };

  const selectAllItems = () => {
    const stringItemIds = totalItemsIds.map((id) => id);
    setSelectedItemsIds(stringItemIds);
  };

  const groupItemsLength = items.flatMap((row: any) =>
    row.shop_requests?.map((shopRequest: any) => shopRequest.id)
  ).length;

  return (
    <SelectorControl>
      <Notification
        totalCount={groupItemsLength || items.length}
        allItemsCount={totalItemsIds.length}
        selectedCount={selectedItemsIds.length}
        singularLabel={'request'}
        multipleLabel={'requests'}
        onClose={handleCancelSelection}
        onSelectAllItems={selectAllItems}
        actions={
          <Button
            style={{
              color: 'var(--white)',
              backgroundColor: 'var(--button-blue)',
            }}
            onClick={onEditMultiple}
          >
            Edit Multiple
          </Button>
        }
      />
      <Button
        style={{
          display: 'flex',
          width: 36,
          height: 36,
          padding: 7,
          alignItems: 'center',
          borderColor: selectionMode ? 'var(--button-blue)' : 'initial',
          backgroundColor: selectionMode ? 'var(--light-blue)' : 'initial',
        }}
        onClick={handleSelectionModeChange}
      >
        <img
          src={selectionMode ? '/icons/checklist-blue.svg' : '/icons/checklist.svg'}
          alt="Checklist"
          style={{
            width: 24,
            height: 24,
          }}
        />
      </Button>
    </SelectorControl>
  );
};

export default ShopRequestTableSelectorControl;
