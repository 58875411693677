import React, { createContext, useContext } from 'react';

import { CompetitorSwotScores } from 'types/actors';

type EditSwotDataModalContent = {
  isEditMode: boolean;
  stepNumber: number;
  temporarySwotState: CompetitorSwotScores;
  competitorId: number | null;
  onClose: () => void;
  setStepNumber: (number: number) => void;
  setTemporarySwotState: (scores: CompetitorSwotScores) => void;
};

export const EditSwotDataModalContext = createContext<EditSwotDataModalContent>({
  isEditMode: false,
  stepNumber: 1,
  temporarySwotState: {},
  competitorId: null,
  onClose: () => {
    //  Do Nothing
  },
  setStepNumber: () => {
    //  Do Nothing
  },
  setTemporarySwotState: () => {
    //  Do Nothing
  },
});

export const useEditSwotDataModalContext = () => useContext(EditSwotDataModalContext);
