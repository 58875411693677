import React from 'react';

import classNames from 'classnames';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  gap: 10px;
  padding: 0px 10px;
  margin: 10px;
  border-left: 1px solid var(--line-gray);
`;

const StyledLink = styled.span`
  color: var(--text-secondary);
  font-size: 10px;
  cursor: pointer;
  &.active {
    color: var(--active-blue);
  }
  &.disabled {
    color: var(--text-secondary);
    cursor: not-allowed;
  }
`;

type Props = {
  modal: React.ReactNode;
  showSaveFilter: boolean;
  disableSaveFilter: boolean;
  disableReset?: boolean;
  onReset: () => void;
  onSaveFilter: () => void;
};

const FiltersControls = ({
  modal,
  showSaveFilter = true,
  disableSaveFilter = false,
  disableReset = false,
  onReset,
  onSaveFilter,
}: Props) => {
  const handleReset = () => {
    if (!disableReset) {
      onReset();
    }
  };
  const handleSaveFilter = () => {
    if (!disableSaveFilter) {
      onSaveFilter();
    }
  }

  return (
    <Container>
      <StyledLink onClick={handleReset} 
        className={classNames({ disabled: disableReset })}
      >
        Reset
      </StyledLink>
      {modal}
      {showSaveFilter && (
        <StyledLink
          className={classNames({ disabled: disableSaveFilter, active: !disableSaveFilter })}
          onClick={handleSaveFilter}
        >
          Save Filter
        </StyledLink>
      )}
    </Container>
  );
};

export default FiltersControls;
