import React, { useEffect, useState } from 'react';

import { Modal } from 'antd';
import styled from 'styled-components';

import CommunityViewWidgetModalContent from './CommunityViewWidgetModalContent';
import { CommunityViewWidgetModalContext } from './CommunityViewWidgetModalContext';
import CommunityViewWidgetModalFilters from './CommunityViewWidgetModalFilters';
import CommunityViewWidgetModalFooter from './CommunityViewWidgetModalFooter';
import CommunityViewWidgetModalInfo from './CommunityViewWidgetModalInfo';
import { WIDGET_TYPES_LABELS } from '../../../common/Widgets/constants';
import { WidgetType } from '../../../common/Widgets/types';
import { AGGREGATION_TYPE, CREATE_WIDGET_MODAL_WIDTH } from '../constants';

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 0px;
    padding: 0;
  }
  .ant-modal-body {
    line-height: 1;
  }
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  min-height: 614px;
  box-sizing: border-box;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

interface Props {
  open: boolean;
  modalType: string;
  onClose: any;
  widget?: WidgetType;
}

const CommunityViewWidgetModal = ({ open, modalType, onClose, widget }: Props) => {
  const [id, setId] = useState<number | undefined>(undefined);
  const [type, setType] = useState<number>(0);
  const [name, setName] = useState<string>('');
  const [note, setNote] = useState<string>('');

  const [filterId, setFilterId] = useState<number | undefined>(undefined);
  const [time, setTime] = useState<number | undefined>(undefined);
  const [competitors, setCompetitors] = useState<number[]>([]);
  const [careTypes, setCareTypes] = useState<number[]>([]);
  const [roomTypes, setRoomTypes] = useState<number[]>([]);
  const [careLevels, setCareLevels] = useState<number[]>([]);
  const [careLevelsStatsType, setCareLevelsStatsType] = useState<number>(AGGREGATION_TYPE.AVG);
  const [reviewSources, setReviewSources] = useState<number[]>([]);
  const [reviewCategories, setReviewCategories] = useState<number[]>([]);
  const [swotCategories, setSwotCategories] = useState<number[]>([]);

  useEffect(() => {
    if (widget) {
      setId(widget?.id);
      setType(widget.type);
      setName(widget.name || WIDGET_TYPES_LABELS[widget.type]);
      setNote(widget.note);

      setFilterId(widget.filters.id);
      setTime(widget.filters.time);
      setCompetitors(widget.filters.competitors);
      setCareTypes(widget.filters.careTypes);
      setRoomTypes(widget.filters.roomTypes);
      setCareLevels(widget.filters.careLevels);
      setCareLevelsStatsType(widget.filters.careLevelsStatsType || AGGREGATION_TYPE.AVG);
      setReviewSources(widget.filters.reviewSources);
      setReviewCategories(widget.filters.reviewCategories);
      setSwotCategories(widget.filters.swotCategories);
    }
  }, [widget]);

  return (
    <CommunityViewWidgetModalContext.Provider
      value={{
        id,
        name,
        note,
        type,
        filterId,
        time,
        competitors,
        careTypes,
        roomTypes,
        careLevels,
        reviewSources,
        reviewCategories,
        swotCategories,
        careLevelsStatsType,
        setName,
        setNote,
        setTime,
        setCompetitors,
        setCareTypes,
        setRoomTypes,
        setCareLevels,
        setReviewSources,
        setReviewCategories,
        setSwotCategories,
        setCareLevelsStatsType,
        modalType,
        onClose,
      }}
    >
      <StyledModal open={open} footer={false} closable={false} destroyOnClose width={CREATE_WIDGET_MODAL_WIDTH}>
        <Container>
          <CommunityViewWidgetModalFilters />
          <Content>
            <CommunityViewWidgetModalInfo />
            <CommunityViewWidgetModalContent />
            <CommunityViewWidgetModalFooter />
          </Content>
        </Container>
      </StyledModal>
    </CommunityViewWidgetModalContext.Provider>
  );
};

export default CommunityViewWidgetModal;
