import React, { useEffect, useState } from 'react';

import { Button, Tabs, Typography } from 'antd';
import styled from 'styled-components';

import { useAppSelector } from 'store/hook';

import LivingInfoTab from './LivingInfoTab';

const Header = styled(Typography.Title)`
  margin-top: 20px;
`;

const MarketRates = () => {
  const livingTypes = useAppSelector((state) => state.global.livingtypes);
  const [selectedTab, setSelectedTab] = useState('');

  useEffect(() => {
    setSelectedTab(livingTypes[0]?.type || '');
  }, [livingTypes]);

  const items = livingTypes.map((item: any) => ({
    label: (
      <Button
        type="text"
        className={selectedTab == item.type ? 'p_healing_active_tab' : 'btnStyle'}
        shape="round"
        style={{ fontSize: 14 }}
        onClick={() => setSelectedTab(item.type)}
      >
        {item.type}
      </Button>
    ),
    key: item.type,
    destroyInactiveTabPane: true,
    children: <LivingInfoTab livingType={item.livingtype_order} />,
    style: {
      padding: 0,
    },
  }));

  return (
    <div>
      <Header level={3}>Market Rates</Header>
      <Tabs activeKey={selectedTab} tabPosition="top" tabBarGutter={10} size="small" items={items} />
    </div>
  );
};

export default MarketRates;
