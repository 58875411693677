import React from 'react';

import styled from 'styled-components';

import { CMA_SUMMARY_ROOM_COMPARISON_TYPE_FILTER_ROW_FIELDS } from './constants';

const SmallTitle = styled.div`
  font-size: 10px;
`;

const SmallSubtitle = styled.div`
  font-size: 10px;
  color: var(--text-secondary);
`;

const RoomComparisonField = styled.div`
  &:not(:first-of-type) {
    .subtitle {
      margin-left: 10px;
    }
  }
`;

const Row = styled.div`
  display: flex;
  flex-flow: row;
  gap: 10px;
`;

type Props = {
  title?: string[];
  subtitle?: string[];
  hiddenFieldTypes?: string[];
};

const CMASummaryApartmentTypeRoomComparisonTitle = ({ title, subtitle, hiddenFieldTypes = [] }: Props) => (
  <Row>
    {title?.map((titleItem: string, index: number) => (
      <RoomComparisonField key={`${titleItem}-${index}`}>
        <SmallTitle key={`${titleItem}-${index}`}>
          {index > 0 ? '+ ' : ''}
          {titleItem}
        </SmallTitle>
        {subtitle && subtitle[index] && !hiddenFieldTypes.includes(titleItem) && (
          <SmallSubtitle className="subtitle">
            {index > 0 ? ' ' : ''}
            {subtitle[index]}
          </SmallSubtitle>
        )}
      </RoomComparisonField>
    ))}
  </Row>
);

export default CMASummaryApartmentTypeRoomComparisonTitle;
