import React from 'react';

import styled from 'styled-components';

const Container = styled.div`
  font-size: 14px;
  color: var(--link-blue);
  overflow: hidden;
  max-width: 180px;
  word-break: break-word;
`;

type Props = { phone: string };

const CMASummaryTableDataPhone = ({ phone }: Props) => <Container title={phone}>{phone}</Container>;

export default CMASummaryTableDataPhone;
