import React, { createContext, useContext } from 'react';

import { ClientDataType } from 'types/actors';

type UserOnboardingContent = {
  clients: ClientDataType[];
  count: number;
  loading: boolean;
  setPage: (page: number) => void;
  setSearch: (keyword: string) => void;
  showMessage: (type: 'success' | 'error', message: string) => void;
  refresh: () => void;
};

export const UserOnboardingContext = createContext<UserOnboardingContent>({
  clients: [],
  count: 0,
  loading: false,
  setPage: () => {
    // Do nothing
  },
  setSearch: () => {
    // Do nothing
  },
  showMessage: () => {
    // Do nothing
  },
  refresh: () => {
    // Do nothing
  },
});

export const useUserOnboardingContext = () => useContext(UserOnboardingContext);
