import React, { useEffect, useState } from 'react';

import { Button, Divider, Modal, Typography } from 'antd';

import { handleLogout } from 'store/slices/authSlice';

import { store } from '../../store';
import { useAppSelector } from '../../store/hook';

const { Text, Title } = Typography;

const ForgotPasswordChangeNotification = () => {
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    setIsModalOpen(Boolean(currentUser && currentUser.pending_password_reset && !currentUser.manager));
  }, [currentUser]);

  const handleClose = () => {
    store.dispatch(handleLogout());
    setIsModalOpen(false);
  };

  return (
    <Modal
      centered
      open={isModalOpen}
      onCancel={handleClose}
      destroyOnClose
      footer={[
        <Button key={'ok'} type="primary" onClick={handleClose}>
          Accept
        </Button>,
      ]}
      width={800}
    >
      <Title level={2}>Please Change Your Password</Title>
      <Divider />
      <Text>
        In constant effort to ensure security of your data we are kindly requesting from You to alter current login
        information.
        <br />
        Changing your password periodically is a good practice in commitment to your safety and providing unique
        password will help strengthen your account security.
      </Text>
      <br />
      <br />
      <Text type={'secondary'}>
        Please follow the standard <strong>Pricing Assistant</strong> password reset procedure. After completing all the
        necessary actions you will have access to your data.
      </Text>
      <br />
      <br />
      <strong>Password Guidelines:</strong>
      <Text>
        <ul>
          <li>At least 8 characters long.</li>
          <li>Include a mix of uppercase and lowercase letters.</li>
          <li>Include at least one number (0-9).</li>
          <li>Include at least one special character (e.g., @, #, $).</li>
        </ul>
      </Text>
      <Divider />
    </Modal>
  );
};

export default ForgotPasswordChangeNotification;
