import React, { useEffect, useState } from 'react';

import { CheckCircleFilled, CloseCircleFilled, DeleteFilled, EditFilled, PlusOutlined } from '@ant-design/icons/lib';
import { Button, Form, Popconfirm, Table } from 'antd';
import shortUUID from 'short-uuid';
import styled from 'styled-components';

import { EditableTableDataType } from './constants';
import EditableCell from './EditableCell';
import { Columns } from './interfaces';
import { MEDIA_BREAK_POINTS } from '../../constants';

const ActionsContainer = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-around;
`;

const ButtonContainer = styled.div`
  padding: 10px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

interface Props {
  tableLayout?: any;
  loading?: boolean;
  columns: Columns[];
  data: EditableTableDataType[];
  onAdd: (record: EditableTableDataType) => void;
  onUpdate: (record: EditableTableDataType) => void;
  onDelete: (record: Partial<EditableTableDataType>) => void;
}

const EditableTable = ({ columns, data, onAdd, onUpdate, onDelete, ...restProps }: Props) => {
  const [form] = Form.useForm();
  const [dataSource, setDataSource] = useState<Partial<EditableTableDataType>[]>([]);
  const [newRecord, setNewRecord] = useState<Partial<EditableTableDataType> | null>(null);
  const [editingKey, setEditingKey] = useState('');
  const [_, setFormKey] = useState(shortUUID.generate());
  const [formDataDirty, setFormDataDirty] = useState<boolean>(false);
  const [formInitialValues, setFormInitialValues] = useState<any>({});

  useEffect(() => {
    setDataSource(data);
  }, [data]);

  const isEditing = (record: EditableTableDataType) => record.key === editingKey;

  const handleResetFields = () => {
    form.resetFields();
    setFormDataDirty(false);
    setEditingKey('');
  };

  const handleFieldsChange = (changedValues: any, allValues: any) => {
    const fieldKeys = Object.keys(allValues);
    const isDirty = fieldKeys.some((fieldKey) => allValues[fieldKey] != formInitialValues[fieldKey]);
    setFormDataDirty(isDirty);
    setFormKey(shortUUID.generate());
  };

  const onHandleCancelEditItem = () => {
    handleResetFields();

    if (newRecord) {
      setNewRecord(null);
    }
  };

  const handleAddRow = () => {
    const dummyRecord = { key: shortUUID.generate() };
    form.setFieldsValue({ ...dummyRecord });
    setFormInitialValues(form.getFieldsValue(true));
    setNewRecord(dummyRecord);
    setEditingKey(dummyRecord.key);
  };

  const onHandleEditItem = (record: Partial<EditableTableDataType> & { key: React.Key }) => {
    const columnWithInitialValues = columns.filter((col) => !!col.initialValue);
    let initialValues = {};
    columnWithInitialValues.forEach((col) => {
      initialValues = {
        ...initialValues,
        [col.dataIndex]: col.initialValue,
      };
    });

    form.setFieldsValue({ ...record, ...initialValues });
    setFormInitialValues(form.getFieldsValue(true));
    setEditingKey(record.key);
  };

  const saveItem = async (record: EditableTableDataType & { key: React.Key }) => {
    try {
      const row = (await form.validateFields()) as EditableTableDataType;

      if (editingKey === newRecord?.key) {
        setNewRecord(null);
        onAdd({ ...row, key: record.key });
      } else {
        onUpdate({ id: record.id, ...row, key: record.key });
      }

      handleResetFields();
    } catch (errInfo) {
      console.error('Validate Failed:', errInfo);
    }
  };

  const deleteItem = (record: Partial<EditableTableDataType> & { key: React.Key }) => {
    onDelete(record);
  };

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: EditableTableDataType) => ({
        record,
        inputType: col.inputType,
        dataIndex: col.dataIndex,
        options: col.options,
        title: col.title,
        required: col.required,
        editing: isEditing(record),
        handleChange: col.handleChange,
        validator: col.validator,
        calculatedDataIndex: col.calculatedDataIndex,
        calculated: col.calculated,
        dependencies: col.dependencies,
        dependencyType: col.dependencyType,
      }),
    };
  });

  mergedColumns.push({
    title: 'Actions',
    dataIndex: 'actions',
    width: '80px',
    render: (_: any, record: EditableTableDataType) => {
      const editable = isEditing(record);

      return (
        <ActionsContainer>
          {editable && (
            <>
              <Button
                size="small"
                type="link"
                disabled={!formDataDirty}
                icon={<CheckCircleFilled />}
                onClick={() => saveItem(record)}
              />
              <Button
                size="small"
                type="link"
                icon={<CloseCircleFilled style={{ color: 'red' }} />}
                onClick={onHandleCancelEditItem}
              />
            </>
          )}
          {!editable && (
            <>
              <Button
                size="small"
                type="link"
                icon={<EditFilled />}
                disabled={!!editingKey}
                onClick={() => onHandleEditItem(record)}
              />
              <Popconfirm
                disabled={!!editingKey}
                title="Are you sure you want to delete this record?"
                placement="left"
                onConfirm={() => deleteItem(record)}
              >
                <Button size="small" type="link" danger icon={<DeleteFilled />} disabled={!!editingKey} />
              </Popconfirm>
            </>
          )}
        </ActionsContainer>
      );
    },
    onCell: (record: EditableTableDataType) => ({
      record,
      disableTooltip: true,
    }),
  });

  const managedDataSource = newRecord ? [...dataSource, newRecord] : dataSource;

  return (
    <Container>
      <Form form={form} size="small" onValuesChange={handleFieldsChange}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={managedDataSource}
          columns={mergedColumns}
          pagination={false}
          scroll={{ x: MEDIA_BREAK_POINTS.MOBILE }}
          {...restProps}
        />
        <ButtonContainer>
          {!editingKey && (
            <Button
              icon={<PlusOutlined />}
              shape="round"
              disabled={!!editingKey}
              style={{ fontSize: 14 }}
              onClick={handleAddRow}
            >
              Add
            </Button>
          )}
        </ButtonContainer>
      </Form>
    </Container>
  );
};

export default EditableTable;
