import React from 'react';

import { OccupanciesContext } from './OccupanciesContext';
import OccupanciesFormData from './OccupanciesFormData';
import OccupanciesFormHistory from './OccupanciesFormHistory';
import FormModeSwitch from '../../../common/FormNavigation/FormModeSwitch';

interface Props {
  communityId: any;
}

const OccupanciesForm = ({ communityId }: Props) => (
  <OccupanciesContext.Provider value={{ communityId: parseInt(communityId) }}>
    <FormModeSwitch editChild={<OccupanciesFormData />} historyChild={<OccupanciesFormHistory />} />
  </OccupanciesContext.Provider>
);

export default OccupanciesForm;
