import React, { useRef } from 'react';
import { useQuery } from 'react-query';

import styled from 'styled-components';

import CompaniesTableCommunities from './CompaniesTableCommunities';
import CompaniesTableCompany from './CompaniesTableCompany';
import CompaniesTableDate from './CompaniesTableDate';
import CompaniesTablePrimaryUser from './CompaniesTablePrimaryUser';
import CompaniesTableStatus from './CompaniesTableStatus';
import StyledTable from './StyledTable';
import { getCompanies } from '../../../apis/UserAPI';
import { useCompaniesContext } from '../CompaniesContext';
import { COMPANIES_PAGE_SIZE, TABLE_ACTIONS } from '../constants';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
  overflow-y: auto;
`;

const CompaniesTable = () => {
  const containerRef = useRef<HTMLDivElement>(null);

  const { items, totalItemsCount, filters, setItems, setPage } = useCompaniesContext();

  useQuery({
    queryKey: [filters],
    queryFn: () =>
      getCompanies({ search: filters.search, page: filters.page, page_size: filters.pageSize, status: filters.status }),
    onSuccess: (data: any) => {
      setItems(data);
    },
    refetchOnWindowFocus: false,
  });

  const handleChange = (pagination: any, filters: any, sorter: any, extra: any) => {
    const { action } = extra;
    if (action === TABLE_ACTIONS.PAGINATE) {
      setPage(pagination.current);
    }
  };

  const columns = [
    {
      title: 'Company',
      render: (value: any, record: any) => <CompaniesTableCompany item={record} />,
    },
    {
      title: 'Primary User',
      render: (value: any, record: any) => <CompaniesTablePrimaryUser item={record} />,
    },
    {
      title: 'Communities',
      render: (value: any, record: any) => <CompaniesTableCommunities item={record} />,
    },
    {
      title: 'Created',
      render: (value: any, record: any) => <CompaniesTableDate item={record} />,
    },
    {
      title: 'Status',
      render: (value: any, record: any) => <CompaniesTableStatus item={record} />,
    },
  ];

  return (
    <Container ref={containerRef}>
      <StyledTable
        columns={columns}
        dataSource={items}
        rowKey={(record: any) => record.uuid}
        onChange={handleChange}
        showSorterTooltip={false}
        pagination={{
          size: 'small',
          position: ['bottomLeft'],
          showSizeChanger: false,
          current: filters.page,
          pageSize: COMPANIES_PAGE_SIZE,
          total: totalItemsCount,
          showTotal: (total, range) => {
            const displayedItems = range[1] - range[0] + 1;
            return (
              <span>
                Showing {displayedItems} of {total} records
              </span>
            );
          },
        }}
      />
    </Container>
  );
};

export default CompaniesTable;
