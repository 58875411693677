import React, { createContext, useContext } from 'react';

type CommunityInfoContentType = {
  isLoading: boolean;
  parentCommunityId: number;
  activeTabKey: string;
  openPdfModal: boolean;
  toggleRefresh: boolean;
  communityInfo: any;
  competitorInfo: any;
  setActiveTabKey: any;
  setCommunityInfo: any;
  setCompetitorInfo: any;
  setToggleRefresh: any;
  setOpenPdfModal: any;
  showMessage: (type: 'success' | 'error', message: string) => void;
};

export const CommunityInfoContext = createContext<CommunityInfoContentType>({
  isLoading: false,
  parentCommunityId: 0,
  activeTabKey: '',
  openPdfModal: false,
  toggleRefresh: false,
  communityInfo: undefined,
  competitorInfo: undefined,
  setActiveTabKey: () => {
    // Does Nothing
  },
  setCommunityInfo: () => {
    // Does Nothing
  },
  setCompetitorInfo: () => {
    // Does Nothing
  },
  setToggleRefresh: () => {
    // Does Nothing
  },
  setOpenPdfModal: () => {
    // Does Nothing
  },
  showMessage: () => {
    // Does Nothing
  },
});

export const useCommunityInfoContext = () => useContext(CommunityInfoContext);
