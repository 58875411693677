import React, { useState } from 'react';
import { useQuery } from 'react-query';

import styled from 'styled-components';

import { getCMASummary } from 'apis/CMASummaryAPI';
import { useAppSelector } from 'store/hook';
import useDataList from 'utils/hooks/useDataList';

import { CMASummaryContext } from './CMASummaryContext';
import CMASummaryHeader from './CMASummaryHeader';
import CMASummaryTable from './CMASummaryTable';
import { CMA_SUMMARY_INITIAL_FILTERS } from './constants';
import { useFetchCMASummaryData } from './customHooks';
import { transformCMASummaryFiltersInbound } from './filterUtils';
import { CMASummaryFilter, CMASummary as CMASummaryType } from './types';
import { getCMASummaryData } from './utils';
import { ACTOR_TYPE } from '../../constants';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

const CMASummaryTableContainer = styled.div`
  height: 100%;
  background-color: var(--background-gray);
  position: relative;
`;

const CMASummary = () => {
  const { selectedCompany } = useAppSelector((state) => state.global);
  const [cmaSummary, setCmaSummary] = useState<CMASummaryType>();

  const [persistedFilters, setPersistedFilters] = useState<CMASummaryFilter | null>(null);
  const {
    selectedCommunity,
    communityList,
    livingtypes: livingTypes,
    apartmentTypeGroups,
  } = useAppSelector((state) => state.global);

  const { refetch: refetchCMASummary } = useQuery({
    queryKey: ['CMASummary', selectedCompany],
    queryFn: () => getCMASummary(selectedCompany!.id),
    onSuccess: (response) => {
      if (response?.results?.length) {
        const cmaSummary = response.results[0];
        setCmaSummary(cmaSummary);
        if (cmaSummary.filter) {
          const filtersData = transformCMASummaryFiltersInbound(cmaSummary.filter);
          setFilters(filtersData);
          setPersistedFilters(filtersData);
        }
      }
    },
    enabled: Boolean(selectedCompany?.id),
    refetchOnWindowFocus: false,
  });

  const { filters, setFilters } = useDataList({
    initialState: {
      filters: CMA_SUMMARY_INITIAL_FILTERS,
    },
  });

  let community, competitors;
  if (selectedCommunity?.type === ACTOR_TYPE.COMMUNITY) {
    community = communityList.find((community) => community?.id === selectedCommunity?.id);
    if (filters.competitors?.length) {
      competitors = community?.competitors.filter((competitor: any) => filters.competitors.includes(competitor.id));
    } else {
      competitors = community?.competitors;
    }
  } else {
    community = communityList.find((community) => community?.id === selectedCommunity?.parentId);
    competitors = [community?.competitors.find((competitor: any) => competitor.id === selectedCommunity?.id)];
  }

  const { loading, ratings, feesIncentives, careFees, occupancies, livingInfo } = useFetchCMASummaryData(
    community,
    competitors,
    filters
  );

  return (
    <CMASummaryContext.Provider
      value={{
        cmaSummaryData: getCMASummaryData({
          community,
          competitors,
          ratings,
          livingTypes,
          feesIncentives,
          careFees,
          occupancies,
          livingInfo,
          apartmentTypeGroups,
          filters: filters as CMASummaryFilter,
        }),
        cmaSummary,
        loading,
        community,
        competitors,
        apartmentTypeGroups,
        persistedFilters,
        filters: filters as CMASummaryFilter,
        livingInfo,
        setFilters,
        refetchCMASummary,
      }}
    >
      <Container>
        <CMASummaryHeader />
        <CMASummaryTableContainer>
          <CMASummaryTable />
        </CMASummaryTableContainer>
      </Container>
    </CMASummaryContext.Provider>
  );
};

export default CMASummary;
