import React, { useRef } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import styled from 'styled-components';

import IncentivesTableIncentivesActions from './IncentivesTableIncentivesActions';
import IncentivesTableIncentivesCommunities from './IncentivesTableIncentivesCommunities';
import IncentivesTableIncentivesDate from './IncentivesTableIncentivesDate';
import IncentivesTableIncentivesIncentive from './IncentivesTableIncentivesIncentive';
import { getIncentives } from '../../../../apis/IncentiveAPI';
import { useAppSelector } from '../../../../store/hook';
import { INCENTIVES_PAGE_SIZE, TABLE_ACTIONS } from '../../constants';
import { getOrdering } from '../../helpers';
import { useIncentivesContext } from '../../IncentivesContext';
import { useIncentivesDataContext } from '../../IncentivesDataContext';
import IncentivesStyledTable from '../IncentivesStyledTable';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
  overflow-y: auto;
`;

interface Props {
  onSelection: any;
}

const IncentivesTableIncentives = () => {
  const { managementCompanyId } = useParams();
  const { selectedCompany } = useAppSelector((state) => state.global);

  const containerRef = useRef<HTMLDivElement>(null);

  const { key, currentTab } = useIncentivesContext();
  const { createMode, items, totalItemsCount, filters, ordering, setItems, setPage, setOrdering } =
    useIncentivesDataContext();

  const { isLoading, isFetching } = useQuery({
    queryKey: ['incentives', 'list', managementCompanyId, selectedCompany, filters, ordering, key, currentTab],
    queryFn: () =>
      getIncentives({
        search: filters.search,
        page: filters.page,
        page_size: filters.pageSize,
        ordering: ordering,
        companies: [selectedCompany?.id],
        management_companies: managementCompanyId ? [managementCompanyId] : undefined,
        communities: filters.communities,
        room_types: filters.roomTypes,
        external_care_types: filters.careTypes,
      }),
    onSuccess: (data) => {
      setItems(data);
    },
    enabled: Boolean(selectedCompany) || Boolean(managementCompanyId),
    refetchOnWindowFocus: false,
  });

  const handleChange = (pagination: any, filters: any, sorter: any, extra: any) => {
    const { action } = extra;

    if (action === TABLE_ACTIONS.PAGINATE) {
      setPage(pagination.current);
    } else if (action === TABLE_ACTIONS.SORT) {
      setOrdering(getOrdering(sorter));
    }
  };

  const columns = [
    {
      title: 'Incentive',
      dataIndex: 'incentive',
      width: '30%',
      sorter: true,
      key: 'name',
      render: (value: any, record: any) => <IncentivesTableIncentivesIncentive item={record} />,
    },
    {
      title: 'Communities',
      render: (value: any, record: any) => <IncentivesTableIncentivesCommunities item={record} />,
    },
    {
      title: 'Created',
      sorter: true,
      key: 'created_at',
      render: (value: any, record: any) => <IncentivesTableIncentivesDate item={record} />,
    },
    {
      title: '',
      width: 100,
      render: (value: any, record: any) => <IncentivesTableIncentivesActions item={record} />,
    },
  ];

  return (
    <Container ref={containerRef}>
      <IncentivesStyledTable
        columns={columns}
        dataSource={items}
        loading={isLoading || isFetching}
        rowKey={(record: any) => record.uuid}
        onChange={handleChange}
        showSorterTooltip={false}
        pagination={
          createMode
            ? false
            : {
                size: 'small',
                position: ['bottomLeft'],
                showSizeChanger: false,
                current: filters.page,
                pageSize: INCENTIVES_PAGE_SIZE,
                total: totalItemsCount,
                showTotal: (total, range) => {
                  const displayedItems = range[1] - range[0] + 1;
                  return (
                    <span>
                      Showing {displayedItems} of {total} records
                    </span>
                  );
                },
              }
        }
      />
    </Container>
  );
};

export default IncentivesTableIncentives;
