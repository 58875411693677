import React from 'react';

import { Form, Radio } from 'antd';
import styled from 'styled-components';

import { CMA_SUMMARY_VIEW_FILTER_DISPLAY_VALUE } from '../constants';

const Title = styled.div`
  letter-spacing: 0.5px;
  color: var(--text-primary);
  font-family: var(--font-bold);
  font-size: 12px;
  margin-bottom: 10px;
`;

const RadioButton = styled(Radio.Button)`
  &&&.ant-radio-button-wrapper {
    font-size: 14px;
  }
`;

const CMASummaryViewFilterValuesSelector = () => (
  <div>
    <Title>DISPLAY VALUE DIFFERENCES</Title>
    <Form.Item name="valueDisplay">
      <Radio.Group buttonStyle="solid">
        <RadioButton value={CMA_SUMMARY_VIEW_FILTER_DISPLAY_VALUE.DONT_DISPLAY}>Don't Display</RadioButton>
        <RadioButton value={CMA_SUMMARY_VIEW_FILTER_DISPLAY_VALUE.PERCENTAGES}>Percentages</RadioButton>
        <RadioButton value={CMA_SUMMARY_VIEW_FILTER_DISPLAY_VALUE.AMOUNTS}>Amounts</RadioButton>
      </Radio.Group>
    </Form.Item>
  </div>
);

export default CMASummaryViewFilterValuesSelector;
