import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import FiltersControls from 'common/FiltersControls/FiltersControls';

import { duplicateView } from '../../../apis/ViewAPI';
import { useAppDispatch, useAppSelector } from '../../../store/hook';
import { handleStoreUserViews } from '../../../store/slices/globalSlice';
import { useRootContext } from '../../layout/RootContext';
import { useCommunityViewContext } from '../CommunityViewContext';
import ViewSaveFilterModal from '../modals/ViewSaveFilterModal';
import { equalFilters, transformFilterInbound, transformFilterOutbound } from '../utils';

const HeaderViewFiltersControls = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { communityId, competitorId } = useCommunityViewContext();
  const { showMessage } = useRootContext();

  const { views } = useAppSelector((state) => state.global);

  const [filtersModified, setFiltersModified] = useState<boolean>(false);
  const [openSaveModal, setOpenSaveModal] = useState<boolean>(false);
  const { view, viewFilters, setViewFilters } = useCommunityViewContext();
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const permissions = currentUser?.all_permissions;

  const handleConfirmCreateNew = async (values: any) => {
    await duplicateView(view.id, { ...values, filter: transformFilterOutbound(viewFilters) })
      .then((response: any) => {
        const viewId = response.id;
        dispatch(handleStoreUserViews([...views, response]));
        showMessage('success', 'New view created from template.');
        navigate(`/community/${communityId}/view/${viewId}`);
        setOpenSaveModal(false);
      })
      .catch(() => {
        showMessage('error', 'Failed to create new view.');
      });
  };

  const handleConfirmReset = async () => {
    const transformedFilters = transformFilterInbound(view?.filter);
    if (competitorId) {
      setViewFilters((prevState: any) => Object.assign({}, transformedFilters, { competitors: [competitorId] }));
    } else {
      setViewFilters(transformedFilters);
    }
  };

  useEffect(() => {
    setFiltersModified(equalFilters(view?.filter, viewFilters));
  }, [view, viewFilters]);

  return (
    <FiltersControls
      modal={
        <ViewSaveFilterModal
          open={openSaveModal}
          onConfirmCreateNew={handleConfirmCreateNew}
          onCancel={() => setOpenSaveModal(false)}
        />
      }
      showSaveFilter={permissions?.indexOf('views.add_view') !== -1}
      disableSaveFilter={filtersModified}
      onReset={handleConfirmReset}
      onSaveFilter={() => setOpenSaveModal(true)}
    />
  );
};

export default HeaderViewFiltersControls;
