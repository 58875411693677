import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from '../index';

export interface UserType {
  user_id: number;
  name: string;
  FullName: string;
  fname: string;
  email: string;
  isSuperuser: boolean;
  isStaff: boolean;
  groups: any[];
  company?: number;
  companyPrimaryEmail?: string;
  management_company?: any;
  ManagementcompanyName?: string;
  level?: string;
  avatar?: string;
  companyName?: string;
  PAUser_Id: any;
  all_permissions: string[];
  pending_password_reset?: boolean;
  feature_flags?: {
    try_beta_application: boolean;
    use_beta_application: boolean;
    show_beta_settings: boolean;
    show_beta_community_data: boolean;
  };
  manager?: {
    id: number;
    name: string;
    email: string;
    isSuperuser: boolean;
    isStaff: boolean;
    company?: number;
    management_company?: any;
    status?: number;
    level?: string;
  };
}

type FeatureFlagsDataType = {
  showBetaApplication: boolean;
};

interface AuthType {
  currentUser: UserType | null;
  featureFlags: FeatureFlagsDataType | null;
  refreshToken: string;
  accessToken: string;
}

// Define the initial state using that type
const initialState: AuthType = {
  currentUser: null,
  featureFlags: {
    showBetaApplication: false,
  },
  refreshToken: '',
  accessToken: '',
};

export const authSlice = createSlice({
  name: 'auth',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    handleLogin: (state, action: PayloadAction<AuthType>) => {
      state.currentUser = action.payload.currentUser;
      state.featureFlags = action.payload.featureFlags;
      state.refreshToken = action.payload.refreshToken;
      state.accessToken = action.payload.accessToken;
    },
    handleUpdateAccessToken: (state, action: PayloadAction<string>) => {
      state.accessToken = action.payload;
    },
    handleUpdateUser: (state, action: PayloadAction<UserType>) => {
      state.currentUser = action.payload;
    },
    handleUpdateFeatureFlags: (state, action: PayloadAction<FeatureFlagsDataType>) => {
      state.featureFlags = action.payload;
    },
    handleUpdateAvatar: (state, action: PayloadAction<string>) => {
      if (state.currentUser) {
        state.currentUser = {
          ...state.currentUser,
          avatar: action.payload,
        };
      }
    },

    handleLogout: (state) => {
      state.currentUser = null;
      state.featureFlags = null;
      state.refreshToken = '';
      state.accessToken = '';
    },
  },
});

export const {
  handleLogin,
  handleLogout,
  handleUpdateAccessToken,
  handleUpdateUser,
  handleUpdateFeatureFlags,
  handleUpdateAvatar,
} = authSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectAuth = (state: RootState) => state.auth;

export default authSlice.reducer;
