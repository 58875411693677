import { AGGREGATION_TYPE, AGGREGATION_TYPE_LABELS } from 'components/View/constants';

import {
  CMA_SUMMARY_APARTMENT_TYPE_DATA_ROW,
  CMA_SUMMARY_APARTMENT_TYPE_DATA_ROW_ROOM_COMPARISON,
  CMA_SUMMARY_APARTMENT_TYPE_GROUP_DATA_ROW,
  CMA_SUMMARY_APARTMENT_TYPE_GROUP_DATA_ROW_ROOM_COMPARISON,
  CMA_SUMMARY_CARE_FEE_DATA_ROW,
  CMA_SUMMARY_CARE_FEE_GROUP_DATA_ROW,
  CMA_SUMMARY_DATA_ROW_TYPE,
  CMA_SUMMARY_LIVING_TYPE_DATA_ROW,
  CMA_SUMMARY_LIVING_TYPE_FEE_DATA_ROW,
  CMA_SUMMARY_ROOM_COMPARISON_TYPE_FILTER_ROW_FIELDS,
  CMA_SUMMARY_VIEW_FILTER_DISPLAY_VALUE,
} from './constants';
import { CMASummaryFilter } from './types';
import { formatAmount, formatPercentage, getCMASummaryReviewSources, parseCMASummaryCommunityData, parseCMASummaryFeeData } from './utils';

const FIXED_TITLE_COLUMNS = ['Type', 'Field'];
const COLUMN_TYPE = {
  GENERAL_INFO: 'General Info',
  OTHER: 'Other',
};

const formatNumber = (num: number | undefined) => {
  if (!num || typeof num !== 'number') {
    return num;
  }

  // Check if there are non-zero decimals after rounding to two decimal places
  return num % 1 === 0 ? num.toFixed(0) : num.toFixed(2);
};

const getFormattedDifference = (difference: number | null | undefined, filters: Partial<CMASummaryFilter>) => {
  if (filters.viewOptions?.valueDisplay === CMA_SUMMARY_VIEW_FILTER_DISPLAY_VALUE.PERCENTAGES) {
    return formatPercentage(difference);
  } else if (filters.viewOptions?.valueDisplay === CMA_SUMMARY_VIEW_FILTER_DISPLAY_VALUE.AMOUNTS) {
    return formatAmount('$', difference);
  }
};

const getCMASummaryRatingRowsCSV = (rowContent: any, filters: Partial<CMASummaryFilter>) => {
  const ratings: (string | number | null | undefined)[] = [];
  const reviews: (string | number | null | undefined)[] = [];

  rowContent.forEach((item: (string | number)[]) => {
    const rating = item[0];
    const reviewsNumber = item[1];

    // ratings
    const parsedRating = typeof rating === 'string' ? parseFloat(rating) : rating;
    const parsedRatingData: (number | undefined | null)[] = parseCMASummaryCommunityData(
      parsedRating,
      filters.viewOptions?.valueDisplay
    );
    const ratingValue = parsedRatingData[0];
    let ratingDifference: string | number | undefined | null = parsedRatingData[1];
    if (filters.viewOptions?.valueDisplay === CMA_SUMMARY_VIEW_FILTER_DISPLAY_VALUE.PERCENTAGES) {
      ratingDifference = formatPercentage(ratingDifference);
    } else if (filters.viewOptions?.valueDisplay === CMA_SUMMARY_VIEW_FILTER_DISPLAY_VALUE.AMOUNTS) {
      ratingDifference = ratingDifference?.toFixed(1);
    }
    ratings.push(ratingDifference ? `${ratingValue} (difference to community: ${ratingDifference})` : ratingValue);

    // reviews
    const parsedReviewsNumber: number = typeof reviewsNumber === 'string' ? parseFloat(reviewsNumber) : reviewsNumber;
    const parsedReviewsData: (number | undefined | null)[] = parseCMASummaryCommunityData(
      parsedReviewsNumber,
      filters.viewOptions?.valueDisplay
    );
    const reviewsNumberValue = parsedReviewsData[0];
    let reviewsNumberDifference: string | number | undefined | null = parsedReviewsData[1];
    if (filters.viewOptions?.valueDisplay === CMA_SUMMARY_VIEW_FILTER_DISPLAY_VALUE.PERCENTAGES) {
      reviewsNumberDifference = formatPercentage(reviewsNumberDifference);
    }
    reviews.push(
      reviewsNumberDifference
        ? `${reviewsNumberValue} (difference to community: ${reviewsNumberDifference})`
        : reviewsNumberValue
    );
  });

  return { ratings, reviews };
};

const getCMASummaryDataRowsCSV = (rowContent: any, filters: Partial<CMASummaryFilter>) => {
  const rates: (string | number | undefined | null)[] = [];
  const squareFootages: (string | number | undefined | null)[] = [];

  rowContent.forEach((item: number[] | number[][]) => {
    const [rent, squareFootage, rentDifference, squareFootageDifference] = parseCMASummaryCommunityData(
      item,
      filters.viewOptions?.valueDisplay
    );

    let formattedRentDiff, formattedSqFtDiff;
    if (filters.viewOptions?.valueDisplay === CMA_SUMMARY_VIEW_FILTER_DISPLAY_VALUE.PERCENTAGES) {
      formattedRentDiff = formatPercentage(rentDifference);
      formattedSqFtDiff = formatPercentage(squareFootageDifference);
    } else if (filters.viewOptions?.valueDisplay === CMA_SUMMARY_VIEW_FILTER_DISPLAY_VALUE.AMOUNTS) {
      formattedRentDiff = formatAmount('$', rentDifference);
      formattedSqFtDiff = formatAmount('$', squareFootageDifference);
    }

    if (rent) {
      rates.push(formattedRentDiff ? `"${rent} (difference to community: ${formattedRentDiff})"` : `"${rent}"`);
    } else {
      rates.push('');
    }
    if (squareFootage) {
      squareFootages.push(
        formattedSqFtDiff ? `"${squareFootage} (difference to community: ${formattedSqFtDiff})"` : `"${squareFootage}"`
      );
    } else {
      squareFootages.push('');
    }
  });

  return { rates, squareFootages };
};

const getCMASummaryLivingTypeFeeRowsCSV = (rowContent: any, filters: Partial<CMASummaryFilter>) =>
  rowContent.map((item: (number | number[])[]) => {
    const [fee, communityFeeEquivalentFactor] = item;
    const [feeValue, feeDifference] = parseCMASummaryFeeData(fee, filters.viewOptions?.valueDisplay);

    const formattedFeeDifferenceValue = getFormattedDifference(feeDifference, filters);
    if (feeValue) {
      return formattedFeeDifferenceValue
        ? `"${feeValue} (difference to community: ${formattedFeeDifferenceValue})"`
        : `"${feeValue}"`;
    } else {
      return '';
    }
  });

const getCMASummaryCareFeeRowCSV = (rowContent: any, filters: Partial<CMASummaryFilter>) =>
  rowContent.map((item: number | number[]) => {
    const [careFeeValue, careFeeDifference] = parseCMASummaryFeeData(item, filters.viewOptions?.valueDisplay);

    const formattedFeeDifferenceValue = getFormattedDifference(careFeeDifference, filters);
    if (careFeeValue) {
      return formattedFeeDifferenceValue
        ? `"${formatNumber(careFeeValue)} (difference to community: ${formattedFeeDifferenceValue})"`
        : `"${formatNumber(careFeeValue)}"`;
    } else {
      return '';
    }
  });

const getCMASummaryComparisonRowCSV = (rowContent: any, filters: Partial<CMASummaryFilter>) =>
  rowContent.map((item: number | number[]) => {
    const [comparisonRowValue, secondItem, comparisonRowDifference] = parseCMASummaryCommunityData(
      item,
      filters.viewOptions?.valueDisplay
    );

    const formattedDifference = getFormattedDifference(comparisonRowDifference, filters);
    if (comparisonRowValue) {
      return formattedDifference
        ? `"${comparisonRowValue} (difference to community: ${formattedDifference})"`
        : `"${comparisonRowValue}"`;
    } else {
      return '';
    }
  });

export const transformCSV = (community: any, data: any, filters: CMASummaryFilter) => {
  const csvData: any[] = [];
  const aggregationType = AGGREGATION_TYPE_LABELS[filters.aggregationType || AGGREGATION_TYPE.AVG];

  data.forEach((row: any) => {
    const rowMetadata = row[0];
    const rowContent = row.slice(1);

    switch (rowMetadata?.type) {
      // Form the header CSV row
      // Fixed columns Type and Field, variable columns of Community and it's Competitors
      case CMA_SUMMARY_DATA_ROW_TYPE.COMMUNITY_NAME:
        csvData.push([...FIXED_TITLE_COLUMNS, ...rowContent.map((item: string) => (item ? `"${item}"` : undefined))]);
        break;
      case CMA_SUMMARY_DATA_ROW_TYPE.ADDRESS:
        csvData.push([
          COLUMN_TYPE.GENERAL_INFO,
          'Address',
          ...rowContent.map((item: string) => (item ? `"${item}"` : undefined)),
        ]);
        break;
      case CMA_SUMMARY_DATA_ROW_TYPE.WEBSITE:
        csvData.push([COLUMN_TYPE.GENERAL_INFO, 'Website', ...rowContent]);
        break;
      case CMA_SUMMARY_DATA_ROW_TYPE.PHONE:
        csvData.push([COLUMN_TYPE.GENERAL_INFO, 'Phone', ...rowContent]);
        break;
      // Concatenate all address parts
      case CMA_SUMMARY_DATA_ROW_TYPE.REVIEWS: {
        const reviewSources = getCMASummaryReviewSources(filters);
        const { ratings, reviews } = getCMASummaryRatingRowsCSV(rowContent, filters);
        csvData.push([COLUMN_TYPE.GENERAL_INFO, `"Rating (${reviewSources})"`, ...ratings]);
        csvData.push([COLUMN_TYPE.GENERAL_INFO, `"Number of reviews (${reviewSources})"`, ...reviews]);
        break;
      }
      // Check for presence of occupancies data for Living Type
      case CMA_SUMMARY_LIVING_TYPE_DATA_ROW:
        if (rowMetadata.hasOccupancies) {
          csvData.push([
            rowMetadata.title,
            'Occupancy',
            ...rowContent.map((item: [number, number, number]) => (item[0] ? `${item[0]}%` : undefined)),
          ]);
          csvData.push([
            rowMetadata.title,
            'Total number of units',
            ...rowContent.map((item: [number, number, number]) => item[1]),
          ]);
          csvData.push([
            rowMetadata.title,
            'Occupied number of units',
            ...rowContent.map((item: [number, number, number]) => (item[1] && item[2] ? item[1] - item[2] : undefined)),
          ]);
        }
        break;
      case CMA_SUMMARY_APARTMENT_TYPE_GROUP_DATA_ROW: {
        const { rates, squareFootages } = getCMASummaryDataRowsCSV(rowContent, filters);
        csvData.push([rowMetadata.subtitle, `"${rowMetadata.title} Rate (${aggregationType})"`, ...rates]);
        csvData.push([rowMetadata.subtitle, `"${rowMetadata.title} Sq.Ft. (${aggregationType})"`, ...squareFootages]);
        break;
      }
      case CMA_SUMMARY_APARTMENT_TYPE_DATA_ROW: {
        const { rates, squareFootages } = getCMASummaryDataRowsCSV(rowContent, filters);
        csvData.push([rowMetadata.subtitle, `${rowMetadata.title} Rate`, ...rates]);
        csvData.push([rowMetadata.subtitle, `${rowMetadata.title} Sq.Ft.`, ...squareFootages]);
        break;
      }
      case CMA_SUMMARY_LIVING_TYPE_FEE_DATA_ROW: {
        const fees = getCMASummaryLivingTypeFeeRowsCSV(rowContent, filters);
        csvData.push([rowMetadata.subtitle, rowMetadata.title, ...fees]);
        break;
      }
      case CMA_SUMMARY_CARE_FEE_GROUP_DATA_ROW: {
        csvData.push([
          rowMetadata.living_type,
          'Care Fee Type',
          ...rowContent.map((item: [number, string, string]) => item[1]),
        ]);
        const averageCareFees: string[] = getCMASummaryCareFeeRowCSV(rowContent, filters);
        csvData.push([rowMetadata.living_type, `Care Fee (${aggregationType})`, ...averageCareFees]);
        break;
      }
      case CMA_SUMMARY_CARE_FEE_DATA_ROW: {
        const careFees: string[] = getCMASummaryCareFeeRowCSV(rowContent, filters);
        csvData.push([rowMetadata.living_type, `Care Fee ${rowMetadata.title}`, ...careFees]);
        break;
      }
      case CMA_SUMMARY_DATA_ROW_TYPE.INCENTIVES: {
        const rowIncentivesWithContent = rowContent.map((incentives: any[]) => [
          ...incentives.filter((incentive: any) => Boolean(incentive.incentive)),
        ]);
        const incentivesMaxCount = Math.max(...rowIncentivesWithContent.map((incentives: any[]) => incentives.length));

        for (let i = 0; i < incentivesMaxCount; i++) {
          csvData.push([
            COLUMN_TYPE.OTHER,
            'Incentive',
            ...rowIncentivesWithContent.map((incentives: any[]) =>
              incentives[i] ? `"${incentives[i].incentive}"` : undefined
            ),
          ]);
        }
        break;
      }
      case CMA_SUMMARY_APARTMENT_TYPE_DATA_ROW_ROOM_COMPARISON: {
        const comparisonRowContent = getCMASummaryComparisonRowCSV(rowContent, filters);
        csvData.push([
          rowMetadata.livingType,
          `${rowMetadata.apartmentType}` +
            rowMetadata.title
              .map((item: string, index: string) => {
                const subtitle =
                  item === CMA_SUMMARY_ROOM_COMPARISON_TYPE_FILTER_ROW_FIELDS.CARE
                    ? ` (${rowMetadata.subtitle[index]})`
                    : '';
                return `${item}${subtitle}`;
              })
              .join(' + '),
          ...comparisonRowContent,
        ]);
        break;
      }
      case CMA_SUMMARY_APARTMENT_TYPE_GROUP_DATA_ROW_ROOM_COMPARISON: {
        const comparisonRowContent = getCMASummaryComparisonRowCSV(rowContent, filters);
        csvData.push([
          rowMetadata.livingType,
          `${rowMetadata.apartmentGroupType} ` +
            rowMetadata.title
              .map((item: string, index: string) => {
                const subtitle = [
                  CMA_SUMMARY_ROOM_COMPARISON_TYPE_FILTER_ROW_FIELDS.RATE,
                  CMA_SUMMARY_ROOM_COMPARISON_TYPE_FILTER_ROW_FIELDS.CARE,
                ].includes(item)
                  ? ` (${rowMetadata.subtitle[index]})`
                  : '';
                return `${item}${subtitle}`;
              })
              .join(' + '),
          ...comparisonRowContent,
        ]);
        break;
      }
    }
  });

  // Merge available data together
  let csvContent = '';
  csvData.forEach((row: any) => {
    csvContent += row.join(',') + '\n';
  });

  // Generate binary Blob and initiate download
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8,' });
  const objUrl = URL.createObjectURL(blob);
  const currentDate = new Date().toLocaleDateString();
  const communityName = community?.name.split(' ').join('_');
  const filename = `CMA_Summary_${communityName}_${currentDate}.csv`;

  const link = document.createElement('a');
  link.setAttribute('href', objUrl);
  link.setAttribute('download', filename);

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
