import React from 'react';

import { Scatter } from '@ant-design/plots';

import {
  GRID_STROKE_COLOR,
  GRID_STROKE_SWOT_COLOR,
  SWOT_PERFORMANCE_BETTER_REGION_COLOR,
  SWOT_PERFORMANCE_WORSE_REGION_COLOR,
} from './constants';

type Props = {
  data: any;
};

const SCATTER_CHART_HEIGHT = 262;

const ScatterChart = ({ data }: Props) => {
  return (
    <Scatter
      data={data}
      appendPadding={20}
      height={SCATTER_CHART_HEIGHT}
      xField="score"
      yField="categoryScore"
      size={5}
      color="blue"
      shape="circle"
      pointStyle={{ fillOpacity: 1 }}
      yAxis={{
        line: {
          style: {
            stroke: ' #aaa',
          },
        },
        title: {
          text: 'Importance to Customer',
        },
      }}
      xAxis={{
        min: 0,
        max: 6,
        tickCount: 8,
        title: {
          text: 'Avg.Market Competition Rating',
        },
        label: {
          autoHide: true,
          autoRotate: false,
        },
        grid: {
          line: {
            style: (text: any, index: any) => {
              if (index == 3) {
                return {
                  stroke: GRID_STROKE_SWOT_COLOR,
                };
              }
              return {
                stroke: GRID_STROKE_COLOR,
              };
            },
          },
        },
        line: {
          style: {
            stroke: '#aaa',
          },
        },
        tickLine: {
          length: 0,
        },
      }}
      annotations={[
        {
          type: 'region',
          start: ['min', 'min'],
          end: [3, 'max'],
          style: {
            fill: SWOT_PERFORMANCE_BETTER_REGION_COLOR,
          },
        },
        {
          type: 'region',
          start: [3, 'min'],
          end: ['max', 'max'],
          style: {
            fill: SWOT_PERFORMANCE_WORSE_REGION_COLOR,
          },
        },
      ]}
      tooltip={{
        fields: ['Score', 'Category Score'],
      }}
      label={{
        formatter: function (val: any) {
          return val?.categoryName;
        },
      }}
      legend={false}
      style={{ opacity: '1', height: '100%' }}
    />
  );
};

export default ScatterChart;
