import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { CaretRightFilled } from '@ant-design/icons';
import { Button, Col, message, Row, Tabs, Tooltip } from 'antd';
import { format } from 'date-fns';

import DashboardReports from 'components/Dashboard/DashboardReports';
import axios from 'config/axiosPrivate';
import AdditionalLinks from 'pages/price_healing/AdditionalLinks';
import Calculator from 'pages/PriceHealing/Calculator';
import CommunityDetails from 'pages/PriceHealing/CommunityDetails';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { handleClearCommunityData } from 'store/slices/communitySlice';

import AccommodationForm from './Accommodation/AccommodationForm';
import BasicInfoForm from './BasicInfoForm';
import CMASummary from './CMASummary/CMASummary';
import ComparisonTab from './ComparisonTab';
import CompetitorsForm from './CompetitorsForm';
import FeesForm from './Fees/FeesForm';
import ImageBrochure from './ImageBrochure';
import Occupancies from './Occupancies/Occupancies';
import RentRoll from './RentRoll';
import ShopNotes from './ShopNotes';
import classes from './styles.module.scss';
import CommunityIncentives from '../../components/CommunityInfo/CommunityIncentives';

const PriceHealing = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [params] = useSearchParams();

  const communityId = params.get('id') as string;
  const competitor_id = params.get('c_id') as string;
  const currentUser = useAppSelector((state) => state.auth.currentUser);

  const permissions = currentUser?.all_permissions;
  const [communityInfo, setCommunityInfo] = React.useState<any>(null);
  const [competitorInfo, setCompetitorInfo] = React.useState<any>(null);
  const [openPdfModal, setOpenPdfModal] = useState<boolean>(false);
  const [toggleRefresh, setToggleRefresh] = useState(false);

  const protectedTabs = () => {
    if (currentUser) {
      const isManagerOrNoGroup = currentUser.groups.length == 0 || Boolean(currentUser?.manager);
      const items: any = [];
      if (permissions?.indexOf('PAScreens.view_screencomparison') != -1 || isManagerOrNoGroup) {
        items.push({
          label: 'Comparison',
          key: 'Comparison',
          children: (
            <ComparisonTab
              community_id={parseInt(communityId)}
              communityInfo={communityInfo}
              competitorInfo={competitorInfo}
              loading={state.isLoading}
              openPdfModal={openPdfModal}
              setOpenPdfModal={setOpenPdfModal}
              setToggleRefresh={setToggleRefresh}
              toggleRefresh={toggleRefresh}
            />
          ),
          style: {
            padding: 0,
          },
        });
      }
      if (permissions?.indexOf('PAScreens.view_dashboardreports') != -1 || isManagerOrNoGroup) {
        items.push({
          label: 'Dashboard',
          key: 'Dashboard',
          children: <DashboardReports communityId={parseInt(communityId)} communityInfo={communityInfo} />,
        });
      }
      if (permissions?.indexOf('PAScreens.view_basic_info') != -1 || isManagerOrNoGroup) {
        items.push({
          label: 'Basic Info',
          key: 'Basic_Info',
          children: (
            <BasicInfoForm
              communityId={parseInt(communityId ?? '')}
              initialData={communityInfo}
              getCommunityInfo={() => state.refetch()}
              setData={setCommunityInfo}
            />
          ),
          disabled: communityInfo === null,
        });
      }
      if (permissions?.indexOf('PAScreens.view_screens_community_details') != -1 || isManagerOrNoGroup) {
        items.push({
          label: 'Community Details',
          key: 'Community details',
          children: <CommunityDetails community_id={communityId} />,
          disabled: communityInfo === null,
        });
      }
      if (permissions?.indexOf('PAScreens.view_screens_accommodation') != -1 || isManagerOrNoGroup) {
        items.push({
          label: 'Accommodation',
          key: 'Accommodation',
          children: <AccommodationForm community_id={communityId} />,
          style: {
            padding: 0,
          },
          disabled: communityInfo === null,
        });
      }
      if (permissions?.indexOf('PAScreens.view_screens_fees_care') != -1 || isManagerOrNoGroup) {
        items.push({
          label: 'Fees & Care',
          key: 'Fees',
          children: <FeesForm community_id={communityId} />,
          style: {
            padding: 0,
          },
          disabled: communityInfo === null,
        });
      }
      if (permissions?.indexOf('PricingAssistant.view_incentive') != -1 || currentUser.manager) {
        items.push({
          label: 'Incentives',
          key: 'incentives',
          children: <CommunityIncentives communityId={Number(communityId)} />,
          style: {
            padding: 0,
          },
          disabled: communityInfo === null,
        });
      }
      if (permissions?.indexOf('PAScreens.view_screens_competitor') != -1 || isManagerOrNoGroup) {
        items.push({
          label: 'Competitors',
          key: 'Competitors',
          children: <CompetitorsForm community_id={parseInt(communityId)} communityInfo={communityInfo} />,
          style: {
            padding: 0,
          },
          disabled: communityInfo === null,
        });
      }
      if (permissions?.indexOf('PAScreens.view_imagesbrochure') != -1 || isManagerOrNoGroup) {
        items.push({
          label: 'Images/Brochures',
          key: 'brochure',
          children: <ImageBrochure communityId={parseInt(communityId)} />,
          style: {
            padding: 0,
          },
          disabled: communityInfo === null,
        });
      }
      if (permissions?.indexOf('PAScreens.view_additionallinks') != -1 || isManagerOrNoGroup) {
        items.push({
          label: 'Additional Links',
          key: 'links',
          children: <AdditionalLinks community_id={parseInt(communityId)} />,
          style: {
            padding: 0,
          },
          disabled: communityInfo === null,
        });
      }
      if (permissions?.indexOf('PAScreens.view_occupancies') != -1 || isManagerOrNoGroup) {
        items.push({
          label: 'Occupancies',
          key: 'occupancies',
          children: <Occupancies communityId={communityId} />,
          style: {
            padding: 0,
          },

          disabled: communityInfo === null,
        });
      }
      if (permissions?.indexOf('PAScreens.view_screenshopnotes') != -1 || isManagerOrNoGroup) {
        items.push({
          label: 'Shop Notes',
          key: 'shop_notes',
          children: <ShopNotes community_id={parseInt(communityId)} />,
          style: {
            padding: 0,
          },
          disabled: communityInfo === null,
        });
      }
      if (permissions?.indexOf('PAScreens.view_screencalculator') != -1 || isManagerOrNoGroup) {
        items.push({
          label: 'Calculator',
          key: 'calculator',
          children: <Calculator communityInfo={communityInfo} />,
          style: {
            padding: 0,
          },
          disabled: communityInfo === null,
        });
      }
      if (permissions?.indexOf('PAScreens.add_screenrentroll') != -1 || isManagerOrNoGroup) {
        items.push({
          label: 'Rent Roll',
          key: 'rent-role',
          children: <RentRoll community_id={parseInt(communityId)} communityInfo={communityInfo} />,
          style: {
            padding: 0,
          },
          disabled: communityInfo === null,
        });
      }
      if (permissions?.indexOf('PAScreens.view_screens_cma_summary') != -1 || isManagerOrNoGroup) {
        items.push({
          label: 'CMA Summary',
          key: 'cma_summary',
          children: <CMASummary community_id={parseInt(communityId)} communityInfo={communityInfo} />,
          style: {
            padding: 0,
          },
          disabled: communityInfo === null,
        });
      }

      return items;
    }
    return [];
  };

  React.useEffect(() => {
    dispatch(handleClearCommunityData());
    return () => {
      dispatch(handleClearCommunityData());
    };
  }, []);

  const state = useQuery({
    queryKey: `Get_HomeCommunityCompetitors_${communityId}`,
    queryFn: () =>
      axios.get(`/communities/Get_HomeCommunityCompetitors/${communityId}`).then((res) => res.data?.payload),
    onSuccess: (data) => {
      const community_data = {
        ...data?.CommunityInfo[0][0],
        livingInfo: data?.CommunityInfo[1]?.Details?.LivingInfo,
        logo: data?.CompanyLogo,
      };
      setCommunityInfo(community_data);
      const competitor_data: any = {};
      if (data?.CompetitorInfo) {
        Object.keys(data?.CompetitorInfo).forEach((key) => {
          competitor_data[key] = {
            ...data?.CompetitorInfo[key][0],
            livingInfo: data?.CompetitorInfo[key][1]?.Details?.LivingInfo,
          };
        });
      }
      setCompetitorInfo(competitor_data);
    },
    refetchOnWindowFocus: false,
  });

  const [activeTabKey, setActiveTabKey] = useState('Comparison');
  useEffect(() => {
    if (competitor_id) {
      setActiveTabKey('Competitors');
    }
  }, [competitor_id]);

  useEffect(() => {
    state.refetch();
  }, [toggleRefresh]);

  return (
    <div
      style={{
        padding: '20px 30px 0px',
      }}
    >
      <div>
        <div style={{ marginBottom: '20px' }}>
          <span className={classes.page_heading}>
            <span style={{ color: '#1f58b0', cursor: 'pointer' }} onClick={() => navigate('/')}>
              HOME
            </span>
            <span
              style={{
                color: '#222',
                margin: '0px 10px',
              }}
            >
              {communityInfo?.comunity_name}
            </span>
            {communityInfo?.address}
          </span>
          {communityInfo?.export_pdf && activeTabKey == 'Comparison' ? (
            <span style={{ float: 'right' }}>
              <Tooltip title="Export to PDF" placement="topRight">
                <Button
                  type="text"
                  icon={
                    <svg
                      enableBackground="new 0 0 334.371 380.563"
                      version="1.1"
                      viewBox="0 0 14 16"
                      xmlSpace="preserve"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ width: '100%', height: 25 }}
                    >
                      <g transform="matrix(.04589 0 0 .04589 -.66877 -.73379)">
                        <polygon
                          points="51.791 356.65 51.791 23.99 204.5 23.99 282.65 102.07 282.65 356.65"
                          fill="#fff"
                          strokeWidth="212.65"
                        />
                        <path
                          d="m201.19 31.99 73.46 73.393v243.26h-214.86v-316.66h141.4m6.623-16h-164.02v348.66h246.85v-265.9z"
                          strokeWidth="21.791"
                        />
                      </g>
                      <g transform="matrix(.04589 0 0 .04589 -.66877 -.73379)">
                        <polygon
                          points="282.65 356.65 51.791 356.65 51.791 23.99 204.5 23.99 206.31 25.8 206.31 100.33 280.9 100.33 282.65 102.07"
                          fill="#fff"
                          strokeWidth="212.65"
                        />
                        <path
                          d="m198.31 31.99v76.337h76.337v240.32h-214.86v-316.66h138.52m9.5-16h-164.02v348.66h246.85v-265.9l-6.43-6.424h-69.907v-69.842z"
                          strokeWidth="21.791"
                        />
                      </g>
                      <g transform="matrix(.04589 0 0 .04589 -.66877 -.73379)" strokeWidth="21.791">
                        <polygon points="258.31 87.75 219.64 87.75 219.64 48.667 258.31 86.38" />
                        <path d="m227.64 67.646 12.41 12.104h-12.41v-12.104m-5.002-27.229h-10.998v55.333h54.666v-12.742z" />
                      </g>
                      <g transform="matrix(.04589 0 0 .04589 -.66877 -.73379)" fill="#ed1c24" strokeWidth="212.65">
                        <polygon points="311.89 284.49 22.544 284.49 22.544 167.68 37.291 152.94 37.291 171.49 297.15 171.49 297.15 152.94 311.89 167.68" />
                        <path d="m303.65 168.63 1.747 1.747v107.62h-276.35v-107.62l1.747-1.747v9.362h272.85v-9.362m-12.999-31.385v27.747h-246.86v-27.747l-27.747 27.747v126h302.35v-126z" />
                      </g>
                      <rect x="1.7219" y="7.9544" width="10.684" height="4.0307" fill="none" />
                      <g transform="matrix(.04589 0 0 .04589 1.7219 11.733)" fill="#fff" strokeWidth="21.791">
                        <path d="m9.216 0v-83.2h30.464q6.784 0 12.928 1.408 6.144 1.28 10.752 4.608 4.608 3.2 7.296 8.576 2.816 5.248 2.816 13.056 0 7.68-2.816 13.184-2.688 5.504-7.296 9.088-4.608 3.456-10.624 5.248-6.016 1.664-12.544 1.664h-8.96v26.368zm22.016-43.776h7.936q6.528 0 9.6-3.072 3.2-3.072 3.2-8.704t-3.456-7.936-9.856-2.304h-7.424z" />
                        <path d="m87.04 0v-83.2h24.576q9.472 0 17.28 2.304 7.936 2.304 13.568 7.296t8.704 12.8q3.2 7.808 3.2 18.816t-3.072 18.944-8.704 13.056q-5.504 5.12-13.184 7.552-7.552 2.432-16.512 2.432zm22.016-17.664h1.28q4.48 0 8.448-1.024 3.968-1.152 6.784-3.84 2.944-2.688 4.608-7.424t1.664-12.032-1.664-11.904-4.608-7.168q-2.816-2.56-6.784-3.456-3.968-1.024-8.448-1.024h-1.28z" />
                        <path d="m169.22 0v-83.2h54.272v18.432h-32.256v15.872h27.648v18.432h-27.648v30.464z" />
                      </g>
                    </svg>
                  }
                  style={{ marginLeft: 5 }}
                  onClick={() => setOpenPdfModal(true)}
                />
              </Tooltip>
            </span>
          ) : null}
        </div>
        <Row gutter={[16, 10]}>
          <Col
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '15px',
            }}
            lg={19}
            xl={19}
          >
            <span className={classes.community_name} style={{}}>
              {communityInfo?.comunity_name}{' '}
              {communityInfo && (
                <a
                  target="_blank"
                  href={`https://www.google.com/maps/search/?api=1&query=${communityInfo.address}&query_place_id=<placeId>${communityInfo.google_map_location}`}
                  style={{
                    position: 'absolute',
                  }}
                  rel="noreferrer"
                >
                  <img src="/images/world-icon.png" height="25" width="auto" style={{ translate: '3px -20px' }} />
                </a>
              )}
            </span>
          </Col>
          <Col
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              marginBottom: '15px',
            }}
            lg={5}
            xl={5}
          >
            {(permissions?.indexOf('PAScreens.can_view_swot_analysis') != -1 || currentUser?.isSuperuser) && (
              <div className={classes.survey_btn} onClick={() => navigate(`/swot-survey?cid=${communityId}`)}>
                SWOT Analysis
              </div>
            )}
          </Col>
        </Row>
      </div>
      <Tabs
        activeKey={activeTabKey}
        moreIcon={<CaretRightFilled />}
        tabPosition="top"
        tabBarGutter={30}
        size="small"
        destroyInactiveTabPane
        onChange={(key: any) => setActiveTabKey(key)}
        items={protectedTabs()}
      />
    </div>
  );
};

export default PriceHealing;
