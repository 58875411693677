import React from 'react';

import SidebarSelectorDropdownFilterButton from './SidebarSelectorDropdownFilterButton';
import { SELECTOR_ITEM_TYPES } from '../constants';

const SidebarSelectorDropdownControlsAdmin = () => (
  <>
    <SidebarSelectorDropdownFilterButton type={SELECTOR_ITEM_TYPES.PRIMARY_USER} />
    <SidebarSelectorDropdownFilterButton type={SELECTOR_ITEM_TYPES.USER} />
  </>
);

export default SidebarSelectorDropdownControlsAdmin;
