import React from 'react';

import styled from 'styled-components';

import PhotosPreviewCarousal from './PhotosPreviewCarousal';
import PhotosPreviewThumbnails from './PhotosPreviewThumbnails';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
`;

const PhotosPreviewContent = () => {
  return (
    <Container>
      <PhotosPreviewCarousal />
      <PhotosPreviewThumbnails />
    </Container>
  );
};

export default PhotosPreviewContent;
