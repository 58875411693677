import React from 'react';

import classNames from 'classnames';
import styled from 'styled-components';

import { usePhotosContext } from './PhotosContext';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const ThumbnailWrapper = styled.div`
  border-radius: 4px;
  height: 40px;
  width: 40px;
  overflow: hidden;
  cursor: pointer;

  &.selected {
    border: 3px solid var(--white);
    border-radius: 6px;
  }
`;

const PhotosPreviewThumbnails = () => {
  const { photos, selectedPhotoIndex, setSelectedPhotoIndex } = usePhotosContext();
  return (
    <Container>
      {photos.map((item: any, index: number) => (
        <ThumbnailWrapper
          key={item.id}
          className={classNames({ selected: index === selectedPhotoIndex })}
          onClick={() => setSelectedPhotoIndex(index)}
        >
          <img src={item.image_url} alt="img" height="40px" width="40px" />
        </ThumbnailWrapper>
      ))}
    </Container>
  );
};

export default PhotosPreviewThumbnails;
