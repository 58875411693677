import React from 'react';

import classNames from 'classnames';
import styled from 'styled-components';

import { CompanyListItemType } from './types';
import { COMPANY_STATUS, COMPANY_STATUS_LABEL } from '../constants';

const Container = styled.span`
  display: flex;
  margin: 2px 0px 10px 0px;
`;

const Status = styled.span`
  font-family: var(--font-regular);
  font-size: 14px;
  color: var(--text-secondary);

  &.active {
    color: var(--positive-green);
  }

  &.inActive {
    color: var(--inactive-orange);
  }
`;

interface Props {
  item: CompanyListItemType;
}

const CompaniesTableStatus = ({ item }: Props) => {
  const active = item.status == COMPANY_STATUS.IN_TRIAL || item.status == COMPANY_STATUS.PAYING_CUSTOMER;
  const inActive = item.status == COMPANY_STATUS.INACTIVE;

  return (
    <Container>
      <Status
        className={classNames({
          active: active,
          inActive: inActive,
        })}
      >
        {COMPANY_STATUS_LABEL[item?.status]}
      </Status>
    </Container>
  );
};

export default CompaniesTableStatus;
