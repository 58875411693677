import React, { useEffect } from 'react';
import { useQuery } from 'react-query';

import { format } from 'date-fns';
import styled from 'styled-components';

import { getIncentives } from 'apis/CommunityAPI';

import Incentives from './Incentives';
import { useCommunityViewContext } from '../../../components/View/CommunityViewContext';
import NoDataInfo from '../Widget/NoDataContent';
import { useWidgetContext } from '../Widget/WidgetContext';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: start;
  height: 100%;
  overflow-y: auto;
`;

export const IncentivesWidgetContent = () => {
  const { communityId } = useCommunityViewContext();
  const { filters = { time: undefined, competitors: [], careTypes: [] }, setCSVData } = useWidgetContext();
  const { time, competitors, careTypes } = filters;

  const params = {
    time,
    status: 1,
    competitors: competitors?.length > 0 ? competitors : null,
    care_types: careTypes?.length > 0 ? careTypes : null,
  };

  const { data, isLoading } = useQuery({
    queryKey: ['community', 'incentive', communityId, params],
    queryFn: () => getIncentives(communityId, params),
    refetchOnWindowFocus: false,
    enabled: Boolean(communityId),
  });

  useEffect(() => {
    if (data) {
      const communitiesData = (data || []).map((item: any) => [
        item.community_name,
        item.description,
        format(new Date(item.date), 'MMM dd, yyyy'),
      ]);
      const exportData = [['COMMUNITY', 'INCENTIVE', 'DATE'], ...communitiesData];
      setCSVData(exportData);
    }
  }, [data]);

  const hasData = data?.length > 0;

  return (
    <Container className="mini-scroll">
      {!isLoading && !hasData && <NoDataInfo>No Data Available</NoDataInfo>}
      {!isLoading && hasData && <Incentives data={data} />}
    </Container>
  );
};

export default IncentivesWidgetContent;
