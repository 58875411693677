import React from 'react';

import styled from 'styled-components';

import CareTypeFilter from 'common/Filters/CareTypeFilter';
import CompetitorsFilter from 'common/Filters/CompetitorsFilter';
import ReviewSourceFilter from 'common/Filters/ReviewSourceFilter';
import RoomTypeFilter from 'common/Filters/RoomTypeFilter';
import { useAppSelector } from 'store/hook';

import { useCMASummaryContext } from './CMASummaryContext';
import CMASummaryFilterControls from './CMASummaryFilterControls';
import CMASummaryGroupAggregationFilter from './CMASummaryGroupAggregationFilter';
import CMASummaryRoomComparisonTypeFilter from './filters/CMASummaryRoomComparisonTypeFilter';
import CMASummaryViewFilter from './filters/CMASummaryViewFilter';

const Container = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: start;
  gap: 5px;
`;

const CMASummaryFilters = () => {
  const { filters, livingInfo, setFilters } = useCMASummaryContext();
  const { selectedCommunity, communityCompetitorsList } = useAppSelector((state) => state.global);

  const roomTypeFilterOptions = livingInfo?.length
    ? livingInfo
        .flatMap((communityLivingInfo: any) =>
          communityLivingInfo.flatMap((livingInfoItem: any) => ({
            label: livingInfoItem.apartmentType,
            value: livingInfoItem.apartmentTypeId,
          }))
        )
        // remove duplicates
        .filter((item, index, self) => index === self.findIndex((t) => t.value === item.value))
    : [];

  const originalCompetitors =
    selectedCommunity && communityCompetitorsList.length
      ? communityCompetitorsList.filter((competitor) => competitor.parent_id === selectedCommunity.id)
      : [];

  const competitorsFilterOptions = originalCompetitors?.map((item) => ({
    label: item.apartmentname,
    value: item.id,
    color: item.color,
  }));

  return (
    <Container>
      <CMASummaryGroupAggregationFilter />
      <CMASummaryViewFilter />
      <CMASummaryRoomComparisonTypeFilter />
      <ReviewSourceFilter reviewSources={filters.reviewSources} setFilters={setFilters} />
      {originalCompetitors.length > 1 && (
        <CompetitorsFilter
          competitors={filters.competitors || []}
          options={competitorsFilterOptions}
          setFilters={setFilters}
        />
      )}
      <CareTypeFilter careTypes={filters.careTypes || []} setFilters={setFilters} />
      <RoomTypeFilter roomTypes={filters.roomTypes || []} options={roomTypeFilterOptions} setFilters={setFilters} />
      <CMASummaryFilterControls />
    </Container>
  );
};

export default CMASummaryFilters;
