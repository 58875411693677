import React from 'react';

import SelectFilter from 'components/lib/SelectFilter';

import { useBrochureAndDocsContext } from './BrochureAndDocsContext';
import { BROCHURE_AND_DOCS_FILTER_OPTIONS } from './constants';

const BrochureAndDocsFilter = () => {
  const { setSelectedCategory } = useBrochureAndDocsContext();

  const handleChange = (value: string) => {
    setSelectedCategory(value);
  };

  return (
    <SelectFilter
      placeholder="View"
      options={BROCHURE_AND_DOCS_FILTER_OPTIONS}
      variant="borderless"
      onChange={handleChange}
    />
  );
};

export default BrochureAndDocsFilter;
