import React from 'react';

import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import { usePhotosContext } from './PhotosContext';
import PhotosPreviewCarousalController from './PhotosPreviewCarousalController';
import PhotosPreviewCarousalDisplay from './PhotosPreviewCarousalDisplay';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  user-select: none;
`;

const PhotosPreviewCarousal = () => {
  const { photos, selectedPhotoIndex, setSelectedPhotoIndex } = usePhotosContext();

  const handlePrev = () => {
    const value = selectedPhotoIndex > 0 ? selectedPhotoIndex - 1 : photos.length - 1;
    setSelectedPhotoIndex(value);
  };

  const handleNext = () => {
    const value = selectedPhotoIndex < photos.length - 1 ? selectedPhotoIndex + 1 : 0;
    setSelectedPhotoIndex(value);
  };

  return (
    <Container>
      <PhotosPreviewCarousalController onClick={handlePrev} icon={<LeftOutlined />} />
      <PhotosPreviewCarousalDisplay />
      <PhotosPreviewCarousalController onClick={handleNext} icon={<RightOutlined />} />
    </Container>
  );
};

export default PhotosPreviewCarousal;
