import React from 'react';

import SidebarSelectorDropdownFilterButton from './SidebarSelectorDropdownFilterButton';
import { useAppSelector } from '../../../../store/hook';
import { SELECTOR_ITEM_TYPES } from '../constants';

const SidebarSelectorDropdownControlsUser = () => {
  const { managementCompanyList, communityList, communityCompetitorsList } = useAppSelector((state) => state.global);

  const companiesCount = managementCompanyList.length;
  const comunitiesCount = communityList.length;
  const competitorsCount = communityCompetitorsList.length;

  return (
    <>
      <SidebarSelectorDropdownFilterButton type={SELECTOR_ITEM_TYPES.ALL} />
      <SidebarSelectorDropdownFilterButton type={SELECTOR_ITEM_TYPES.COMMUNITY} count={comunitiesCount} />
      <SidebarSelectorDropdownFilterButton type={SELECTOR_ITEM_TYPES.MANAGEMENT_COMPANY} count={companiesCount} />
      <SidebarSelectorDropdownFilterButton type={SELECTOR_ITEM_TYPES.COMPETITOR} count={competitorsCount} />
    </>
  );
};
export default SidebarSelectorDropdownControlsUser;
