import React from 'react';

import { Form, FormListFieldData } from 'antd';
import styled from 'styled-components';

import { useCMASummaryFilterContext } from './CMASummaryFilterContext';
import CMASummaryRoomComparisonTypeFilterAddField from './CMASummaryRoomComparisonTypeFilterAddField';
import CMASummaryRoomComparisonTypeFilterField from './CMASummaryRoomComparisonTypeFilterField';
import {
  CMA_SUMMARY_ROOM_COMPARISON_MINIMUM_FILTER_FIELDS,
  CMA_SUMMARY_ROOM_DATA_FILTER_TYPES,
  ROOM_COMPARISON_ROWS,
} from '../constants';
import { filterRemainingCMASummaryRoomComparisonFields } from '../filterUtils';

const RowTitle = styled.div`
  font-family: var(--font-bold);
  font-size: 14px;
  margin-bottom: 10px;
`;

const Row = styled.div`
  margin-bottom: 15px;
`;

const RemoveRow = styled.div`
  color: var(--link-blue);
  font-size: 14px;
  cursor: pointer;
`;

const RowHeader = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
`;

const ErrorMessage = styled.div`
  color: var(--error-red);
`;

type Props = {
  row: FormListFieldData;
  rowIndex: number;
  removeRow: (index: number | number[]) => void;
};

const CMASummaryRoomComparisonFilterFormListRow = ({ row, rowIndex, removeRow }: Props) => {
  const { form } = useCMASummaryFilterContext();
  const roomComparisonRows = Form.useWatch(ROOM_COMPARISON_ROWS, form);
  const allowAddingFields =
    filterRemainingCMASummaryRoomComparisonFields(roomComparisonRows ? roomComparisonRows[rowIndex] : []).length > 0;

  return (
    <Row key={`${row.name}${rowIndex}`}>
      <RowHeader>
        <RowTitle>Additional Row {rowIndex + 1}</RowTitle>
        {roomComparisonRows?.length > 1 && <RemoveRow onClick={() => removeRow(rowIndex)}>Remove Row</RemoveRow>}
      </RowHeader>
      <Form.List
        name={[row.name]}
        rules={[
          {
            validator: async (_, fields) => {
              if (!fields || fields.length < CMA_SUMMARY_ROOM_COMPARISON_MINIMUM_FILTER_FIELDS) {
                return Promise.reject(new Error(`Row must contain at least two fields.`));
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        {(fields, subOpt, { errors }) => (
          <>
            <div style={{ display: 'flex', flexDirection: 'column', rowGap: 10 }}>
              {fields?.map((subField) => (
                <CMASummaryRoomComparisonTypeFilterField
                  key={subField.key}
                  subField={subField}
                  rowIndex={rowIndex}
                  subOpt={subOpt}
                />
              ))}
              {allowAddingFields && (
                <CMASummaryRoomComparisonTypeFilterAddField
                  rowIndex={rowIndex}
                  onClick={(fieldName: string) =>
                    subOpt.add({
                      name: fieldName,
                      fieldType: CMA_SUMMARY_ROOM_DATA_FILTER_TYPES.AVG.id,
                    })
                  }
                />
              )}
            </div>
            {errors && (
              <div>
                {errors.map((e, index) => (
                  <ErrorMessage key={index}>{e}</ErrorMessage>
                ))}
              </div>
            )}
          </>
        )}
      </Form.List>
    </Row>
  );
};

export default CMASummaryRoomComparisonFilterFormListRow;
