import React from 'react';

import { SelectProps } from 'antd';
import styled from 'styled-components';

import Select from 'components/lib/Select';

const StyledSelect = styled(Select)`
  &.ant-select {
    font-family: var(--font-regular);
    font-size: 12px;
    height: 28px;
    color: inherit;
    color: var(--deep-blue);
    background-color: var(--lighter-gray);
    border-radius: 4px;

    &.active {
      color: var(--dark-blue);
      background-color: var(--faded-blue);
    }

    &.ant-select-focused {
      color: var(--dark-blue);
      background-color: var(--faded-blue);
      border: 1px solid var(--button-blue);
    }

    .ant-select-arrow {
      color: inherit;
      width: 16px;
      height: 16px;
      top: 12px;
    }
    .ant-select-arrow .anticon {
      width: 8px;
      height: 4.9px;
      margin: auto;
    }
    .ant-select-selector {
      color: inherit;
      .ant-select-selection-placeholder {
        color: inherit;
        font-size: 12px;
      }
      .ant-select-selection-item {
        color: inherit;
        font-size: 12px;
      }
    }
  }

  .ant-select-dropdown {
    padding: 0;
  }
`;

const CMASummarySelect = (props: SelectProps) => {
  return (
    <StyledSelect
      variant={'borderless'}
      dropdownStyle={{
        padding: 0,
        borderRadius: 0,
        border: '1px solid var(--line-gray)',
        minWidth: 370,
      }}
      {...props}
    />
  );
};

export default CMASummarySelect;
