import React from 'react';

import {
  INCENTIVES_FEES_TYPE,
  INCENTIVES_SAVINGS_DISCOUNT_TYPE,
  INCENTIVES_SAVINGS_DURATION_TYPE_FACTOR,
} from './create/constants';
import { IncentiveSavingType, UnitType } from './types';
import { SORT_ORDER } from '../../constants';

export const calculateTotalSavings = (savings: IncentiveSavingType[]) => {
  const amountSavings = savings.filter(
    (item: IncentiveSavingType) => item.discount_type === INCENTIVES_SAVINGS_DISCOUNT_TYPE.AMOUNT
  );

  if (amountSavings.length) {
    return amountSavings
      .filter((item: IncentiveSavingType) => Boolean(item.discount))
      .reduce((sum, current) => {
        const discountDurationFactor = INCENTIVES_SAVINGS_DURATION_TYPE_FACTOR[current.discount_duration_type!] ?? 1;
        const discountDuration = (current?.discount_duration ?? 1) * discountDurationFactor;
        return sum + (current.discount ?? 0) * discountDuration;
      }, 0);
  }

  return 0;
};

export const getUnitFeePrice = (unit: UnitType, feeType: string | undefined) => {
  const unitPricing = unit?.community?.pricing;

  switch (feeType) {
    case INCENTIVES_FEES_TYPE.MONTHLY_RENT:
      return unit?.market_rate ?? 0;
    case INCENTIVES_FEES_TYPE.COMMUNITY_FEE:
      if (unitPricing?.community_fee_equivalent_factor) {
        return Number(unit?.market_rate) * (unitPricing?.community_fee_equivalent_factor ?? 1);
      }

      return unitPricing?.community_fee ?? 0;
    case INCENTIVES_FEES_TYPE.MOVE_IN_FEE:
      return unit?.community?.pricing?.move_in_fee ?? 0;
    case INCENTIVES_FEES_TYPE.PET_FEE:
      return unitPricing?.pet_fee ?? 0;
    case INCENTIVES_FEES_TYPE.PARKING_FEE:
      return unitPricing?.parking_fee ?? 0;
    case INCENTIVES_FEES_TYPE.DEPOSIT:
      if (unitPricing?.deposit_equivalent_factor) {
        return Number(unit?.market_rate) * (unitPricing?.deposit_equivalent_factor ?? 1);
      }
      return unit?.community?.pricing?.deposit ?? 0;
    case INCENTIVES_FEES_TYPE.SECOND_PERSON_FEE:
      return unit?.community?.pricing?.second_person_fee ?? 0;
    case INCENTIVES_FEES_TYPE.ADDITIONAL_FEE:
      return unit?.community?.pricing?.additional_fee ?? 0;
  }

  return 0;
};

export const getOrdering = (sorter: any) => {
  if (!sorter.order && !sorter.columnKey) return undefined;

  return sorter.order == SORT_ORDER.ASCEND ? sorter?.columnKey : `-${sorter.columnKey}`;
};
