import React from 'react';

import styled from 'styled-components';

import { useCMASummaryContext } from './CMASummaryContext';
import CMASummaryTableDataValuesDifference from './CMASummaryTableDataValuesDifference';
import { parseCMASummaryFeeData } from './utils';

const Title = styled.div`
  font-family: var(--font-regular);
  font-size: 14px;
`;

type Props = {
  careFeeLevel: number;
};

const CMASummaryTableDataCareFees = ({ careFeeLevel }: Props) => {
  const { filters } = useCMASummaryContext();
  const [careFeeLevelValue, feeLevelDifference] = parseCMASummaryFeeData(
    careFeeLevel,
    filters.viewOptions?.valueDisplay
  );

  return (
    <div>
      {typeof careFeeLevelValue == 'number' && (
        <Title>
          ${careFeeLevelValue.toLocaleString()} <CMASummaryTableDataValuesDifference difference={feeLevelDifference} />
        </Title>
      )}
      {typeof careFeeLevelValue != 'number' && '-'}
    </div>
  );
};

export default CMASummaryTableDataCareFees;
