import React from 'react';

import { Form, FormListFieldData } from 'antd';
import styled from 'styled-components';

import CMASummaryRoomComparisonFilterAddRowButton from './CMASummaryRoomComparisonFilterAddRowButton';
import CMASummaryRoomComparisonFilterFormListRow from './CMASummaryRoomComparisonFilterFormListRow';

const Rows = styled.div`
  padding-left: 44px;
  padding-right: 20px;
  max-height: 400px;
  overflow-y: auto;
`;

const CMASummaryRoomComparisonTypeFilterFormList = () => (
  <Form.List name="roomComparisonRows">
    {(rows, { add: addRow, remove: removeRow }) => (
      <>
        {Boolean(rows.length) && (
          <Rows>
            {rows?.map((row: FormListFieldData, rowIndex: number) => (
              <CMASummaryRoomComparisonFilterFormListRow
                key={row.key}
                row={row}
                rowIndex={rowIndex}
                removeRow={removeRow}
              />
            ))}
          </Rows>
        )}
        <CMASummaryRoomComparisonFilterAddRowButton addRow={addRow} />
      </>
    )}
  </Form.List>
);

export default CMASummaryRoomComparisonTypeFilterFormList;
