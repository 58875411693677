import React from 'react';

import styled from 'styled-components';

import { useCMASummaryContext } from '../CMASummaryContext';
import {
  CMA_SUMMARY_ROOM_COMPARISON_TYPE_FILTER_LABELS,
  CMA_SUMMARY_ROOM_COMPARISON_TYPE_FILTER_OPTIONS,
} from '../constants';

const Label = styled.span<{ fontFamily: string }>`
  font-family: ${({ fontFamily }) => fontFamily};
`;

const CMA_SUMMARY_ROOM_COMPARISON_TYPE_FILTER_NAME = 'Room Comparison Type';

const CMASummaryRoomComparisonTypeFilterSelectLabel = () => {
  const { filters } = useCMASummaryContext();
  const roomComparisonTypeSelected =
    Boolean(filters.roomComparisonType) &&
    filters.roomComparisonType === CMA_SUMMARY_ROOM_COMPARISON_TYPE_FILTER_OPTIONS.ADVANCED_COMPARISON;

  const placeholderInfo = roomComparisonTypeSelected
    ? `${CMA_SUMMARY_ROOM_COMPARISON_TYPE_FILTER_NAME}:`
    : CMA_SUMMARY_ROOM_COMPARISON_TYPE_FILTER_NAME;

  return (
    <>
      <Label fontFamily={roomComparisonTypeSelected ? 'var(--font-bold)' : 'var(--font-medium)'}>
        {placeholderInfo}{' '}
      </Label>
      {roomComparisonTypeSelected &&
        filters &&
        CMA_SUMMARY_ROOM_COMPARISON_TYPE_FILTER_LABELS[`${filters.roomComparisonType as number}`]}
    </>
  );
};

export default CMASummaryRoomComparisonTypeFilterSelectLabel;
