import React, { ReactNode, useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import { uuid } from 'short-uuid';
import styled from 'styled-components';

import { getFirstAndLastName } from './helpers';
import { useIncentivesProposalModalContext } from './IncentivesProposalModalContext';
import { IncentiveUnitProspectType } from './types';
import { searchProspects } from '../../../apis/IncentiveAPI';
import { useDebounce } from '../../../utils/hooks/useDebounce';
import Select from '../../lib/Select';
import { useIncentivesContext } from '../IncentivesContext';

const StyledSelect = styled(Select)`
  font-family: var(--font-regular);
  width: 100%;
  border: 1px solid var(--line-gray);
  border-radius: 0;
  color: var(--text-secondary);

  &.ant-select-disabled {
    border-color: var(--line-gray);
    opacity: 0.8;
  }

  .ant-select-arrow {
    left: 10px;
    color: var(--text-secondary);
  }

  &:hover {
    .ant-select-arrow {
      opacity: 1;
    }
  }

  &.ant-select-allow-clear {
    &:hover {
      .ant-select-arrow {
        opacity: 1;
      }
    }
  }

  &.ant-select-single.ant-select-lg {
    .ant-select-selector {
      border-radius: 0;
      font-size: 14px;
      padding-left: 38px;
      .ant-select-selection-search {
        padding-left: 26px;
      }
    }
  }

  .ant-select-selection-placeholder {
    color: var(--text-secondary);
  }

  .ant-select-selector {
    background-color: green;
  }
`;

const Container = styled.div`
  position: relative;
  display: flex;
  padding: 10px 0px;
`;

const ItemContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 2px;
  height: 35px;
`;

const ItemName = styled.div`
  display: flex;
  font-family: var(--font-bold);
  font-size: 14px;
  color: var(--text-primary);
`;

const ItemContact = styled.div`
  display: flex;
  font-size: 12px;
  color: var(--text-secondary);
`;

const StyledDropdownMenu = styled.div`
  .ant-select-item {
    padding: 12px;
    border-radius: 0;

    :not(:first-child) {
      border-top: 1px solid var(--line-gray);
    }

    &.ant-select-item-option-active {
      background-color: var(--button-blue);

      ${ItemName} {
        color: white;
      }

      ${ItemContact} {
        color: white;
        opacity: 0.6;
      }
    }
  }
`;

const IncentivesProposalModalProposalLeadSearch = () => {
  const { selectedProposalUnit } = useIncentivesContext();
  const { readOnly } = useIncentivesProposalModalContext();
  const [prospect, setProspect] = useState<IncentiveUnitProspectType | undefined>(undefined);
  const formInstance = Form.useFormInstance();

  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(query);

  const handleSelect = (value: string, option: any) => {
    setProspect(option);
  };

  const handleClear = () => {
    setProspect(undefined);
  };

  useEffect(() => {
    const { firstName, lastName } = getFirstAndLastName(prospect?.name ?? '');
    const payload = {
      prospect_id: prospect?.prospect_id ?? undefined,
      first_name: firstName,
      last_name: lastName,
      email: prospect?.email ?? '',
      phone: prospect?.preferred_phone ?? '',
    };

    formInstance.setFieldValue('lead', payload);

    // Only perform validation if any of the fields was populated
    if (prospect) {
      formInstance.validateFields();
    }
  }, [prospect]);

  const { data, isLoading } = useQuery({
    queryKey: ['incentiveProspectSearch', selectedProposalUnit?.community.id, debouncedQuery],
    queryFn: () =>
      searchProspects(selectedProposalUnit?.id || -1, {
        query: debouncedQuery,
      }),
    enabled: Boolean(debouncedQuery),
    refetchOnWindowFocus: false,
  });

  const notFoundContent = debouncedQuery ? (
    <span style={{ display: 'flex', justifyContent: 'center' }}>
      {debouncedQuery && isLoading && <LoadingOutlined />}
      {debouncedQuery && !isLoading && 'No results found'}
    </span>
  ) : null;

  return (
    <Container>
      <StyledSelect
        showSearch
        disabled={readOnly}
        suffixIcon={<SearchOutlined style={{ fontSize: 20, color: 'var(--text-secondary)' }} />}
        showArrow={true}
        allowClear={!!prospect && !isLoading}
        onClear={handleClear}
        onSearch={(value: string) => setQuery(value)}
        onSelect={handleSelect}
        variant={'filled'}
        filterOption={false}
        placeholder={'Search recipient by name, email or phone to populate quickly'}
        options={data?.map((item: IncentiveUnitProspectType) => ({
          ...item,
          key: uuid(),
          label: item.name,
          value: item.name,
        }))}
        dropdownStyle={{
          border: '1px solid var(--line-gray)',
          borderRadius: 0,
          padding: 0,
        }}
        optionRender={(item: any) => (
          <ItemContainer>
            <ItemName>{item.data.name}</ItemName>
            <ItemContact>
              {item.data.email}
              {Boolean(item.data.email) && Boolean(item.data.preferred_phone) && ' · '}
              {item.data.preferred_phone}
            </ItemContact>
          </ItemContainer>
        )}
        notFoundContent={notFoundContent}
        dropdownRender={(menu: ReactNode) => <StyledDropdownMenu>{menu}</StyledDropdownMenu>}
      />
    </Container>
  );
};

export default IncentivesProposalModalProposalLeadSearch;
