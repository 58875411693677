import React, { createContext, useContext } from 'react';

import { COMPANIES_PAGE_SIZE } from './constants';
import { CompaniesFiltersType, CompanyListItemType } from './table/types';

type CompaniesContent = {
  items: CompanyListItemType[];
  totalItemsCount: number;
  filters: Partial<CompaniesFiltersType>;
  setItems: (payload: any) => void;
  setPage: (page: number) => void;
  setFilters: (filters: any) => void;
  resetFilters: () => void;
};

export const CompaniesContext = createContext<CompaniesContent>({
  items: [],
  totalItemsCount: 0,
  filters: {
    page: 1,
    pageSize: COMPANIES_PAGE_SIZE,
    search: '',
    status: [],
  },
  setItems: () => {
    // Do nothing
  },
  setPage: () => {
    // Do nothing
  },
  setFilters: () => {
    // Do nothing
  },
  resetFilters: () => {
    // Do nothing
  },
});

export const useCompaniesContext = () => useContext(CompaniesContext);
