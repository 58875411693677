export const SHOP_REQUESTS_PAGE_SIZE = 10;

export const MYSTERY_SHOP_MODAL_CONTENT = {
  EMPTY: 'EMPTY',
  EDIT_SHOP_REQUEST: 'EDIT_SHOP_REQUEST',
  NEW_SHOP_REQUEST: 'NEW_SHOP_REQUEST',
};

export const MYSTERY_SHOP_MODAL_REQUEST_FROM = {
  FURTHER: 1,
  COMPANY: 2,
};

export const MYSTERY_SHOP_MODAL_REQUEST_FROM_OPTIONS = [
  // { value: 1, label: 'Further' },
  { value: 2, label: 'Company' },
];

export const MYSTERY_SHOP_REQUEST_DUE_DATES = {
  IN_3_DAYS: 'IN_3_DAYS',
  IN_A_WEEK: 'IN_A_WEEK',
  IN_2_WEEKS: 'IN_2_WEEKS',
  SPECIFIC_DATE: 'SPECIFIC_DATE',
};

export const SHOP_REQUEST_REPEAT_OCCURENCES = {
  NEVER: 0,
  EVERY_MONTH: 1,
  EVERY_QUARTER: 2,
  EVERY_6_MONTHS: 3,
  EVERY_YEAR: 4,
};

export const SHOP_REQUEST_REPEAT_OCCURENCES_LABELS: { [key: number]: string } = {
  0: 'Never',
  1: 'Every month',
  2: 'Every quarter',
  3: 'Every 6 months',
  4: 'Every year',
};

export const REPEAT_LABELS = {
  [SHOP_REQUEST_REPEAT_OCCURENCES.NEVER]: 'Ad-hoc',
  [SHOP_REQUEST_REPEAT_OCCURENCES.EVERY_MONTH]: 'Monthly reccuring',
  [SHOP_REQUEST_REPEAT_OCCURENCES.EVERY_QUARTER]: 'Quarterly reccuring',
  [SHOP_REQUEST_REPEAT_OCCURENCES.EVERY_6_MONTHS]: 'Biannually reccuring',
  [SHOP_REQUEST_REPEAT_OCCURENCES.EVERY_YEAR]: 'Yearly reccuring',
};

export const SHOP_REQUEST_DUE_DATE_RANGES = {
  ALL_REQUESTS: 1,
  DUE_THIS_WEEK: 2,
  DUE_NEXT_WEEK: 3,
  DUE_IN_30_DAYS: 4,
  UPCOMING: 5,
  OVERDUE: 6,
};

export const SHOP_REQUEST_STATUS_LABELS = {
  NOT_STARTED: 1,
  IN_PROGRESS: 2,
  WAITING_FOR_REPLY: 3,
  NEEDS_APPROVAL: 4,
  APPROVED: 5,
  CANCELED: 6,
  BLOCKED: 7,
};

export const SHOP_REQUEST_STATUS = [
  { id: SHOP_REQUEST_STATUS_LABELS.NOT_STARTED, label: 'Not Started' },
  { id: SHOP_REQUEST_STATUS_LABELS.IN_PROGRESS, label: 'In Progress' },
  { id: SHOP_REQUEST_STATUS_LABELS.WAITING_FOR_REPLY, label: 'Waiting for Reply' },
  { id: SHOP_REQUEST_STATUS_LABELS.NEEDS_APPROVAL, label: 'Needs Approval' },
  { id: SHOP_REQUEST_STATUS_LABELS.APPROVED, label: 'Approved' },
  { id: SHOP_REQUEST_STATUS_LABELS.CANCELED, label: 'Canceled' },
  { id: SHOP_REQUEST_STATUS_LABELS.BLOCKED, label: 'Blocked' },
];

export const END_REPEAT_VALUES = {
  AFTER_1_YEAR: 1,
  AFTER_2_YEARS: 2,
  AFTER_3_YEARS: 3,
};

export const END_REPEAT: { [key: number]: string } = {
  1: 'After 1 year',
  2: 'After 2 year',
  3: 'After 3 year',
};

export const SHOP_REQUEST_STATS = [
  { value: 1, label: 'Weekly Stats' },
  { value: 2, label: 'Monthly Stats' },
  { value: 3, label: 'Quarterly Stats' },
  { value: 4, label: 'Annual Stats' },
];

export const SHOP_REQUEST_CHECKLIST = [
  {
    id: 'website_review',
    label: 'Website review (floor plans, square footage)',
  },
  {
    id: 'fee_information',
    label: 'Fee Information (Community Fee, 2nd Person Fee, Pet Fee)',
  },
  {
    id: 'apartment_starting_rates',
    label: 'Apartment starting rates by care type',
  },
  {
    id: 'basic_floor_plan_rates',
    label: 'Basic floor plan rates (Shared, Studio, One Bedroom, Two Bedroom, Casita)',
  },
  {
    id: 'floor_plans_sq_footage',
    label: 'Floor plans/Sq. footage information (if unable to get off the website)',
  },
  {
    id: 'care_pricing_type',
    label: 'Care pricing type (all-inclusive, points, levels, etc.)',
  },
  {
    id: 'care_pricing',
    label: 'Care pricing',
    description:
      'if Levels, try to get breakdown of each Level cost. If Points, try to ask approximate cost of care the loved one needs: Medication Help, Dressing, Bathing Assistance, etc.',
  },
  {
    id: 'specials_incentives',
    label: 'Specials/Incentives',
  },
  {
    id: 'typical_rate_increase_annaully',
    label: 'Typical rate increases annually',
  },
  {
    id: 'email_correspondence_application_attachments',
    label: 'Email correspondence & application attachments',
  },
  {
    id: 'respite_fee_by_care_type',
    label: 'Respite fee by care type',
  },
  {
    id: 'rate_documentation',
    label: 'Rate documentation',
    description:
      'E-brochures and pricing sheet/email correspondence with rate information. Need to have documentation verifying the rates they quoted over the phone.',
  },
  {
    id: 'occupancy_data_per_care_level',
    label: 'Occupancy data per care level',
  },
  {
    id: 'sample_menu_activity_calendar',
    label: 'Sample menu/activity calendar (if unable to get off the website)',
  },
];

export const SHOP_REQUEST_VIEW_GROUP_BY = { REQUEST: 1, COMMUNITY: 2 };

export const SHOP_REQUEST_VIEW_FILTER_OPTIONS = [
  { value: SHOP_REQUEST_VIEW_GROUP_BY.REQUEST, label: 'Grouped By Request' },
  { value: SHOP_REQUEST_VIEW_GROUP_BY.COMMUNITY, label: 'Grouped By Base Community' },
];

export const SHOP_REQUEST_COLUMNS = {
  dueDate: {
    id: 'due_date',
    label: 'Due date',
  },
  updatedAt: {
    id: 'updated_at',
    label: 'Date updated',
  },
  status: {
    id: 'status',
    label: 'Status',
  },
  shopper: {
    id: 'shopper',
    label: 'Shopper',
  },
  community: {
    id: 'community',
    label: 'Community',
  },
  requestGroup: {
    id: 'group',
    label: 'Request',
  },
};

export const SHOP_REQUEST_MODAL_STATUS = {
  ADMIN_NOTE: 'ADMIN_NOTE',
  SHOPPER_NOTE: 'SHOPPER_NOTE',
  CLOSED: 'CLOSED',
};
