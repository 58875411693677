import React from 'react';

import { Breadcrumb } from 'antd';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 30px;
`;

const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
`;

const StyledBreadcrumb = styled(Breadcrumb)`
  span {
    color: var(--text-primary);

    &.fixed {
      color: var(--text-secondary);
    }
  }
`;

const TitleContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const TitleIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: var(--competitor-purple);
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 var(--box-shadow-pink);
`;

const Title = styled.span`
  font-family: var(--font-bold);
  font-size: 40px;
  font-weight: 900;
`;

const CompaniesHeader = () => {
  return (
    <Container>
      <HeaderContent>
        <StyledBreadcrumb
          items={[
            {
              title: 'Administration',
            },
          ]}
          separator="·"
        />
        <TitleContainer>
          <TitleIcon>
            <img
              src={'/icons/lock.svg'}
              alt="Companies"
              style={{
                width: 20,
                height: 20,
              }}
            />
          </TitleIcon>
          <Title>Companies</Title>
        </TitleContainer>
      </HeaderContent>
    </Container>
  );
};

export default CompaniesHeader;
