import React, { useState } from 'react';

import styled from 'styled-components';

import CommunityInfoContent from './CommunityInfoContent';
import { CommunityInfoContext } from './CommunityInfoContext';
import CommunityInfoHeader from './CommunityInfoHeader';
import { CommunityDataType } from './types';

const Container = styled.div`
  width: 100%;
  overflow: auto;
`;

const Content = styled.div`
  padding: 0 20px;
  overflow: auto;
  .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-weight: 900;
  }
`;

const CommunityInfo = () => {
  const [communityInfo, setCommunityInfo] = useState<CommunityDataType>();

  return (
    <CommunityInfoContext.Provider
      value={{
        communityInfo,
        setCommunityInfo,
      }}
    >
      <Container>
        <CommunityInfoHeader />
        <Content>
          <CommunityInfoContent />
        </Content>
      </Container>
    </CommunityInfoContext.Provider>
  );
};

export default CommunityInfo;
