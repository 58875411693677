import React from 'react';

import styled from 'styled-components';

import Button from 'components/lib/Button';

const Controls = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-bottom: solid 1px var(--line-gray);
`;

const Link = styled.span`
  cursor: pointer;
  color: var(--active-blue);
  font-size: 12px;
  tab-index: 0;
`;

type Props = {
  onReset: () => void;
};

const CMASummaryFilterDropdownHeader = ({ onReset }: Props) => (
  <Controls>
    <Link onClick={onReset}>Reset</Link>
    <Button type="primary" style={{ width: 96, height: 36 }} htmlType="submit">
      Apply
    </Button>
  </Controls>
);

export default CMASummaryFilterDropdownHeader;
