import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Alert, Button, Form, message } from 'antd';
import { NoticeType } from 'antd/es/message/interface';
import styled from 'styled-components';

import axios from 'config/axiosPublic';

import AuthenticationLayout from './AuthenticationLayout';
import ForgotPasswordChangeNotification from './ForgotPasswordChangeNotification';
import Input from '../../components/lib/Input';

const { Password } = Input;

const FormContainer = styled.div`
  .ant-form-item .ant-form-item-control-input-content {
    flex: none;
  }
`;
const Title = styled.p`
  font-family: var(--font-bold);
  font-size: 60px;
  color: #222222;
  font-size: 60px;
`;

const SubTitle = styled.p`
  font-family: var(--font-regular);
  font-size: 18px;
  color: #222222;
`;

const Container = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 3px;
  width: 530px;
`;

const Label = styled.div`
  font-family: var(--font-bold);
  font-size: 14px
  color: var(--text-primary);
  display: flex;
  justify-content: space-between;
`;

const StyledButton = styled(Button)`
  color: #fff;
  font-family: var(--font-bold);
  font-size: 14px;
  margin-top: 30px;
  width: 530px;
  height: 48px;
  border-radius: 4px;
  background-color: var(--positive-green);

  &.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    border-color: var(--positive-green);
    color: var(--positive-green);
  }
`;

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [step, setStep] = React.useState<number>(1);
  const [loading, setLoading] = React.useState(false);
  const [step1Data, setStep1Data] = React.useState<any>(null);
  const [error, setError] = React.useState<any>({ msg: '', show: false });

  const showMessage = (mgs: string, type: NoticeType | undefined) => {
    messageApi.open({
      type: type,
      content: mgs,
    });
  };

  const onFinish = (values: any) => {
    setLoading(true);
    if (step < 2) {
      const formData = new FormData();
      formData.append('forget', '2');
      formData.append('email', values.email.trim());
      formData.append('code', '');
      formData.append('oldpassword', '');
      formData.append('password', '');
      axios
        .post('/api/auth_changepassword/', formData)
        .then((res) => {
          setStep1Data({
            email: values.email.trim(),
          });
          if (error.show) {
            setError({ show: false, msg: '' });
          }
          setStep(2);
          setLoading(false);
        })
        .catch((err) => {
          const { msg } = err.response?.data;
          setError({ show: true, msg: msg });
          setLoading(false);
        });
    } else {
      const formData = new FormData();
      formData.append('forget', '1');
      formData.append('email', step1Data.email);
      formData.append('code', values.code);
      formData.append('oldpassword', '');
      formData.append('password', values.password);
      axios
        .post('/api/auth_changepassword/', formData)
        .then((res) => {
          showMessage('Password Changed Successfully', 'success');
          setLoading(false);
          setTimeout(() => {
            navigate('/sign-in');
          }, 2000);
        })
        .catch((err) => {
          const { msg } = err.response?.data;
          setError({ show: true, msg: msg });
          setLoading(false);
        });
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <AuthenticationLayout>
      {contextHolder}
      <ForgotPasswordChangeNotification />
      <FormContainer>
        <Title className="mb-15">Forgot password?</Title>
        <SubTitle>
          {step < 2
            ? 'Enter the email associated with your Pricing Assistant account.'
            : 'A Verification Code has been sent to your email address. Kindly check your email.'}
        </SubTitle>
        <Form
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          className="row-col"
          style={{ marginTop: '40px' }}
        >
          {step < 2 ? (
            <div>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: 'Please input your email!',
                  },
                ]}
              >
                <Container>
                  <Label>Email address</Label>
                  <Input style={{ height: '48px' }} />
                </Container>
              </Form.Item>
            </div>
          ) : (
            <div>
              <Form.Item
                name="code"
                rules={[
                  {
                    required: true,
                    message: 'Please input code sent on your email!',
                  },
                ]}
              >
                <Container>
                  <Label>Code</Label>
                  <Input style={{ height: '48px' }} />
                </Container>
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Please input your password!',
                  },
                ]}
              >
                <Container>
                  <Label>New Password</Label>
                  <Password style={{ height: '48px' }} />
                </Container>
              </Form.Item>
              <Form.Item
                name="confirm_password"
                rules={[
                  {
                    required: true,
                    message: 'Please confirm your password!',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('The Password that You Entered do not Match!'));
                    },
                  }),
                ]}
              >
                <Container>
                  <Label>Confirm Password</Label>
                  <Password style={{ height: '48px' }} />
                </Container>
              </Form.Item>{' '}
            </div>
          )}

          <Form.Item style={{ textAlign: 'center' }}>
            {step < 2 ? (
              <StyledButton htmlType="submit" loading={loading}>
                Send Code
              </StyledButton>
            ) : (
              <StyledButton htmlType="submit" loading={loading}>
                Update Password
              </StyledButton>
            )}
          </Form.Item>
        </Form>
        <div>
          <Alert
            message=""
            description={error.msg}
            type="error"
            showIcon={false}
            style={{
              marginTop: '20px',
              fontSize: '18px',
              display: error.show ? 'block' : 'none',
            }}
          />
        </div>
      </FormContainer>
    </AuthenticationLayout>
  );
};

export default ForgotPassword;
