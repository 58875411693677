import React from 'react';

import styled from 'styled-components';

import Select from 'components/lib/Select';

import { useCMASummaryFilterContext } from './CMASummaryFilterContext';
import { ROOM_COMPARISON_ROWS } from '../constants';
import { filterRemainingCMASummaryRoomComparisonFields } from '../filterUtils';

const StyledSelect = styled(Select)`
  &.ant-select {
    width: 148px !important;
    .ant-select-selector {
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
      border: solid 1px #d7d7d7;
      border-radius: 0;
      height: 36px !important;
      font-size: 14px;
    }
  }
`;

type Props = {
  rowIndex: number;
  onClick: (type: string) => void;
};

const CMASummaryRoomComparisonTypeFilterAddField = ({ rowIndex, onClick }: Props) => {
  const { form } = useCMASummaryFilterContext();
  const rows = form?.getFieldValue(ROOM_COMPARISON_ROWS);

  return (
    <StyledSelect
      placeholder={'Select'}
      dropdownStyle={{
        border: '1px solid var(--line-gray)',
        borderRadius: 0,
        padding: 0,
      }}
      value={null}
      onChange={(value: any) => onClick(value)}
      options={filterRemainingCMASummaryRoomComparisonFields(rows[rowIndex]).map((fieldName: string) => ({
        value: fieldName,
        label: fieldName,
      }))}
      onMouseDown={(e) => e.stopPropagation()} //prevent dropdown close after selecting an option
    />
  );
};

export default CMASummaryRoomComparisonTypeFilterAddField;
