import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';

import { Spin } from 'antd';
import styled from 'styled-components';

import Header from './Header';
import { formatUrl } from './utils';
import { getAdditionalLinks } from '../../../apis/CommunityAPI';

const Container = styled.div`
  box-sizing: border-box;
  position: relative;
`;

const SpinWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  padding: 20px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
`;

const Label = styled.p`
  color: var(--text-primary);
  font-family: var(--font-bold);
  width: 50%;
`;

const Link = styled.a`
  color: var(--link-blue);
  font-size: 14px;
  font-family: var(--font-regular);
  width: 50%;
  text-align: right;
`;
const NoData = styled.p`
  color: var(--text-secondary);
  font-family: var(--font-bold);
  font-size: 16px;
  text-align: center;
  align-content: center;
  margin: 30px 0;
`;

const AdditionalLinks = () => {
  const { competitorId } = useParams();
  const { communityId } = useParams();
  const id = Number(competitorId || communityId);

  const { data, isLoading } = useQuery({
    queryKey: ['community', 'additional', 'links', id],
    queryFn: () => getAdditionalLinks(id),
    enabled: Boolean(id),
  });

  const noData = data && data.length == 0;

  return (
    <Container>
      <Header title="Additional Links" />
      {isLoading && (
        <SpinWrapper>
          <Spin />
        </SpinWrapper>
      )}
      {data && (
        <Content>
          {data.map((item: any) => (
            <Row key={item.id}>
              <Label>{item.description}</Label>
              <Link href={formatUrl(item?.page_url)} target="_blank" rel="noopener noreferrer">
                {item?.page_url}
              </Link>
            </Row>
          ))}
          {noData && <NoData>No Data Available</NoData>}
        </Content>
      )}
    </Container>
  );
};

export default AdditionalLinks;
