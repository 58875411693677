import React, { useState } from 'react';

import FiltersControls from 'common/FiltersControls/FiltersControls';
import CMASummarySaveFilterModal from 'components/View/modals/CMASummarySaveFilterModal';

import { useCMASummaryContext } from './CMASummaryContext';
import { CMA_SUMMARY_INITIAL_FILTERS } from './constants';

const CMASummaryFilterControls = () => {
  const [open, setOpen] = useState(false);
  const { persistedFilters, filters, setFilters } = useCMASummaryContext();

  const areFiltersEmpty = JSON.stringify(filters) === JSON.stringify(CMA_SUMMARY_INITIAL_FILTERS);
  const disableSave =
    persistedFilters === null ? areFiltersEmpty : JSON.stringify(persistedFilters) === JSON.stringify(filters);

  return (
    <FiltersControls
      modal={<CMASummarySaveFilterModal open={open} onCancel={() => setOpen(false)} />}
      showSaveFilter
      disableSaveFilter={disableSave}
      disableReset={areFiltersEmpty}
      onReset={() => setFilters(CMA_SUMMARY_INITIAL_FILTERS)}
      onSaveFilter={() => setOpen(true)}
    />
  );
};

export default CMASummaryFilterControls;
