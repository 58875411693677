import React from 'react';

import styled from 'styled-components';

import AuthenticationSidePanelContent from './AuthenticationSidePanelContent';

const Container = styled.div`
  position: relative;
  width: 35vw;
  height: 100%;
  background-image: url('images/login.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  color: white;

  &::before {
    content: '';
    display: block;
    position: absolute;
    inset: 0;
    background: rgba(0, 0, 0, 0.7);
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
`;

const Content = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;

const Logo = styled.div`
  position: absolute;
  justify-content: center;
  left: 50%;
  transform: translate(-50%, 0%);
  padding: 60px 0px 60px 0px;
`;

const AuthenticationSidePanel = () => {
  return (
    <Container>
      <Content>
        <Logo>
          <img src="logos/login_logo.svg" alt="logo" />
        </Logo>
        <AuthenticationSidePanelContent />
      </Content>
    </Container>
  );
};

export default AuthenticationSidePanel;
