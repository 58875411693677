import React, { ReactNode } from 'react';

import { CloseOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import {
  DEFAULT_SAVINGS_ENTRY,
  DEFAULT_SAVINGS_PERCENTAGE_ENTRY,
  INCENTIVES_FEES_TYPE,
  INCENTIVES_FEES_TYPE_LABELS,
  INCENTIVES_SAVINGS_CONTENT_TYPE,
  INCENTIVES_SAVINGS_CONTENT_TYPE_OPTIONS,
  INCENTIVES_SAVINGS_DISCOUNT_DURATION_FACTOR,
  INCENTIVES_SAVINGS_DISCOUNT_DURATION_MAP,
  INCENTIVES_SAVINGS_DISCOUNT_DURATION_VALUES,
  INCENTIVES_SAVINGS_DISCOUNT_TYPE,
  INCENTIVES_SAVINGS_DISCOUNT_TYPE_OPTIONS,
  INCENTIVES_SAVINGS_DURATION_OPTIONS,
  INCENTIVES_SAVINGS_DURATION_TYPE,
  INCENTIVES_SAVINGS_TYPE_OPTIONS,
} from './constants';
import { useIncentivesCreateModalContext } from './IncentivesCreateModalContext';
import IncentivesCreateOffersSavingsEntryCustomName from './IncentivesCreateOffersSavingsEntryCustomName';
import Input from '../../lib/Input';
import Select from '../../lib/Select';
import { IncentiveSavingType } from '../types';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const StyledSelect = styled(Select)`
  && {
    width: 138px;
    height: 36px;

    &.short {
      width: 88px;
    }

    .ant-select-selector {
      font-size: 14px;
      height: 36px;
      border-radius: 0;
      border: 1px solid var(--line-gray);
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    }
  }
`;

const StyledInput = styled(Input)`
  background-color: white;
  .ant-input-prefix {
    font-size: 12px;
    color: inherit;
  }
`;

const Label = styled.div`
  font-family: var(--font-bold);
  font-size: 14px;
  text-align: end;
  color: var(--text-primary);
  min-width: 32px;
`;

const RemoveControl = styled.div`
  position: absolute;
  cursor: pointer;
  top: 10px;
  right: -18px;
`;

const StyledDropdownMenu = styled.div`
  .ant-select-item-option-content {
    display: flex;
    align-items: center;
  }
`;

const CustomSelect = (props: any) => (
  <StyledSelect
    dropdownStyle={{
      border: '1px solid var(--line-gray)',
      borderRadius: 0,
      padding: 0,
    }}
    dropdownRender={(menu: ReactNode) => <StyledDropdownMenu>{menu}</StyledDropdownMenu>}
    style={{
      display: 'flex',
      flexGrow: 1,
    }}
    {...props}
  />
);

interface Props {
  index: number;
  savingsItem: IncentiveSavingType;
}

const IncentivesCreateOffersSavingsEntry = ({ index, savingsItem }: Props) => {
  const { savings, setSavings } = useIncentivesCreateModalContext();

  const handleItemUpdate = (item: IncentiveSavingType) => {
    const savingsEntry = savings.find((entry: IncentiveSavingType) => entry.id === item.id);
    if (savingsEntry) {
      Object.assign(savingsEntry, item);
      setSavings([...savings]);
    }
  };

  const handleChange = (value: string | number, field: string) => {
    handleItemUpdate(Object.assign(savingsItem, { [field]: value }));
  };

  const handleChangeContentType = (value: string) => {
    const contentUpdates = { content_type: value };
    if (value === INCENTIVES_SAVINGS_CONTENT_TYPE.CUSTOM) {
      Object.assign(contentUpdates, { type: INCENTIVES_FEES_TYPE.CUSTOM, discount_type: undefined });
    } else if (value === INCENTIVES_SAVINGS_CONTENT_TYPE.CUSTOM_AMOUNT) {
      Object.assign(contentUpdates, { type: INCENTIVES_FEES_TYPE.CUSTOM });
    } else {
      Object.assign(contentUpdates, { name: undefined });
    }

    handleItemUpdate(Object.assign(savingsItem, DEFAULT_SAVINGS_ENTRY, contentUpdates));
  };

  const handleChangeDiscountType = (value: string) => {
    handleItemUpdate(
      Object.assign(
        savingsItem,
        value === INCENTIVES_SAVINGS_DISCOUNT_TYPE.PERCENTAGE ? DEFAULT_SAVINGS_PERCENTAGE_ENTRY : DEFAULT_SAVINGS_ENTRY
      )
    );
  };

  const handleChangeDiscountDuration = (value: number) => {
    handleItemUpdate(Object.assign(savingsItem, INCENTIVES_SAVINGS_DISCOUNT_DURATION_VALUES[value]));
  };

  const handleChangeType = (value: string) => {
    const durationOptions =
      value === INCENTIVES_FEES_TYPE.MONTHLY_RENT
        ? {
            discount_duration: INCENTIVES_SAVINGS_DISCOUNT_DURATION_FACTOR.SIX_MONTHS,
            discount_duration_type: INCENTIVES_SAVINGS_DURATION_TYPE.MONTH,
          }
        : {
            discount_duration: undefined,
            discount_duration_type: undefined,
          };

    handleItemUpdate(
      Object.assign(savingsItem, durationOptions, { type: value, name: INCENTIVES_FEES_TYPE_LABELS[value] })
    );
  };

  const handleRemoveEntry = (value: any) => {
    setSavings((prev: IncentiveSavingType[]) => [
      ...prev.filter((item: IncentiveSavingType, itemIndex: number) => itemIndex != index),
    ]);
  };

  const savingsTypeOptions = INCENTIVES_SAVINGS_TYPE_OPTIONS.filter(
    (item: any) =>
      item.id == savingsItem.type || !savings.some((savingsItem: IncentiveSavingType) => savingsItem.type === item.id)
  ).map((item: any) => ({ value: item.id, label: item.name }));

  const showSavingsDiscountType = [
    INCENTIVES_SAVINGS_CONTENT_TYPE.COMMON,
    INCENTIVES_SAVINGS_CONTENT_TYPE.CUSTOM_AMOUNT,
  ].includes(savingsItem.content_type);

  const showSavingsCommonTypes = ![
    INCENTIVES_SAVINGS_CONTENT_TYPE.CUSTOM,
    INCENTIVES_SAVINGS_CONTENT_TYPE.CUSTOM_AMOUNT,
  ].includes(savingsItem.content_type);

  const showSavingsDiscountDuration =
    savingsItem.type === INCENTIVES_FEES_TYPE.MONTHLY_RENT &&
    savingsItem.discount_duration_type &&
    savingsItem.discount_duration;

  const showSavingsCustomTypeName = [
    INCENTIVES_SAVINGS_CONTENT_TYPE.CUSTOM_AMOUNT,
    INCENTIVES_SAVINGS_CONTENT_TYPE.CUSTOM,
  ].includes(savingsItem.content_type);

  return (
    <Container>
      {index > 0 && (
        <RemoveControl>
          <CloseOutlined style={{ color: 'var(--gray-dark)', fontSize: 12 }} onClick={handleRemoveEntry} />
        </RemoveControl>
      )}
      <Row>
        <Label>Save</Label>
        <CustomSelect
          value={savingsItem.content_type}
          defaultValue={INCENTIVES_SAVINGS_CONTENT_TYPE.COMMON}
          onChange={(value: string) => handleChangeContentType(value)}
          options={INCENTIVES_SAVINGS_CONTENT_TYPE_OPTIONS.map((item: any) => ({ value: item.id, label: item.name }))}
        />
      </Row>
      {showSavingsDiscountType && (
        <Row>
          <Label />
          <CustomSelect
            value={savingsItem.discount_type}
            defaultValue={INCENTIVES_SAVINGS_DISCOUNT_TYPE.AMOUNT}
            onChange={(value: string) => handleChangeDiscountType(value)}
            options={INCENTIVES_SAVINGS_DISCOUNT_TYPE_OPTIONS.map((item: any) => ({
              value: item.id,
              label: item.name,
            }))}
          />
          <StyledInput
            prefix={savingsItem.discount_type === INCENTIVES_SAVINGS_DISCOUNT_TYPE.AMOUNT ? '$' : undefined}
            suffix={savingsItem.discount_type === INCENTIVES_SAVINGS_DISCOUNT_TYPE.PERCENTAGE ? '%' : undefined}
            style={{ width: 88, height: 36 }}
            min={0}
            max={savingsItem.discount_type === INCENTIVES_SAVINGS_DISCOUNT_TYPE.PERCENTAGE ? 100 : 9999}
            type="number"
            value={savingsItem.discount}
            onChange={(e: any) => handleChange(e.target.value, 'discount')}
          />
        </Row>
      )}
      <Row>
        <Label>on</Label>
        {showSavingsCommonTypes && (
          <CustomSelect
            style={{
              width: 148,
            }}
            value={savingsItem.type}
            onChange={(value: string) => handleChangeType(value)}
            options={savingsTypeOptions}
            placeholder={'Select'}
          />
        )}
        {showSavingsDiscountDuration && (
          <CustomSelect
            value={
              INCENTIVES_SAVINGS_DISCOUNT_DURATION_MAP[savingsItem.discount_duration_type!][
                savingsItem.discount_duration!
              ]
            }
            className={'short'}
            onChange={(value: number) => handleChangeDiscountDuration(value)}
            options={INCENTIVES_SAVINGS_DURATION_OPTIONS.map((item: any) => ({
              value: item.id,
              label: item.name,
            }))}
          />
        )}
        {showSavingsCustomTypeName && (
          <IncentivesCreateOffersSavingsEntryCustomName
            savingsItem={savingsItem}
            onChange={(value: string) => handleChange(value, 'name')}
          />
        )}
      </Row>
    </Container>
  );
};

export default IncentivesCreateOffersSavingsEntry;
