import React from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';

import styled from 'styled-components';

import { useAppDispatch, useAppSelector } from 'store/hook';

import AlphaContent from './AlphaContent';
import RootLoaders from './RootLoaders';
import { getUserInfo } from '../../apis/UserAPI';
import { USER_DATA_VALIDATION_FIELD } from '../../constants';
import { handleLogout, handleUpdateUser } from '../../store/slices/authSlice';
import BetaContent from '../Beta/BetaContent';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 100vh;
`;

interface Props {
  children: React.ReactNode;
}

const AppLayout: React.FC<Props> = ({ children }) => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const { currentUser, featureFlags } = useAppSelector((state) => state.auth);

  useQuery({
    queryKey: [location.pathname, currentUser],
    queryFn: getUserInfo,
    onSuccess: (userData) => {
      if (
        currentUser &&
        userData &&
        USER_DATA_VALIDATION_FIELD in currentUser &&
        USER_DATA_VALIDATION_FIELD in userData &&
        currentUser.hash != userData.hash
      ) {
        dispatch(handleLogout());
      } else {
        dispatch(handleUpdateUser(userData));
      }
    },
  });

  const showBetaApplications = currentUser?.isSuperuser || featureFlags?.showBetaApplication;

  return (
    <Container>
      <RootLoaders>
        {!showBetaApplications && <AlphaContent>{children}</AlphaContent>}
        {showBetaApplications && <BetaContent>{children}</BetaContent>}
      </RootLoaders>
    </Container>
  );
};

export default AppLayout;
