import React, { useState } from 'react';

import Button from 'components/lib/Button';

import CreateShopRequestModal from './CreateMysteryShopRequestModal/CreateShopRequestModal';
import { useShopRequestsDataContext } from './ShopRequestsDataContext';

const ShopRequestsCreateButton = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const { resetSelection } = useShopRequestsDataContext();

  const handleModelOpen = () => {
    setModalOpen(true);
    resetSelection();
  };

  return (
    <>
      <Button type="primary" style={{ padding: '0 25px' }} onClick={handleModelOpen}>
        Create Shop Request
      </Button>
      {isModalOpen && <CreateShopRequestModal open={isModalOpen} onCancel={() => setModalOpen(false)} />}
    </>
  );
};

export default ShopRequestsCreateButton;
