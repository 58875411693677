import React from 'react';

import { add } from 'date-fns';

import { createIncentiveProposal } from 'apis/IncentiveAPI';
import { useRootContext } from 'components/layout/RootContext';

import { useIncentivesContext } from './IncentivesContext';
import { PROPOSAL_DURATION } from './proposal/constants';
import { getProposalIncentiveAmountDescription, getProposalPricingSavings } from './proposal/helpers';
import { useIncentivesProposalModalContext } from './proposal/IncentivesProposalModalContext';
import { IncentiveProposalPricingType } from './proposal/types';

type Props = {
  setLoading: (value: boolean) => void;
};

const useSubmitIncentiveProposal = ({ setLoading }: Props) => {
  const { showMessage } = useRootContext();
  const { incentive, rates, handleClose } = useIncentivesProposalModalContext();
  const { selectedProposalUnit } = useIncentivesContext();

  const handleSubmit = async (values: any) => {
    if (!selectedProposalUnit) {
      return;
    }

    setLoading(true);
    const { lead, time, resident, second_person, signature_documents_ids } = values;
    const pricing = rates.filter(
      (item: IncentiveProposalPricingType) => values.rates.includes(item.type) || values.rates.includes(item.name)
    );
    const total_savings = getProposalPricingSavings(pricing);
    const total_savings_description = incentive?.savings
      ? getProposalIncentiveAmountDescription(incentive?.savings, selectedProposalUnit)
      : '';

    // When there is limit we look at the selected option:
    // Selected duration - Calculate Proposal expiration date based on selection
    // Default - Use existing Incentive date as Proposal expiration date
    // Without set limit we default to Incentive expiration date if present
    // Otherwise we consider Proposal indefinite in duration
    let expires_at = undefined;
    if (time.limit && time.duration !== PROPOSAL_DURATION.EXPIRATION_DATE) {
      expires_at = add(new Date(), { days: time.duration });
    } else if (
      incentive?.end_at &&
      ((time.limit && time.duration === PROPOSAL_DURATION.EXPIRATION_DATE) || !time.limit)
    ) {
      expires_at = incentive?.end_at;
    }

    const payload = {
      unit_id: selectedProposalUnit.id,
      incentive_id: incentive?.id,
      expires_at,
      total_savings,
      lead,
      resident: resident.enabled ? { ...resident } : undefined,
      second_person: second_person.enabled ? { ...second_person } : undefined,
      pricing,
      signature_documents_ids,
      total_savings_description,
    };

    await createIncentiveProposal(payload)
      .then((response: any) => {
        showMessage('success', 'Proposal sent to lead.');
        handleClose();
      })
      .catch((reason: any) => {
        showMessage('error', reason.response?.data?.text || 'Trouble sending Incentive proposal');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return handleSubmit;
};

export default useSubmitIncentiveProposal;
