import React, { createContext, useContext } from 'react';

import { CommunityDataType } from './types';

type CommunityInfoContentType = {
  communityInfo: CommunityDataType | undefined;
  setCommunityInfo: (info: CommunityDataType) => void;
};

export const CommunityInfoContext = createContext<CommunityInfoContentType>({
  communityInfo: undefined,
  setCommunityInfo: () => {
    // Does Nothing
  },
});

export const useCommunityInfoContext = () => useContext(CommunityInfoContext);
