import React, { useContext } from 'react';
import { useQuery } from 'react-query';

import classNames from 'classnames';
import styled from 'styled-components';

import SidebarSelectorDropdownListItem from './SidebarSelectorDropdownListItem';
import { getClients } from '../../../../apis/UserAPI';
import { useAppSelector } from '../../../../store/hook';
import Loader from '../../../lib/Loader';
import { SELECTOR_ITEM_TYPES } from '../constants';
import { SidebarContext } from '../SidebarContext';
import { SelectorItemDatatype } from '../types';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 400px;
  overflow-x: auto;
  border-top: solid 1px var(--line-gray);

  &.empty {
    border-top: none;
  }
`;

const getPrimaryClientParameter = (type: string | undefined) => {
  if (type === SELECTOR_ITEM_TYPES.PRIMARY_USER) {
    return true;
  } else if (type === SELECTOR_ITEM_TYPES.USER) {
    return false;
  }

  return type;
};

const SidebarSelectorDropdownListAdmin = () => {
  const { currentUser } = useAppSelector((state) => state.auth);

  const { filterKeyword, filterType } = useContext(SidebarContext);

  const { data, isLoading } = useQuery({
    queryKey: ['selector', 'users', filterKeyword, filterType],
    queryFn: () =>
      getClients({ search: filterKeyword, page_size: 30, primary_client: getPrimaryClientParameter(filterType) }),
    select: (data: any) => {
      return data?.results?.map((item: any) => ({
        id: item.id,
        type: SELECTOR_ITEM_TYPES.USER,
        name: item.name,
        label: item.company_name,
        description: item.email,
      }));
    },
    refetchOnWindowFocus: false,
    enabled: currentUser?.isSuperuser,
  });

  return (
    <Container className={classNames({ empty: data?.length === 0 }, 'mini-scroll')}>
      <Loader active={isLoading} />
      {data?.map((entry: SelectorItemDatatype) => (
        <SidebarSelectorDropdownListItem key={entry.type + entry.id} item={entry} />
      ))}
    </Container>
  );
};

export default SidebarSelectorDropdownListAdmin;
