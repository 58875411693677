import React from 'react';

import { Typography } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import styled from 'styled-components';

import Checkbox from 'components/lib/Checkbox';
import { isMysteryShopAdmin, isMysteryShopShopper } from 'helpers/permissions';
import { useAppSelector } from 'store/hook';

import { useEditShopRequestModalContext } from './EditShopRequestModalContext';

const Container = styled.div`
  display: flex;
  flex-flow: column;
  margin-top: 20px;
`;

const Label = styled(Typography.Text)`
  font-weight: 900;
  margin-bottom: 10px;
`;

const StyledCheckbox = styled(Checkbox)`
  margin-bottom: 10px;
  .ant-checkbox-inner {
    height: 18px;
    width: 18px;
  }
`;

const CheckboxLabel = styled.span`
  font-size: 14px;
  color: var(--text-primary);
`;

const StyledList = styled.ul`
  margin-top: 0;
  padding-left: 28px;
`;

const BaseCommunity = styled(Typography.Text)`
  font-family: var(--font-bold);
  color: var(--text-primary);
  font-size: 12px;
`;

const BaseCompany = styled(Typography.Text)`
  font-family: var(--font-regular);
  color: var(--text-secondary);
  font-size: 12px;
`;

const EditShopRequestModalPricingOptions = () => {
  const { shopRequest, setShopRequest } = useEditShopRequestModalContext();
  const { currentUser } = useAppSelector((state) => state.auth);
  const isShopRequestAdmin = currentUser && isMysteryShopAdmin(currentUser);
  const isShopRequestSalesperson = currentUser && isMysteryShopShopper(currentUser);

  const baseCommunityName = shopRequest?.requestGroup.base_community?.name;

  const updateChecbox = (event: CheckboxChangeEvent, property: string) => {
    if (shopRequest) {
      setShopRequest({ ...shopRequest, [property]: event.target.checked });
    }
  };

  return (
    <Container>
      <Label>Pricing Needed For</Label>
      {isShopRequestAdmin && (
        <>
          <StyledCheckbox
            className={'green'}
            checked={shopRequest?.independentLiving}
            onChange={(e: CheckboxChangeEvent) => updateChecbox(e, 'independentLiving')}
          >
            <CheckboxLabel>Independent Living</CheckboxLabel>
          </StyledCheckbox>
          <StyledCheckbox
            className={'green'}
            checked={shopRequest?.assistedLiving}
            onChange={(e: CheckboxChangeEvent) => updateChecbox(e, 'assistedLiving')}
          >
            <CheckboxLabel>Assisted Living</CheckboxLabel>
          </StyledCheckbox>
          <StyledCheckbox
            className={'green'}
            checked={shopRequest?.memoryCare}
            onChange={(e: CheckboxChangeEvent) => updateChecbox(e, 'memoryCare')}
          >
            <CheckboxLabel>Memory Care</CheckboxLabel>
          </StyledCheckbox>
        </>
      )}
      {isShopRequestSalesperson && (
        <StyledList>
          {shopRequest?.independentLiving && <li>Independent Living</li>}
          {shopRequest?.assistedLiving && <li>Assisted Living</li>}
          {shopRequest?.memoryCare && <li>Memory Care</li>}
        </StyledList>
      )}

      {baseCommunityName && (
        <>
          <Label>Propagate To</Label>
          <BaseCommunity>{baseCommunityName}</BaseCommunity>
          <BaseCompany>{shopRequest?.requestGroup.company?.name}</BaseCompany>
        </>
      )}
    </Container>
  );
};

export default EditShopRequestModalPricingOptions;
