import React, { ReactNode, useCallback, useEffect } from 'react';
import { useQuery } from 'react-query';

import { message } from 'antd';

import { useAppDispatch, useAppSelector } from 'store/hook';

import { RootContext } from './RootContext';
import { getFullHomeCommunities } from '../../apis/CommunityAPI';
import { getManagementCompanies } from '../../apis/UserAPI';
import { PAGINATION_PAGE_SIZE, USER_LEVELS } from '../../constants';
import {
  handleReset,
  handleSetLoader,
  handleSetSelectedClient,
  handleSetSelectedCompany,
  handleSetSelectedManagementCompany,
  handleStoreUserCommunities,
  handleStoreUserCommunitiesCompetitors,
  handleStoreUserManagementCompanies,
} from '../../store/slices/globalSlice';
import { processFullHomeCommunities } from '../../utils/data/communities';

interface Props {
  children: ReactNode;
}

const RootLoaders = ({ children }: Props) => {
  const dispatch = useAppDispatch();

  const [messageApi, contextHolder] = message.useMessage();

  const { selectedClient, selectedCompany, managementCompanyList } = useAppSelector((state) => state.global);
  const { currentUser } = useAppSelector((state) => state.auth);

  // Setup available User and Company information for Client
  useEffect(() => {
    if (currentUser && !currentUser.isSuperuser) {
      dispatch(
        handleSetSelectedClient({
          id: currentUser.PAUser_Id,
          name: currentUser.name,
          email: currentUser.email,
          level: currentUser.level,
          company: currentUser.company,
          management_company: currentUser.management_company,
        })
      );

      dispatch(
        handleSetSelectedCompany({
          id: currentUser.company ?? -1,
          name: currentUser.companyName ?? 'Unknown Company',
          primary_email: currentUser.companyPrimaryEmail,
        })
      );
    } else if (currentUser?.isSuperuser) {
      dispatch(handleReset());
    }
  }, [currentUser]);

  // Fetch all available Management Companies for Client/User
  const fetchManagementCompanies = useCallback(
    async (clientId: number | undefined = undefined, companyId: number) => {
      try {
        const { results } = await getManagementCompanies(companyId, {
          page_size: PAGINATION_PAGE_SIZE.MEDIUM,
          user: clientId,
        });

        dispatch(handleStoreUserManagementCompanies(results));
      } catch (e) {
        console.error(e);
      }
    },
    [selectedCompany]
  );

  useEffect(() => {
    if (selectedCompany && currentUser && !currentUser.isSuperuser) {
      fetchManagementCompanies(selectedClient?.id, selectedCompany.id);
    }
  }, [selectedCompany]);

  useEffect(() => {
    // When only one option is available set it as selected and prevent change.
    // Preselect management company only if user is Management Company level
    if (selectedClient?.level == USER_LEVELS.MANAGEMENT_COMPANY) {
      const preselectedManagementCompany = managementCompanyList[0];
      dispatch(handleSetSelectedManagementCompany(preselectedManagementCompany));
    }
  }, [managementCompanyList]);

  // Perform fetching for User Communities and Competitors
  const { refetch } = useQuery({
    queryKey: [selectedClient, selectedCompany, managementCompanyList],
    queryFn: () =>
      getFullHomeCommunities({
        client_id: selectedClient?.id ?? -1,
        company_list: selectedCompany ? [selectedCompany?.id] : [],
        management_company_list: [],
        comm_list: [],
        caretype_list: [],
      }),
    onSuccess: (response) => {
      const { updatedCommunities, updatedCompetitor } = processFullHomeCommunities(response);

      dispatch(handleStoreUserCommunities(updatedCommunities));
      dispatch(handleStoreUserCommunitiesCompetitors(updatedCompetitor));
      dispatch(handleSetLoader(false));
    },
    enabled: Boolean(selectedClient && selectedCompany && currentUser && !currentUser.isSuperuser),
  });

  const showMessage = (type: 'success' | 'info' | 'error', mgs: string) => {
    messageApi.open({
      type: type,
      content: mgs,
    });
  };

  return (
    <RootContext.Provider value={{ refreshCommunities: refetch, showMessage }}>
      {contextHolder}
      {children}
    </RootContext.Provider>
  );
};

export default RootLoaders;
