import { ACTOR_TYPE } from '../../../constants';

export const SELECTOR_ITEM_TYPES = {
  ALL: 'all',
  PRIMARY_USER: ACTOR_TYPE.PRIMARY_USER,
  USER: ACTOR_TYPE.USER,
  COMMUNITY: ACTOR_TYPE.COMMUNITY,
  COMPETITOR: ACTOR_TYPE.COMPETITOR,
  MANAGEMENT_COMPANY: ACTOR_TYPE.MANAGEMENT_COMPANY,
  COMPANY: ACTOR_TYPE.COMPANY,
};

export const SELECTOR_ITEM_FILTERS_LABEL = {
  [SELECTOR_ITEM_TYPES.ALL]: 'All',
  [SELECTOR_ITEM_TYPES.PRIMARY_USER]: 'Primary Clients',
  [SELECTOR_ITEM_TYPES.USER]: 'Other Users',
  [SELECTOR_ITEM_TYPES.COMMUNITY]: 'My Communities',
  [SELECTOR_ITEM_TYPES.COMPETITOR]: 'Competitors',
  [SELECTOR_ITEM_TYPES.MANAGEMENT_COMPANY]: 'My Companies',
};

export const SELECTOR_ITEM_DESCRIPTION = {
  [SELECTOR_ITEM_TYPES.COMMUNITY]: 'My Community',
  [SELECTOR_ITEM_TYPES.COMPETITOR]: 'Competitor',
  [SELECTOR_ITEM_TYPES.MANAGEMENT_COMPANY]: 'Mgmt. Company',
  [SELECTOR_ITEM_TYPES.COMPANY]: 'Company',
};
