import React from 'react';

import styled from 'styled-components';

import SidebarMenuCategoryHeader from './SidebarMenuCategoryHeader';
import SidebarMenuItem from './SidebarMenuItem';

const Container = styled.div`
  padding-top: 20px;
`;

const SidebarMenuAdmin = () => {
  return (
    <Container>
      <SidebarMenuCategoryHeader title={'ADMIN'} />
      <SidebarMenuItem to={'/companies'} label={'Companies'} />
      <SidebarMenuItem
        to={'/google-metrics-reviews'}
        pattern={'/google-metrics-reviews/*'}
        label={'Google Metrics Reviews'}
      />
      <SidebarMenuItem to={'/user-log-dashboard'} label={'User Log Dashboard'} />
      <SidebarMenuItem to={'/onboarding'} label={'Onboarding'} />
    </Container>
  );
};

export default SidebarMenuAdmin;
