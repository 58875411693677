import React, { useEffect, useState } from 'react';

import { Image } from 'antd';
import styled from 'styled-components';

import { isImageFile, isPDFFile } from './utils';
import PreviewUnavailable from '../../common/PreviewUnavailable';

type FileItem = {
  file_name: string;
  file_url: string;
  file_extension: string;
};

type Props = {
  file: FileItem;
};

const BrochureAndDocsThumbnails = ({ file }: Props) => {
  const [isPDF, setIsPDF] = useState(false);
  const [isImage, setIsImage] = useState(false);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    const isFilePDF = isPDFFile(file.file_extension);
    const isFileImage = isImageFile(file.file_extension);

    setIsPDF(isFilePDF);
    setIsImage(isFileImage);
    setShowError(!isFilePDF && !isFileImage);
  }, [file]);

  return (
    <>
      {isPDF && !showError && (
        <iframe
          src={file.file_url}
          style={{
            border: 'none',
            height: '100vh',
            width: '100%',
            overflow: 'hidden',
            pointerEvents: 'none',
          }}
          onLoad={(event: any) => {
            const iframe = event.target;
            try {
              setShowError(iframe.contentWindow.length === 0);
            } catch (error) {
              setShowError(true);
            }
          }}
        />
      )}
      {isImage && !showError && (
        <Image
          src={file.file_url}
          style={{ objectFit: 'cover' }}
          alt="img"
          height="134px"
          width="134px"
          preview={false}
          onError={() => {
            setShowError(true);
          }}
        />
      )}
      {showError && <PreviewUnavailable />}
    </>
  );
};

export default BrochureAndDocsThumbnails;
