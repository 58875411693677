import React, { createContext, useContext } from 'react';

type FeesContent = {
  communityId: number | null;
};

export const FeesContext = createContext<FeesContent>({
  communityId: null,
});
export const useFeesContext = () => useContext(FeesContext);
