import React from 'react';

import AggregationTypePicker from 'components/lib/AggregationTypePicker';
import { AGGREGATION_TYPE } from 'components/View/constants';

import { useCMASummaryContext } from './CMASummaryContext';

const CMASummaryGroupAggregationFilter = () => {
  const { filters, setFilters } = useCMASummaryContext();

  return (
    <AggregationTypePicker
      aggregationType={filters.aggregationType || AGGREGATION_TYPE.AVG}
      onSelect={(value: number) => setFilters({ aggregationType: value })}
      containerStyle={{
        borderTop: 'unset',
        maxHeight: 'unset',
        padding: '0',
      }}
      buttonsWrapperStyle={{ height: 'unset' }}
      filterButtonStyle={{ height: 'unset' }}
    />
  );
};

export default CMASummaryGroupAggregationFilter;
