import React from 'react';

import { Typography } from 'antd';
import styled from 'styled-components';

import { SHOP_REQUEST_VIEW_GROUP_BY } from '../constants';
import { useShopRequestsDataContext } from '../ShopRequestsDataContext';
import { MysteryShopRequestGroup } from '../types';

const Container = styled.div`
  display: flex;
  flex-flow: column;
`;

const Title = styled(Typography.Text)`
  font-weight: 900;
  height: 21px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 290px;
`;

const StyledSubTitle = styled(Typography.Text)`
  color: var(--text-secondary);
  font-size: 12px;
  height: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 290px;
`;

type Props = {
  shopRequestGroup: MysteryShopRequestGroup;
};

const ShopRequestGroup = ({ shopRequestGroup }: Props) => {
  const { filters } = useShopRequestsDataContext();

  const baseCommunityName =
    filters.groupBy === SHOP_REQUEST_VIEW_GROUP_BY.COMMUNITY && shopRequestGroup.base_community?.name;

  const shopRequestGroupName = shopRequestGroup.name;

  return (
    <Container>
      <Title>{baseCommunityName || shopRequestGroupName}</Title>
      <StyledSubTitle>{shopRequestGroup.company ? shopRequestGroup.company.name : 'Further'}</StyledSubTitle>
    </Container>
  );
};

export default ShopRequestGroup;
