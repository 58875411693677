import React from 'react';

import dayjs from 'dayjs';
import styled from 'styled-components';

import { CompanyListItemType } from './types';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-family: var(--font-regular);
  color: var(--text-secondary);
  font-size: 12px;
  line-height: normal;
`;

const Date = styled.span`
  color: var(--text-primary);
  font-size: 14px;
`;

interface Props {
  item: CompanyListItemType;
}

const CompaniesTableDate = ({ item }: Props) => {
  return (
    <Container>
      <Date>{dayjs(item.created_at).format('MMM DD, YYYY')}</Date>
      {item.created_by && <span>by {item.created_by}</span>}
    </Container>
  );
};

export default CompaniesTableDate;
