import React, { useEffect, useState } from 'react';

type Props = {
  initialMinimum: number;
  initialMaximum: number;
};

const useArrayBoundary = ({ initialMinimum, initialMaximum }: Props) => {
  const [minimum, setMinimum] = useState(initialMinimum);
  const [maximum, setMaximum] = useState(initialMaximum);

  useEffect(() => {
    setMaximum(initialMaximum);
  }, [setMaximum, initialMaximum]);

  const moveRight = () => {
    setMinimum((prevMinimum) => prevMinimum + 1);
    setMaximum((prevMaximum) => prevMaximum + 1);
  };
  const moveLeft = () => {
    setMinimum((prevMinimum) => prevMinimum - 1);
    setMaximum((prevMaximum) => prevMaximum - 1);
  };

  const resetMinimum = () => setMinimum(0);

  return { minimum, maximum, moveRight, moveLeft, resetMinimum };
};

export default useArrayBoundary;
