import React from 'react';

import { Col, Row } from 'antd';
import styled from 'styled-components';

import ScatterChart from '../Graph/ScatterChart';

type Props = {
  data: any;
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 70px;
  padding-right: 25px;
  font-family: var(--font-regular);
  p {
    font-size: 10px;
  }
`;
const Worse = styled.p`
  color: var(--negative-orange);
`;
const Same = styled.p`
  color: var(--text-primary);
`;
const Better = styled.p`
  color: var(--positive-green);
`;

const SwotPerformanceScatterGraph = ({ data }: Props) => {
  return (
    <>
      <Container>
        <Better>Better than competitors</Better>
        <Same>Same</Same>
        <Worse>Worse than competitors</Worse>
      </Container>
      <ScatterChart data={data} />
    </>
  );
};

export default SwotPerformanceScatterGraph;
