import React from 'react';

import styled from 'styled-components';

import { useCMASummaryContext } from './CMASummaryContext';
import CMASummaryTableDataValuesDifference from './CMASummaryTableDataValuesDifference';
import { parseCMASummaryCommunityData } from './utils';

const Title = styled.div`
  font-family: var(--font-regular);
  font-size: 14px;
`;

const Subtitle = styled.div`
  color: var(--text-secondary);
  font-size: 12px;
`;

type Props = {
  reviews: number[];
};

const CMASummaryTableDataReview = ({ reviews }: Props) => {
  const { filters } = useCMASummaryContext();
  if (!reviews?.length) {
    return null;
  }

  const [parsedAverage, parsedTotal, averageDifference, totalDifference] = parseCMASummaryCommunityData(
    reviews,
    filters.viewOptions?.valueDisplay
  );

  return (
    <div>
      <Title>
        {parsedAverage} <CMASummaryTableDataValuesDifference prefix="" difference={averageDifference} />
      </Title>
      <Subtitle>
        {parsedTotal} <CMASummaryTableDataValuesDifference prefix="" difference={totalDifference} />
      </Subtitle>
    </div>
  );
};

export default CMASummaryTableDataReview;
