import React, { createContext, useContext } from 'react';

import { ApartmentTypeGroups } from 'store/slices/globalSlice';

import { CMA_SUMMARY_INITIAL_FILTERS } from './constants';
import { CMASummary, CMASummaryFilter } from './types';

type CMASummaryContextType = {
  loading: boolean;
  cmaSummary?: CMASummary;
  cmaSummaryData?: any[];
  community?: any;
  competitors: any[];
  apartmentTypeGroups: ApartmentTypeGroups[];
  persistedFilters: CMASummaryFilter | null;
  filters: CMASummaryFilter;
  livingInfo?: any[][];
  setFilters: (filters: any) => void;
  refetchCMASummary: () => void;
};

export const CMASummaryContext = createContext<CMASummaryContextType>({
  loading: false,
  cmaSummary: undefined,
  cmaSummaryData: undefined,
  community: undefined,
  competitors: [],
  apartmentTypeGroups: [],
  persistedFilters: null,
  filters: CMA_SUMMARY_INITIAL_FILTERS,
  livingInfo: [],
  setFilters: () => {
    // Do nothing
  },
  refetchCMASummary: () => {
    // Do nothing
  },
});

export const useCMASummaryContext = () => useContext(CMASummaryContext);
