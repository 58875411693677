import React, { createContext, useContext } from 'react';

type AccommodationContent = {
  communityId: number | null;
};

export const AccommodationContext = createContext<AccommodationContent>({
  communityId: null,
});

export const useAccommodationContext = () => useContext(AccommodationContext);
