import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';

import { Spin } from 'antd';
import styled from 'styled-components';

import { BrochureAndDocsContext } from './BrochureAndDocsContext';
import BrochureAndDocsFilter from './BrochureAndDocsFilter';
import BrochureAndDocsThumbnails from './BrochureAndDocsThumbnails';
import { getCommunityBrochureAndDocs } from '../../../../apis/CommunityAPI';
import { FILE_SOURCE } from '../../../../constants';
import Header from '../Header';

const Container = styled.div`
  position: relative;
`;

const SpinWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  padding: 20px;
`;

const Content = styled.div`
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  height: 320px;
  overflow-y: auto;
`;

const FileWrapper = styled.a`
  position: relative;
  display: flex;
  width: 134px;
  height: 134px;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;
`;

const FileName = styled.span`
  position: absolute;
  box-sizing: border-box;
  width: 134px;
  padding: 4px;
  font-size: 12px;
  color: var(--white);
  bottom: 0px;
  background-color: var(--box-shadow-gray-dark);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const BrochureAndDocs = () => {
  const [brochureAndDocs, setBrochureAndDocs] = useState<any[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string>('');

  const { competitorId } = useParams();
  const { communityId } = useParams();
  const id = Number(competitorId || communityId);

  const { data, isLoading } = useQuery({
    queryKey: ['community', 'brochures', 'documents', id, selectedCategory],
    queryFn: () =>
      getCommunityBrochureAndDocs(id, { file_category: selectedCategory || null, source: [FILE_SOURCE.MEDIA] }),
    onSuccess: (data) => {
      setBrochureAndDocs(data);
    },
    enabled: Boolean(id),
  });

  return (
    <BrochureAndDocsContext.Provider
      value={{
        brochureAndDocs,
        selectedCategory,
        setSelectedCategory,
        setBrochureAndDocs,
      }}
    >
      <Container>
        <Header title="Brochures and Documents" filter={<BrochureAndDocsFilter />} />
        {isLoading && (
          <SpinWrapper>
            <Spin />
          </SpinWrapper>
        )}
        {data && (
          <Content className="mini-scroll">
            {data.map((file: any, index: number) => (
              <FileWrapper key={file.id} href={file.file_url} target="_blank" rel="noopener noreferrer">
                <BrochureAndDocsThumbnails file={file} />
                <FileName title={file.file_name}>{file.file_name}</FileName>
              </FileWrapper>
            ))}
          </Content>
        )}
      </Container>
    </BrochureAndDocsContext.Provider>
  );
};

export default BrochureAndDocs;
