import React, { createContext, useContext } from 'react';

import { AGGREGATION_TYPE } from '../constants';

type CommunityViewWidgetModalContent = {
  id?: number;
  name: string;
  note: string;
  type: number;
  filterId?: number;
  time: number | undefined;
  competitors: number[];
  careTypes: number[];
  roomTypes: number[];
  careLevels: number[];
  reviewSources: number[];
  reviewCategories: number[];
  swotCategories: number[];
  careLevelsStatsType: number;
  setName: (text: string) => void;
  setNote: (text: string) => void;
  setTime: (selection: any) => void;
  setCompetitors: (selection: any) => void;
  setCareLevels: (selection: any) => void;
  setCareTypes: (selection: any) => void;
  setRoomTypes: (selection: any) => void;
  setReviewSources: (selection: any) => void;
  setReviewCategories: (selection: any) => void;
  setSwotCategories: (selection: any) => void;
  setCareLevelsStatsType: (selection: number) => void;
  modalType: string;
  onClose: () => void;
};

export const CommunityViewWidgetModalContext = createContext<CommunityViewWidgetModalContent>({
  id: undefined,
  name: '',
  note: '',
  type: 0,
  filterId: undefined,
  time: undefined,
  competitors: [],
  careTypes: [],
  roomTypes: [],
  careLevels: [],
  reviewSources: [],
  reviewCategories: [],
  swotCategories: [],
  modalType: '',
  careLevelsStatsType: AGGREGATION_TYPE.AVG,
  setName: () => {
    //  Do Nothing
  },
  setNote: () => {
    //  Do Nothing
  },
  setTime: () => {
    //  Do Nothing
  },
  setCareTypes: () => {
    //  Do Nothing
  },
  setCompetitors: () => {
    // Do Nothing
  },
  setRoomTypes: () => {
    //  Do Nothing
  },
  setCareLevels: () => {
    //  Do Nothing
  },
  setReviewSources: () => {
    //  Do Nothing
  },
  setReviewCategories: () => {
    //  Do Nothing
  },
  setSwotCategories: () => {
    //  Do Nothing
  },
  setCareLevelsStatsType: () => {
    //  Do Nothing
  },
  onClose: () => {
    //  Do Nothing
  },
});

export const useCommunityViewWidgetModalContext = () => useContext(CommunityViewWidgetModalContext);
