import React from 'react';

import styled from 'styled-components';

import { isIncentivesCorporate } from '../../../helpers/permissions';
import { useAppSelector } from '../../../store/hook';
import IncentivesActions from '../actions/IncentivesActions';
import { INCENTIVES_TABS } from '../constants';
import IncentivesFilters from '../filters/IncentivesFilters';
import { useIncentivesContext } from '../IncentivesContext';
import { useIncentivesDataContext } from '../IncentivesDataContext';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const IncentivesControls = () => {
  const { currentUser } = useAppSelector((state) => state.auth);
  const { currentTab } = useIncentivesContext();
  const { createMode, archiveMode, selectionMode } = useIncentivesDataContext();

  const showActions =
    isIncentivesCorporate(currentUser) &&
    [INCENTIVES_TABS.INCENTIVE, INCENTIVES_TABS.UNIT].includes(currentTab) &&
    !createMode;

  return (
    <Container>
      <IncentivesFilters />
      {showActions && <IncentivesActions />}
    </Container>
  );
};

export default IncentivesControls;
