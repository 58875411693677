export const PHOTOS_FILTER_OPTIONS = [
  { label: 'All', value: '' },
  { label: 'Exterior Front', value: 'Exterior Front' },
  { label: 'Common Area', value: 'Common Area' },
  { label: 'Apartment Image', value: 'Apartment Image' },
  { label: 'Dining Room', value: 'Dining Room' },
  { label: 'Menu', value: 'Menu' },
  { label: 'Activity Calendar', value: 'Activity Calendar' },
  { label: 'Other Image', value: 'Other Image' },
];
