import React from 'react';

import SelectFilter from 'components/lib/SelectFilter';

import { PHOTOS_FILTER_OPTIONS } from './constants';
import { usePhotosContext } from './PhotosContext';

const PhotosFilter = () => {
  const { setSelectedCategory } = usePhotosContext();

  const handleChange = (value: string) => {
    setSelectedCategory(value);
  };

  return (
    <SelectFilter placeholder="View" options={PHOTOS_FILTER_OPTIONS} variant="borderless" onChange={handleChange} />
  );
};

export default PhotosFilter;
