import React from 'react';

import styled from 'styled-components';

import EditBatchShopRequestsModalDate from './EditBatchShopRequestsModalDate';
import EditBatchShopRequestsModalShopper from './EditBatchShopRequestsModalShopper';
import EditBatchShopRequestsModalStatus from './EditBatchShopRequestsModalStatus';

const Container = styled.div`
  display: flex;
  flex-flow: column;
  width: 540px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const EditBatchShopRequestsModelContent = () => {
  return (
    <Container>
      <EditBatchShopRequestsModalStatus />
      <EditBatchShopRequestsModalShopper />
      <EditBatchShopRequestsModalDate />
    </Container>
  );
};

export default EditBatchShopRequestsModelContent;
