import { useRootContext } from './RootContext';
import { impersonateUser } from '../../apis/UserAPI';
import { useAppDispatch, useAppSelector } from '../../store/hook';
import { handleLogin } from '../../store/slices/authSlice';
import { handleReset } from '../../store/slices/globalSlice';

export const useUserSwitch = () => {
  const { showMessage } = useRootContext();
  const { currentUser } = useAppSelector((state) => state.auth);

  const dispatch = useAppDispatch();

  const handleUserSwitch = async (userId: number) => {
    await impersonateUser({
      user_id: currentUser?.manager ? undefined : userId,
    })
      .then((response: any) => {
        const payload = {
          currentUser: response?.userInfo,
          featureFlags: {
            showBetaApplication: response?.userInfo?.feature_flags?.use_beta_application,
          },
          accessToken: response?.accessToken,
          refreshToken: response?.refreshToken,
        };

        setTimeout(() => {
          if (response?.userInfo && Boolean(response?.userInfo?.manager)) {
            showMessage('success', `Supporting user ${response?.userInfo?.FullName ?? response?.userInfo?.name}.`);
          }
          dispatch(handleReset());
          dispatch(handleLogin(payload));
        }, 500);
      })
      .catch((reason: any) => {
        showMessage('error', `Trouble initiating support mode!`);
        console.log('Error', reason);
      });
  };

  return { handleUserSwitch };
};
