import React from 'react';

import styled from 'styled-components';

import { useCMASummaryContext } from './CMASummaryContext';
import { CMA_SUMMARY_VIEW_FILTER_DISPLAY_VALUE } from './constants';
import { formatAmount, formatPercentage } from './utils';

const Difference = styled.span<{ color: string }>`
  color: ${({ color }) => color};
  margin-left: 2px;
`;

const DEFAULT_DOLLAR_PREFIX = '$';

type Props = {
  difference?: number | null;
  prefix?: string;
};

const CMASummaryTableDataValuesDifference = ({ difference, prefix = DEFAULT_DOLLAR_PREFIX }: Props) => {
  const { filters } = useCMASummaryContext();

  if (!difference) {
    return null;
  }

  return (
    <Difference color={difference < 0 ? 'var(--negative-orange)' : 'var(--positive-green)'}>
      {filters.viewOptions?.valueDisplay === CMA_SUMMARY_VIEW_FILTER_DISPLAY_VALUE.AMOUNTS &&
        formatAmount(prefix, difference)}
      {filters.viewOptions?.valueDisplay === CMA_SUMMARY_VIEW_FILTER_DISPLAY_VALUE.PERCENTAGES &&
        formatPercentage(difference)}
    </Difference>
  );
};

export default CMASummaryTableDataValuesDifference;
