export const STEPS = {
  ENTER_EMAIL: '1',
  ENTER_PASSWORD: '2',
};

export const STEPS_ACTIONS = {
  [STEPS.ENTER_EMAIL]: {
    name: 'email',
    TITLE: 'Log In',
    SUBTITLE: 'Enter your Pricing Assistant email address below.',
    LABEL: 'Email address',
    BUTTON_TEXT: 'Next',
    ERROR_MESSAGE: '',
  },
  [STEPS.ENTER_PASSWORD]: {
    name: 'password',
    TITLE: 'Enter password',
    SUBTITLE: 'Enter your Pricing Assistant password.',
    LABEL: 'Password',
    BUTTON_TEXT: 'Log In',
    ERROR_MESSAGE: 'Your login info is incorrect.',
  },
};
