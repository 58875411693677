import {
  MysteryShopRequestGroup,
  MysteryShopRequestOutbound,
  MysteryShopRequestsBatch,
} from 'components/MysteryShopping/types';

import axios from '../config/axiosPrivate';

export const getShopRequests = async (params: any) => {
  const url = '/shop_requests/';
  const response = await axios.get(url, { params });
  return response.data;
};

export const getShopRequestGroups = async (params: any) => {
  const url = '/shop_requests/groups';
  const response = await axios.get(url, { params });
  return response.data;
};

export const createShopRequests = async (payload: MysteryShopRequestGroup) => {
  const url = '/shop_requests/create';
  const response = await axios.post(url, payload);
  return response.data;
};

export const updateShopRequest = async (id: number, payload: Partial<MysteryShopRequestOutbound>) => {
  const url = `/shop_requests/${id}`;
  const response = await axios.patch(url, payload);
  return response.data;
};

export const batchUpdateShopRequests = async (payload: MysteryShopRequestsBatch) => {
  const url = `/shop_requests/batch-update`;
  const response = await axios.patch(url, payload);
  return response.data;
};

export const getShopRequestStats = async (params: any) => {
  const url = `/shop_requests/metrics`;
  const response = await axios.get(url, { params });
  return response.data;
};

export const deleteShopRequest = async (id: number) => {
  const url = `/shop_requests/${id}`;
  const response = await axios.delete(url);
  return response.data;
};

export const fetchShopRequestTabsMetrics = async (params: any) => {
  const url = '/shop_requests/tabs_metrics';
  const response = await axios.get(url, { params });
  return response.data;
};
